export enum Exceptions {
  Unknown = <any>'Error desconocido',
  ConstraintViolation = <any>'Registro existente',
  Unauthorized = <any>'No autorizado',
  ProductNotFound = <any>'Producto no encontrado',
  CustomerNotFound = <any>'Cliente no encontrado',
  TooManyFields = <any>'Se envió más información de la requerida',
  InvalidFieldName = <any>'Parámetro incorrecto',
  InvalidCredentials = <any>'Usuario no encontrado',
  InvalidEmail = <any>'Correo inválido',
  InvalidInputNumber = <any>'Número inválido',
  InvalidInputText = <any>'Campo de texto inválido',
  FieldsAreMissing = <any>'Hace falta más información para comprar el producto',
  InvalidOrderDetailSchema = <any>'Información incorrecta del producto',
  InvalidRequiredInfoSchema = <any>'Campo dinámico inválido',
  ConsignmentAlreadyInRevision = <any>(
    'Esta acreditación de saldo ya está siendo revisada por otro usuario'
  ),
  OrderAlreadyInRevision = <any>(
    'Este pedido ya está siendo revisado por otro usuario'
  ),
  CategoryWithProducts = <any>(
    'No se puede eliminar la categoría porque tiene productos asociados'
  ),
  SaleCostLowerThanProductionCost = <any>(
    'El costo de venta no puede ser menor que el costo de producción'
  ),
  PasswordsAreSame = <any>'Las contraseñas son iguales',
  InvalidCurrentPassword = <any>'La constraseña actual es incorrecta',
  InvalidProductAccount = <any>'No existe la cuenta preestablecida',
  UserAlreadyExists = <any>'Ya existe un usuario con el correo ingresado',
  VoucherNumberAlreadyExists = <any>(
    'Ya existe el número de comprobante ingresado'
  ),
  UserNotFound = <any>'Usuario no existe',
  BankAccountNotFound = <any>'La cuenta bancaria no existe',
  BankAccountWithConsignment = <any>(
    'No se puede eliminar la cuenta bancaria porque tiene saldos asociados'
  ),
  InsufficientBalance = <any>'No tienes saldo suficiente para hacer la compra',
  DifferentPasswords = <any>'Las contraseñas no son iguales',
  InvalidPasswordLinkException = <any>(
    'Ocurrió un error en la creación de la nueva contraseña'
  ),
  InvalidMimetype = <any>(
    'El documento que subiste debe ser de tipo imagen (PNG, JPG o GIF)'
  ),
  LimitIsPresentInAnotherRange = <any>(
    'Alguno de los límites forma parte de un rango configurado anteriormente'
  ),
  UpperLimitIsLessThanLowerLimit = <any>(
    'El límite superior no puede ser menor al límite inferior'
  ),
  CustomerDiscountAlreadyExists = <any>(
    'Ya existe el descuento para el cliente y categoría seleccionados'
  ),
  InvalidCategory = <any>'Categoría inválida',
  DistributorNotFound = <any>'El distribuidor no existe',
  DistributorCommissionNotFound = <any>'La comisión no existe',
  DistributorCommissionAlreadyExists = <any>(
    'Ya existe la comisión para el distribuidor y categoría seleccionada'
  ),
  REPEATEDEMAIL = <any>(
    'El Email ya se encuentra registrado en el sistema'
  ),
  REPEATEDID = <any>(
    'El número de identificación ya se encuentra registrado en el sistema'
  ),
  PendingUser = <any>(
    'El usuario sigue pendiente de aprobación por el administrador'
  ),
  PastTime = <any>(
    'El tiempo para editar el pedido ya ha pasado'
  ),
}
