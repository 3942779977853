/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountType {
  CHECKING = "CHECKING",
  SAVINGS = "SAVINGS",
}

export enum AdminRole {
  ADMIN = "ADMIN",
  DISTRIBUTOR = "DISTRIBUTOR",
  OPERATOR = "OPERATOR",
}

export enum BankAccountStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum CategoryStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum CommissionStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum ConsignmentStatus {
  ACCEPTED = "ACCEPTED",
  DEVOLUTION = "DEVOLUTION",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  REVERSED = "REVERSED",
  REVISION = "REVISION",
}

export enum DevType {
  ACCEPTED = "ACCEPTED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum DiscountStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum FacType {
  CEDULA = "CEDULA",
  CONSUMIDORFINAL = "CONSUMIDORFINAL",
  PASAPORTE = "PASAPORTE",
  RUC = "RUC",
}

export enum IdType {
  CITIZENSHIP_ID = "CITIZENSHIP_ID",
  PASSPORT = "PASSPORT",
  RUC = "RUC",
}

export enum MTTRType {
  DEFAULT = "DEFAULT",
  EROSNOW = "EROSNOW",
  ESET = "ESET",
  FREEFIRE = "FREEFIRE",
  GENSHINIMPACT = "GENSHINIMPACT",
  GIFTCARD = "GIFTCARD",
  MOBILELEGENDS = "MOBILELEGENDS",
  NETDRAGON = "NETDRAGON",
  NIMOTV = "NIMOTV",
  RAZERGOLD = "RAZERGOLD",
}

export enum NotificationType {
  CONSIGNMENT_APPROVED = "CONSIGNMENT_APPROVED",
  CONSIGNMENT_IN_REVISION = "CONSIGNMENT_IN_REVISION",
  CONSIGNMENT_REJECTED = "CONSIGNMENT_REJECTED",
  CONSIGNMENT_REVERSED = "CONSIGNMENT_REVERSED",
  DEVOLUTION_ACCEPTED = "DEVOLUTION_ACCEPTED",
  DEVOLUTION_IN_PROGRESS = "DEVOLUTION_IN_PROGRESS",
  DEVOLUTION_REJECTED = "DEVOLUTION_REJECTED",
  NEW_CONSIGNMENT = "NEW_CONSIGNMENT",
  NEW_DEVOLUTION = "NEW_DEVOLUTION",
  NEW_ORDER = "NEW_ORDER",
  NEW_USER = "NEW_USER",
  ORDER_APPROVED = "ORDER_APPROVED",
  ORDER_IN_REVISION = "ORDER_IN_REVISION",
  ORDER_REJECTED = "ORDER_REJECTED",
}

export enum OrderStatus {
  ACCEPTED = "ACCEPTED",
  DEVOLUTION_ACCEPTED = "DEVOLUTION_ACCEPTED",
  DEVOLUTION_REJECTED = "DEVOLUTION_REJECTED",
  DEVOLUTION_REQUESTED = "DEVOLUTION_REQUESTED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  REVISION = "REVISION",
}

export enum ProductAccountStatus {
  AVAILABLE = "AVAILABLE",
  USED = "USED",
}

export enum ProductStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum Role {
  ADMIN = "ADMIN",
  DISTRIBUTOR = "DISTRIBUTOR",
  MAIN_CUSTOMER = "MAIN_CUSTOMER",
  OPERATOR = "OPERATOR",
  SUBSIDIARY = "SUBSIDIARY",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
}

export interface BankAccountStatusInput {
  status: BankAccountStatus;
}

export interface CategorySearcherInput {
  where?: number | null;
}

export interface ChangePasswordInput {
  userId: string;
  currentPassword: string;
  newPassword: string;
}

export interface CommissionSearcherInput {
  where?: CommissionWhereInput | null;
  pageCurrent?: number | null;
  nodesPerPage?: number | null;
}

export interface CommissionWhereInput {
  categoryId?: string | null;
  distributorId?: string | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  status?: CommissionStatus | null;
}

export interface ConsignmentSearcherInput {
  where?: ConsignmentWhereInput | null;
  pageCurrent?: number | null;
  nodesPerPage?: number | null;
}

export interface ConsignmentWhereInput {
  customerId?: string | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  status?: ConsignmentStatus[] | null;
}

export interface CrearDevolucionInput {
  id: string;
  request: string;
}

export interface CreateBankAccountInput {
  bankCode: string;
  accountHolder: string;
  accountNumber: string;
  accountType: AccountType;
}

export interface CreateCategoryInput {
  userId: string;
  name: string;
  upload: any;
  apiEnabled: boolean;
  familyId: number;
}

export interface CreateConsignmentInput {
  userId: string;
  amount: number;
  voucherNumber: string;
  transactionDate: any;
  bankAccountId: string;
  comment?: string | null;
  upload: any;
}

export interface CreateCustomerDiscountInput {
  userId: string;
  categoryId: string;
  customerId: string;
  discountRate: number;
}

export interface CreateDiscountRangeInput {
  userId: string;
  categoryId: string;
  lowerLimit: number;
  upperLimit: number;
  discountRate: number;
}

export interface CreateDistributorCommissionInput {
  userId: string;
  categoryId: string;
  distributorId: string;
  commissionTime: number;
  commissionPercentage: number;
}

export interface CreateProductAccountInput {
  productId: string;
  accountInfo: any;
}

export interface CreateProductInput {
  userId: string;
  categoryId: string;
  name: string;
  description: string;
  productionCost: number;
  saleCost: number;
  pvp: number;
  apiCost: number;
  requiredInfo: any;
  isImmediateResponse?: boolean | null;
  isAcceptDiscounts?: boolean | null;
  apiEnabled?: boolean | null;
  suggested?: boolean | null;
  mttrReqInfo?: any | null;
  mttrID?: string | null;
  mttrType?: MTTRType | null;
}

export interface CreatePurchaseOrderInput {
  userId: string;
  subid?: string | null;
  productId: string;
  orderDetail: any;
  total: number;
  facData?: FacturaDataInput | null;
}

export interface CreateUserInput {
  email: string;
  password: string;
  role: AdminRole;
}

export interface CustomerAccountWhereInput {
  dateFrom?: any | null;
  dateTo?: any | null;
  fullTextSearch?: string | null;
  isAllCustomers?: boolean | null;
  status?: UserStatus | null;
}

export interface CustomerSearcherInput {
  where?: CustomerAccountWhereInput | null;
  pageCurrent?: number | null;
  nodesPerPage?: number | null;
}

export interface DeleteBankAccountInput {
  id: string;
}

export interface DeleteCategoryInput {
  id: string;
  userId: string;
}

export interface DeleteCustomerDiscountInput {
  id: string;
  userId: string;
}

export interface DeleteDiscountRangeInput {
  id: string;
  userId: string;
}

export interface DeleteDistributorCommissionInput {
  id: string;
  userId: string;
}

export interface DeleteProductAccountInput {
  id: string;
  userId: string;
}

export interface DevolucionesSearcherInput {
  where?: DevolucionesWhereInput | null;
  pageCurrent?: number | null;
  nodesPerPage?: number | null;
}

export interface DevolucionesWhereInput {
  purchaseId?: string | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  status?: DevType[] | null;
}

export interface DiscountSearcherInput {
  where?: DiscountWhereInput | null;
  pageCurrent?: number | null;
  nodesPerPage?: number | null;
}

export interface DiscountWhereInput {
  categoryId?: string | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  status?: DiscountStatus | null;
}

export interface EmailInput {
  email: string;
}

export interface FacturaDataInput {
  factNames: string;
  facLastnames: string;
  facAddress: string;
  facID: string;
  facIDType: FacType;
}

export interface MTTRAccountInitializationInput {
  mttr_id: string;
}

export interface MTTRComprarVoucherInput {
  order_id: string;
  mttr_id: string;
  userId: string;
  productId: string;
  amount: number;
  facData?: FacturaDataInput | null;
  values?: any | null;
}

export interface MTTRConsultarInput {
  mttr_id: string;
  values: any;
}

export interface MTTRPagarInput {
  order_id: string;
  mttr_id: string;
  userId: string;
  productId: string;
  amount: number;
  facData?: FacturaDataInput | null;
  values: any;
}

export interface ProductAccountSearcherInput {
  where?: ProductAccountWhereInput | null;
  pageCurrent?: number | null;
  nodesPerPage?: number | null;
}

export interface ProductAccountWhereInput {
  dateFrom?: any | null;
  dateTo?: any | null;
  status?: ProductAccountStatus | null;
}

export interface ProductByCategoryIdInput {
  categoryId: string;
  customerId: string;
}

export interface ProductByFavoriteInput {
  customerId: string;
  limit: number;
}

export interface ProductBySearcherInput {
  search: string;
  customerId: string;
}

export interface ProductSearcherInput {
  where?: ProductWhereInput | null;
  pageCurrent?: number | null;
  nodesPerPage?: number | null;
}

export interface ProductWhereInput {
  dateFrom?: any | null;
  dateTo?: any | null;
  status?: ProductStatus | null;
  name?: string | null;
  categoryId?: string | null;
}

export interface PurchaseOrderSearcherInput {
  where?: PurchaseOrderWhereInput | null;
  pageCurrent?: number | null;
  nodesPerPage?: number | null;
}

export interface PurchaseOrderWhereInput {
  customerId?: string | null;
  customerParentId?: string | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  status?: OrderStatus[] | null;
}

export interface RecoverPasswordInput {
  email: string;
  token: string;
  newPassword: string;
  confirmPassword: string;
}

export interface RegisterStoreInput {
  name: string;
  lastName: string;
  identificationType: IdType;
  identificationNumber: string;
  cellPhone: string;
  provinceId: string;
  cityId: string;
  email: string;
  password: string;
  upload: any;
  registerLinkEmail: string;
}

export interface RegisterSubsidiaryInput {
  userId: string;
  name: string;
  lastName: string;
  identificationType: IdType;
  identificationNumber: string;
  cellPhone: string;
  provinceId: string;
  cityId: string;
  email: string;
  password: string;
}

export interface RegisterUserStoreInput {
  name: string;
  lastName: string;
  identificationType: IdType;
  identificationNumber: string;
  cellPhone: string;
  provinceId: string;
  cityId: string;
  email: string;
  password: string;
  upload: any;
}

export interface ReverseConsignmentInput {
  id: string;
  comment?: string | null;
}

export interface ServicioConsultarInput {
  userId: string;
  productId: string;
  id: string;
  key: string;
  values: any;
}

export interface ServicioGetFormInput {
  id: string;
}

export interface ServicioPagarInput {
  key: string;
  userId: string;
  productId: string;
  amount: number;
  facData?: FacturaDataInput | null;
}

export interface SignInInput {
  email: string;
  password: string;
}

export interface UpdateBankAccountInput {
  id: string;
  bankCode?: string | null;
  accountHolder?: string | null;
  accountNumber?: string | null;
  accountType?: AccountType | null;
  status?: BankAccountStatus | null;
}

export interface UpdateCategoryInput {
  id: string;
  userId: string;
  name?: string | null;
  status?: CategoryStatus | null;
  upload?: any | null;
  apiEnabled?: boolean | null;
  familyId: number;
}

export interface UpdateConsignmentInput {
  id: string;
  status: ConsignmentStatus;
  comment?: string | null;
}

export interface UpdateCostumerAPIInput {
  userId: string;
  apiEnabled?: boolean | null;
  apiRestricted?: boolean | null;
  apiImmediate?: boolean | null;
}

export interface UpdateCustomerDiscountInput {
  id: string;
  userId: string;
  categoryId?: string | null;
  customerId?: string | null;
  discountRate?: number | null;
  status?: DiscountStatus | null;
}

export interface UpdateCustomerInput {
  id: string;
  userId: string;
  name?: string | null;
  lastName?: string | null;
  cellPhone?: string | null;
  provinceId?: string | null;
  cityId?: string | null;
  email?: string | null;
}

export interface UpdateDevolucionInput {
  id: number;
  answer?: string | null;
  status: DevType;
  amount?: number | null;
}

export interface UpdateDiscountRangeInput {
  id: string;
  userId: string;
  categoryId?: string | null;
  lowerLimit?: number | null;
  upperLimit?: number | null;
  discountRate?: number | null;
  status?: DiscountStatus | null;
}

export interface UpdateDistributorCommissionInput {
  id: string;
  userId: string;
  categoryId?: string | null;
  distributorId?: string | null;
  commissionTime?: number | null;
  commissionPercentage?: number | null;
  status?: CommissionStatus | null;
}

export interface UpdateOperatorInput {
  id: number;
  userId: string;
  name?: string | null;
  code?: string | null;
  categoryId?: string | null;
  extra?: boolean | null;
  clientPercent?: number | null;
  clientPercentApi?: number | null;
  ownerPercent?: number | null;
}

export interface UpdateOperatorInternationalInput {
  id: number;
  userId: string;
  operatorName?: string | null;
  countryName?: string | null;
  categoryId?: string | null;
  extra?: boolean | null;
  clientPercent?: number | null;
  clientPercentApi?: number | null;
  ownerPercent?: number | null;
}

export interface UpdateProductAccountInput {
  id: string;
  userId: string;
  productId?: string | null;
  accountInfo?: any | null;
}

export interface UpdateProductInput {
  id: string;
  userId: string;
  categoryId?: string | null;
  name?: string | null;
  description?: string | null;
  productionCost?: number | null;
  pvp?: number | null;
  saleCost?: number | null;
  apiCost?: number | null;
  gain?: number | null;
  requiredInfo?: any | null;
  isImmediateResponse?: boolean | null;
  isAcceptDiscounts?: boolean | null;
  status?: ProductStatus | null;
  apiEnabled?: boolean | null;
  suggested?: boolean | null;
  mttrReqInfo?: any | null;
  mttrID?: string | null;
  mttrType?: MTTRType | null;
}

export interface UpdatePurchaseOrderInput {
  id: string;
  userId: string;
  status?: OrderStatus | null;
  comment?: string | null;
  result?: any | null;
}

export interface UpdateResultPurchaseOrderInput {
  id: string;
  userId: string;
  comment?: string | null;
  result?: any | null;
}

export interface UpdateServicioBasicoInput {
  id: number;
  userId: string;
  filter?: string | null;
  categoryId?: string | null;
  clientPercent?: number | null;
  clientPercentApi?: number | null;
}

export interface UpdateUserInput {
  userId: string;
  status: UserStatus;
}

export interface UserIdInput {
  userId: string;
}

export interface UserSearcherInput {
  where?: UserWhereInput | null;
  pageCurrent?: number | null;
  nodesPerPage?: number | null;
}

export interface UserWhereInput {
  customerParentId?: string | null;
  dateFrom?: any | null;
  dateTo?: any | null;
}

export interface ValidateRecoverPasswordLinkInput {
  email: string;
  token: string;
}

export interface ValidateRegisterLinkInput {
  email: string;
  token: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
