import React from 'react';
import { Link } from 'react-router-dom';
import { Titles } from '../enums/titles.enum';
import { Helmet } from 'react-helmet-async';

export const NotFound = () => (
  <div className="flex flex-col items-center justify-center h-screen">
    <Helmet>
      <title>{Titles.NOT_FOUND}</title>
    </Helmet>
    <h2 className="mb-3 text-2xl font-semibold">Página no encontrada.</h2>
    <h4 className="mb-5 text-lg font-medium">
      La página que estás buscando no existe o fue movida.
    </h4>
    <Link className="hover:underline text-cyan-600" to="/">
      Regresa al inicio &rarr;
    </Link>
  </div>
);
