import React, { useState } from 'react';
import { ApolloError, gql } from '@apollo/client/core';
import { DatePicker, Modal as AModal, Table, Tag, Tooltip } from 'antd';
import {
  IdentificationType,
  UserStatusSpanish,
  APIStatusSpanish,
} from '../../enums/spanish.enum';
import { useMutation, useQuery } from '@apollo/client';
import {
  customersQuery,
  customersQuery_customers_nodes_MainCustomer,
  customersQuery_customers_nodes_MainCustomer_customer,
  customersQuery_customers_nodes_MainCustomer_subsidiaries,
  customersQueryVariables,
} from '../../__api__/customersQuery';
import { IdType, Role, UserStatus } from '../../__api__/globalTypes';
import { Labels } from '../../enums/labels.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {
  EyeIcon,
  EyeOffIcon,
  InformationCircleIcon,
  StatusOnlineIcon,
  StatusOfflineIcon,
  XCircleIcon,
  CheckCircleIcon,
  ClipboardListIcon,
  ClipboardCopyIcon
} from '@heroicons/react/outline';
import { Exceptions } from '../../enums/exceptions.enum';
import {
  updateUserMutation,
  updateUserMutationVariables,
} from '../../__api__/updateUserMutation';
import {
  updateCostumerAPIMutation,
  updateCostumerAPIMutationVariables,
} from '../../__api__/updateCostumerAPIMutation';
import { FormError } from '../../components/form-error';
import { Modal } from '../../components/modal';
import { UPDATE_USER_MUTATION,UPDATE_CUSTOMER_API_MUTATION } from '../../common-mutations/common-mutations';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';
import { useMe } from '../../hooks/use-me';

const { RangePicker } = DatePicker;

const CUSTOMERS_QUERY = gql`
  query customersQuery($input: CustomerSearcherInput!) {
    customers(input: $input) {
      meta {
        nodeCount
        nodesPerPage
        pageCount
        pageCurrent
      }
      nodes {
        __typename
        ... on MainCustomer {
          customer {
            id
            identificationType
            identificationNumber
            name
            lastName
            cellPhone
            createdAt
            userId
            email
            status
            province
            city
            identificationImageURL
            distributorEmail
            apiEnabled
            apiRestricted
            apiImmediate
          }
          subsidiaries {
            id
            identificationType
            identificationNumber
            name
            lastName
            cellPhone
            createdAt
            userId
            email
            status
            province
            city
            distributorEmail
            apiEnabled
            apiRestricted
            apiImmediate
          }
        }
      }
    }
  }
`;

interface CustomerDetailProps {
  customer:
    | customersQuery_customers_nodes_MainCustomer_customer
    | customersQuery_customers_nodes_MainCustomer_subsidiaries
    | null;
}

const CustomerDetail: React.FC<CustomerDetailProps> = ({ customer }) => {
  return (
    <div className="flex flex-col justify-center px-2 py-4 sm:px-6 lg:px-8">
      <div className="pb-10 space-y-6">
        {customer?.hasOwnProperty('identificationImageURL') && (
          <div>
            <div className="flex items-center justify-center block w-full h-full rounded-lg">
              <img
                src={
                  (customer as customersQuery_customers_nodes_MainCustomer_customer)
                    .identificationImageURL || ''
                }
                alt={customer?.id}
                className="object-cover"
              />
            </div>
          </div>
        )}
        <div>
          <h3 className="font-medium text-gray-900">Información</h3>
          <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
            <div className="flex justify-between py-3 text-sm font-medium">
              <dt className="text-gray-500">Email</dt>
              <dd className="text-gray-900">{customer?.email}</dd>
            </div>
            <div className="flex justify-between py-3 text-sm font-medium">
              <dt className="text-gray-500">Tipo de ID</dt>
              <dd className="text-gray-900">
                {
                  IdentificationType[
                    customer?.identificationType || IdType.CITIZENSHIP_ID
                  ]
                }
              </dd>
            </div>
            <div className="flex justify-between py-3 text-sm font-medium">
              <dt className="text-gray-500">Identificación</dt>
              <dd className="text-gray-900">
                {customer?.identificationNumber}
              </dd>
            </div>
            <div className="flex justify-between py-3 text-sm font-medium">
              <dt className="text-gray-500">Nombres</dt>
              <dd className="text-gray-900">{`${customer?.name} ${customer?.lastName}`}</dd>
            </div>
            <div className="flex justify-between py-3 text-sm font-medium">
              <dt className="text-gray-500">Celular</dt>
              <dd className="text-gray-900">{customer?.cellPhone}</dd>
            </div>
            <div className="flex justify-between py-3 text-sm font-medium">
              <dt className="text-gray-500">Provincia</dt>
              <dd className="text-gray-900">{customer?.province}</dd>
            </div>
            <div className="flex justify-between py-3 text-sm font-medium">
              <dt className="text-gray-500">Ciudad</dt>
              <dd className="text-gray-900">{customer?.city}</dd>
            </div>
            <div className="flex justify-between py-3 text-sm font-medium">
              <dt className="text-gray-500">Fecha de creación</dt>
              <dd className="text-gray-900">{customer?.createdAt}</dd>
            </div>
            <div className="flex justify-between py-3 text-sm font-medium">
              <dt className="text-gray-500">Estado</dt>
              <dd className="text-gray-900">
                {
                  UserStatusSpanish[
                    customer?.status ? customer.status : UserStatus.ACTIVE
                  ]
                }
              </dd>
            </div>
            <div className="flex justify-between py-3 text-sm font-medium">
              <dt className="text-gray-500">Estado API</dt>
              <dd className="text-gray-900">DESHABILITADO</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

const success = (content: string) => {
  AModal.success({
    content,
  });
};

const DEFAULT_PAGE_SIZE = 10;

export const Customers = () => {
  const { data: user } = useMe();


  const [pageCurrent, setPageCurrent] = useState(1);
  const [nodesPerPage, setNodesPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [status, setStatus] = useState<UserStatus | null>(null);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isModalEnableVisible, setIsModalEnableVisible] = useState(false);
  const [isModalEnableAPI, setIsModalEnableAPI] = useState(false);
  const [isModalIsImmediate, setIsModalIsImmediate] = useState(false);
  const [isModalRestricted, setIsModalRestricted] = useState(false);
  const [isModalDetailVisible, setIsModalDetailVisible] = useState(false);
  const [customer, setCustomer] = useState<
    | customersQuery_customers_nodes_MainCustomer_customer
    | customersQuery_customers_nodes_MainCustomer_subsidiaries
    | null
  >(null);

  const onChange = (values: any) => {
    if (!values) {
      setDateFrom(null);
      setDateTo(null);
      return;
    }
    const dateFrom = values[0].format();
    const dateTo = values[1].format();

    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };

  const baseColumns = [
    {
      title: 'Tipo de ID',
      dataIndex: 'identificationType',
      key: 'identificationType',
    },
    {
      title: 'Identificación',
      dataIndex: 'identificationNumber',
      key: 'identificationNumber',
    },
    {
      title: 'Nombres',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Celular',
      dataIndex: 'cellPhone',
      key: 'cellPhone',
    },
    {
      title: 'Fecha creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ];

  const distributorColumns = [
    ...baseColumns,
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const adminColumns = [
    ...baseColumns,
    {
      title: 'Email distribuidor',
      dataIndex: 'distributorEmail',
      key: 'distributorEmail',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Estado API',
      dataIndex: 'statusAPI',
      key: 'statusAPI',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const columns =
    user?.me.role === Role.DISTRIBUTOR ? distributorColumns : adminColumns;

  const { data, loading, refetch } = useQuery<
    customersQuery,
    customersQueryVariables
  >(CUSTOMERS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        pageCurrent,
        nodesPerPage,
        where: {
          dateFrom,
          dateTo,
          status
        },
      },
    },
  });

  const customers = data?.customers.nodes as
    | customersQuery_customers_nodes_MainCustomer[]
    | null;

  const datasource = customers?.map((item) => ({
    key: item.customer.id,
    email: item.customer.email,
    identificationType: IdentificationType[item.customer.identificationType],
    identificationNumber: item.customer.identificationNumber,
    name: `${item.customer.name} ${item.customer.lastName}`,
    cellPhone: item.customer.cellPhone,
    createdAt: item.customer.createdAt,
    distributorEmail: item.customer.distributorEmail,
    status: (
      <Tag color={item.customer.status === 'ACTIVE' ? 'geekblue' : item.customer.status === 'PENDING' ? 'warning' : 'error'}>
        {
          UserStatusSpanish[
            item.customer.status ? item.customer.status : UserStatus.ACTIVE
          ]
        }
      </Tag>
    ),
    statusAPI: item.customer.apiEnabled ? (
      <div>
        <Tag color={'geekblue'}>{APIStatusSpanish.ON}</Tag>
        {item.customer.apiRestricted && (
          <Tag color={'error'}>RESTRINGIDO</Tag>
        )}
        {item.customer.apiImmediate && (
          <Tag color={'error'}>INMEDIATO</Tag>
        )}
      </div>
      
    ) : (<Tag color={'error'}>{APIStatusSpanish.OFF}</Tag>),
    action: (
      <div className="flex items-center justify-start">
        <div
          onClick={() => {
            setCustomer(item.customer);
            setIsModalDetailVisible(true);
          }}
          className="flex-shrink-0 mr-2"
        >
          <Tooltip title={'ver detalle'}>
            <InformationCircleIcon
              className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
              aria-hidden="true"
            />
          </Tooltip>
        </div>
        {user?.me.role !== Role.DISTRIBUTOR && (
          <div
            onClick={() => {
              setCustomer(item.customer);
              setIsModalEnableAPI(true);
            }}
            className="flex-shrink-0"
          >
            <Tooltip
              title={
                item.customer.apiEnabled ? 'desactivar API' : 'activar API'
              }
            >
              {item.customer.apiEnabled && (
                <StatusOfflineIcon
                  className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  aria-hidden="true"
                />
              )}
              {!item.customer.apiEnabled && (
                <StatusOnlineIcon
                  className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  aria-hidden="true"
                />
              )}
            </Tooltip>
          </div>
        )}
        {(user?.me.role !== Role.DISTRIBUTOR && item.customer.apiEnabled) && (
          <div
            onClick={() => {
              setCustomer(item.customer);
              setIsModalIsImmediate(true);
            }}
            className="flex-shrink-0"
          >
            <Tooltip
              title={
                item.customer.apiImmediate ? 'desactivar Entrega Inmediate' : 'activar Entrega Inmediate'
              }
            >
              {item.customer.apiImmediate && (
                <ClipboardCopyIcon
                  className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  aria-hidden="true"
                />
              )}
              {!item.customer.apiImmediate && (
                <ClipboardListIcon
                  className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  aria-hidden="true"
                />
              )}
            </Tooltip>
          </div>
        )}
        {(user?.me.role !== Role.DISTRIBUTOR && item.customer.apiEnabled) && (
          <div
            onClick={() => {
              setCustomer(item.customer);
              setIsModalRestricted(true);
            }}
            className="flex-shrink-0"
          >
            <Tooltip
              title={
                item.customer.apiRestricted ? 'desactivar Restriccion API' : 'activar Restriccion API'
              }
            >
              {item.customer.apiRestricted && (
                <XCircleIcon
                  className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  aria-hidden="true"
                />
              )}
              {!item.customer.apiRestricted && (
                <CheckCircleIcon
                  className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  aria-hidden="true"
                />
              )}
            </Tooltip>
          </div>
        )}
        {user?.me.role !== Role.DISTRIBUTOR && (
          <div
            onClick={() => {
              setCustomer(item.customer);
              setIsModalEnableVisible(true);
            }}
            className="flex-shrink-0"
          >
            <Tooltip
              title={
                item.customer.status === 'ACTIVE' ? 'desactivar' : 'activar'
              }
            >
              {item.customer.status === 'ACTIVE' && (
                <EyeOffIcon
                  className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  aria-hidden="true"
                />
              )}
              {item.customer.status !== 'ACTIVE' && (
                <EyeIcon
                  className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  aria-hidden="true"
                />
              )}
            </Tooltip>
          </div>
        )}
      </div>
    ),
    children:
      item.subsidiaries && item.subsidiaries.length > 0
        ? item.subsidiaries?.map((item) => ({
            key: item.id,
            email: item.email,
            identificationType: IdentificationType[item.identificationType],
            identificationNumber: item.identificationNumber,
            name: `${item.name} ${item.lastName}`,
            cellPhone: item.cellPhone,
            distributorEmail: item.distributorEmail,
            createdAt: item.createdAt,
            status: (
              <Tag color={item.status === 'ACTIVE' ? 'geekblue' : item.status === 'PENDING' ? 'warning' : 'error'}>
                {
                  UserStatusSpanish[
                    item.status ? item.status : UserStatus.ACTIVE
                  ]
                }
              </Tag>
            ),
            statusAPI: item.apiEnabled ? (
              <div>
                <Tag color={'geekblue'}>{APIStatusSpanish.ON}</Tag>
                {item.apiRestricted && (
                  <Tag color={'error'}>RESTRICTED</Tag>
                )}
                {item.apiImmediate && (
                  <Tag color={'error'}>IMMEDIATE</Tag>
                )}
              </div>
              
            ) : (<Tag color={'error'}>{APIStatusSpanish.OFF}</Tag>),
            action: (
              <div className="flex items-center justify-start">
                <div
                  onClick={() => {
                    setCustomer(item);
                    setIsModalDetailVisible(true);
                  }}
                  className="flex-shrink-0 mr-2"
                >
                  <Tooltip title={'ver detalle'}>
                    <InformationCircleIcon
                      className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                      aria-hidden="true"
                    />
                  </Tooltip>
                </div>
                {user?.me.role !== Role.DISTRIBUTOR && (
                <div
                  onClick={() => {
                    setCustomer(item);
                    setIsModalEnableAPI(true);
                  }}
                  className="flex-shrink-0"
                >
                  <Tooltip
                    title={
                      item.apiEnabled ? 'desactivar API' : 'activar API'
                    }
                  >
                    {item.apiEnabled && (
                      <StatusOfflineIcon
                        className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                        aria-hidden="true"
                      />
                    )}
                    {!item.apiEnabled && (
                      <StatusOnlineIcon
                        className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                        aria-hidden="true"
                      />
                    )}
                  </Tooltip>
                </div>
                )}
                
                {(user?.me.role !== Role.DISTRIBUTOR && item.apiEnabled) && (
                  <div
                    onClick={() => {
                      setCustomer(item);
                      setIsModalIsImmediate(true);
                    }}
                    className="flex-shrink-0"
                  >
                    <Tooltip
                      title={
                        item.apiImmediate ? 'desactivar Entrega Inmediate' : 'activar Entrega Inmediate'
                      }
                    >
                      {item.apiImmediate && (
                        <ClipboardCopyIcon
                          className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                          aria-hidden="true"
                        />
                      )}
                      {!item.apiImmediate && (
                        <ClipboardListIcon
                          className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                          aria-hidden="true"
                        />
                      )}
                    </Tooltip>
                  </div>
                )}
                {(user?.me.role !== Role.DISTRIBUTOR && item.apiEnabled) && (
                  <div
                    onClick={() => {
                      setCustomer(item);
                      setIsModalRestricted(true);
                    }}
                    className="flex-shrink-0"
                  >
                    <Tooltip
                      title={
                        item.apiRestricted ? 'desactivar Restriccion API' : 'activar Restriccion API'
                      }
                    >
                      {item.apiRestricted && (
                        <XCircleIcon
                          className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                          aria-hidden="true"
                        />
                      )}
                      {!item.apiRestricted && (
                        <CheckCircleIcon
                          className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                          aria-hidden="true"
                        />
                      )}
                    </Tooltip>
                  </div>
                )}
                <div
                  onClick={() => {
                    setCustomer(item);
                    setIsModalEnableVisible(true);
                  }}
                  className="flex-shrink-0"
                >
                  <Tooltip
                    title={item.status === 'ACTIVE' ? 'desactivar' : 'activar'}
                  >
                    {item.status === 'ACTIVE' && (
                      <EyeOffIcon
                        className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                        aria-hidden="true"
                      />
                    )}
                    {item.status !== 'ACTIVE' && (
                      <EyeIcon
                        className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                        aria-hidden="true"
                      />
                    )}
                  </Tooltip>
                </div>
              </div>
            ),
          }))
        : null,
  }));

  const onCompleted = async (data: updateUserMutation) => {
    const { updateUser: userId } = data;
    if (userId) {
      setErrorMessage(null);
      setIsModalEnableVisible(false);
      success('Se actualizó el cliente satisfactoriamente');
      await refetch();
    }
  };

  const onError = (error: ApolloError) => {
    if (error) {
      setErrorMessage(Exceptions[error.message as any]);
    }
  };

  const onCompletedAPI = async (data: updateCostumerAPIMutation) => {
    const { updateCostumerAPI: userId } = data;
    if (userId) {
      setErrorMessage(null);
      setIsModalEnableAPI(false);
      setIsModalIsImmediate(false);
      setIsModalRestricted(false);
      success('Se actualizó el permiso de uso de API satisfactoriamente');
      await refetch();
    }
  };

  const [updateUserMutation, { loading: loadingMutation }] = useMutation<
    updateUserMutation,
    updateUserMutationVariables
  >(UPDATE_USER_MUTATION, { onCompleted, onError });

  const enableOrDisableUser = async (
    userId: string | null | undefined,
    isEnabled: boolean,
  ) => {
    if (!loadingMutation) {
      try {
        await updateUserMutation({
          variables: {
            input: {
              userId: userId || '',
              status: isEnabled ? UserStatus.ACTIVE : UserStatus.INACTIVE,
            },
          },
        });
      } catch (error) {}
    }
  };

  const [updateCostumerAPIMutation, { loading: loadingMutation2 }] = useMutation<
    updateCostumerAPIMutation,
    updateCostumerAPIMutationVariables
  >(UPDATE_CUSTOMER_API_MUTATION, { onCompleted:onCompletedAPI, onError });

  const enableOrDisableAPI = async (
    userId: string | null | undefined,
    isEnabled?: boolean,
    isImmediate?: boolean,
    isRestricted?: boolean,
  ) => {

    if (!loadingMutation2) {
      try {
        await updateCostumerAPIMutation({
          variables: {
            input: {
              userId: userId || '',
              apiEnabled: isEnabled,
              apiImmediate: isImmediate,
              apiRestricted: isRestricted,
            },
          },
        });
      } catch (error) {}
    }
  };

  return (
    <div className="py-2">
      <Modal
        title={`${
          customer?.status === UserStatus.ACTIVE ? 'Desactivar' : 'Activar'
        } cliente ${customer?.name} ${customer?.lastName}`}
        visible={isModalEnableVisible}
        onOk={async () => {
          await enableOrDisableUser(
            customer?.userId,
            customer?.status !== UserStatus.ACTIVE,
          );
        }}
        onCancel={() => {
          setIsModalEnableVisible(false);
          setErrorMessage(null);
        }}
        footer={true}
        afterClose={async () => {
          setErrorMessage(null);
          await refetch();
        }}
        child={
          <div className="flex flex-col">
            <span className="mb-4">
              {`Estás seguro de ${
                customer?.status === UserStatus.ACTIVE
                  ? 'desactivar'
                  : 'activar'
              } el cliente ${customer?.name} ${customer?.lastName}?`}
            </span>
            {errorMessage && (
              <FormError errorMessage={Exceptions[errorMessage as any]} />
            )}
          </div>
        }
      />
      <Modal
        title={`${
          customer?.apiEnabled ? 'Desactivar API' : 'Activar API'
        } cliente ${customer?.name} ${customer?.lastName}`}
        visible={isModalEnableAPI}
        onOk={async () => {
          await enableOrDisableAPI(customer?.id,!customer?.apiEnabled,undefined,undefined);
        }}
        onCancel={() => {
          setIsModalEnableAPI(false);
          setErrorMessage(null);
        }}
        footer={true}
        afterClose={async () => {
          setErrorMessage(null);
          await refetch();
        }}
        child={
          <div className="flex flex-col">
            <span className="mb-4">
              {`Estás seguro de ${
                customer?.apiEnabled
                  ? 'desactivar el API'
                  : 'activar el API'
              } al cliente ${customer?.name} ${customer?.lastName}?`}
            </span>
            {errorMessage && (
              <FormError errorMessage={Exceptions[errorMessage as any]} />
            )}
          </div>
        }
      />
      <Modal
        title={`${
          customer?.apiImmediate ? 'Desactivar Entrega Inmediate' : 'Activar Entrega Inmediate'
        } cliente ${customer?.name} ${customer?.lastName}`}
        visible={isModalIsImmediate}
        onOk={async () => {
          await enableOrDisableAPI(customer?.id,undefined,!customer?.apiImmediate,undefined);
        }}
        onCancel={() => {
          setIsModalIsImmediate(false);
          setErrorMessage(null);
        }}
        footer={true}
        afterClose={async () => {
          setErrorMessage(null);
          await refetch();
        }}
        child={
          <div className="flex flex-col">
            <span className="mb-4">
              {`Estás seguro de ${
                customer?.apiImmediate
                  ? 'desactivar la revision de stock obligatoria para la entrega inmediata en el API'
                  : 'activar la revision de stock obligatoria para la entrega inmediata en el API'
              } al cliente ${customer?.name} ${customer?.lastName}?`}
            </span>
            {errorMessage && (
              <FormError errorMessage={Exceptions[errorMessage as any]} />
            )}
          </div>
        }
      />
      
      <Modal
        title={`${
          customer?.apiRestricted ? 'Desactivar Restricciones de API' : 'Activar Restricciones de API'
        } cliente ${customer?.name} ${customer?.lastName}`}
        visible={isModalRestricted}
        onOk={async () => {
          await enableOrDisableAPI(customer?.id,undefined,undefined,!customer?.apiRestricted);
        }}
        onCancel={() => {
          setIsModalRestricted(false);
          setErrorMessage(null);
        }}
        footer={true}
        afterClose={async () => {
          setErrorMessage(null);
          await refetch();
        }}
        child={
          <div className="flex flex-col">
            <span className="mb-4">
              {`Estás seguro de ${
                customer?.apiRestricted
                  ? 'desactivar las restricciones de API para solamenta permitir productos de entrega inmediata'
                  : 'activar las restricciones de API para solamenta permitir productos de entrega inmediata'
              } al cliente ${customer?.name} ${customer?.lastName}?`}
            </span>
            {errorMessage && (
              <FormError errorMessage={Exceptions[errorMessage as any]} />
            )}
          </div>
        }
      />
      <Modal
        title="Detalle del cliente"
        visible={isModalDetailVisible}
        onOk={async () => {
          setIsModalDetailVisible(false);
          await refetch();
        }}
        onCancel={async () => {
          setIsModalDetailVisible(false);
          await refetch();
        }}
        child={<CustomerDetail customer={customer} />}
      />
      <div className="mx-auto max-w-7xl">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-medium text-gray-900 leading-6">
            Clientes
          </h3>
        </div>
        <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
          <form className="flex flex-col items-center w-full py-8 bg-white shadow sm:rounded-3xl sm:px-4">
            <div className="mb-4 mr-auto">
              {Labels.FILTERS}
              <FontAwesomeIcon icon={faFilter} className="ml-3 text-xl" />
            </div>
            <div className="max-w-xl grid grid-cols-1 gap-5">
              <div className="flex items-center justify-center">
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  <span className="mr-3">{Labels.FILTER_DATES}</span>
                </label>
                <RangePicker
                  showTime={true}
                  placeholder={['Fecha desde', 'Fecha hasta']}
                  className="w-full rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={onChange}
                  disabledDate={(current) => moment().isBefore(current)}
                />
              </div>
              <div className="flex items-center justify-center">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium text-gray-700"
                >
                  <span className="mr-3">{Labels.FILTER_STATUS}</span>
                </label>
                <select
                  name="status"
                  onChange={(event) => {
                    const value = event.target.value;
                    setStatus(value ? (value as UserStatus) : null);
                  }}
                  className="w-full select"
                >
                  <option value="">Todos</option>
                  {Object.keys(UserStatus).map((state) => (
                    <option key={state} value={state}>
                      {UserStatusSpanish[state as any]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            
            
          </form>
        </div>
        <SkeletonTable
          active={true}
          loading={loading}
          columns={columns as SkeletonTableColumnsType[]}
        >
          <Table
            dataSource={datasource}
            columns={columns}
            pagination={{
              defaultPageSize: DEFAULT_PAGE_SIZE,
              pageSize: nodesPerPage,
              current: pageCurrent,
              total: data?.customers.meta.nodeCount,
              responsive: true,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '100'],
              onShowSizeChange: (current, size) => setNodesPerPage(size),
              onChange: (page) => setPageCurrent(page),
            }}
          />
        </SkeletonTable>
      </div>
    </div>
  );
};
