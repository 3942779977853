import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { Login } from '../pages/login';
import { RegisterStore } from '../pages/register-store';
import { NotFound } from '../pages/404';
import { LandingPage } from '../pages/landing-page';
import { TermsOfService } from '../pages/terms-of-service';
import { RecoverPassword } from '../pages/recover-password';
import { ResetPassword } from '../pages/reset-password';
import { RegisterNewUser } from '../pages/register-new-user';
import { Privacity } from '../pages/privacity';

export const LoggedOutRouter = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  };

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/privacity">
          <Privacity />
        </Route>
        <Route path="/terms-service">
          <TermsOfService />
        </Route>
        <Route path="/register-store">
          <RegisterStore />
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/register-new-user">
          <RegisterNewUser />
        </Route>
        <Route path="/recover-password" exact>
          <RecoverPassword />
        </Route>
        <Route path="/reset-password" exact>
          <ResetPassword />
        </Route>
        <Route path="/" exact>
          <LandingPage />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
};
