import React, { Fragment, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Labels } from '../enums/labels.enum';
import { Link } from 'react-router-dom';
import logo from '../images/byller-logo1.png';
import logo1 from '../images/byller-logo.png';

/*Icons streaming*/
import netflix from '../images/netflix-icon.png';
import disney from '../images/disney-icon.png';
import prime from '../images/prime-icon.png';
import iptv from '../images/IPTV-icon.png';
import spotify from '../images/spotify-icon.png';
import hbo from '../images/HBOMax.png';
import crunchyrroll from '../images/crunchyrroll.png';
import ebay from '../images/ebay.png';
import camvaPro from '../images/camvaPro.jpeg';
import start from '../images/start.png';
import plex from '../images/plex.jpeg';

/*Icons juegos*/
import freeFire from '../images/free-fire-icon.jpeg';
import callOfDuty from '../images/call-of-duty-icon.png';
import roblox from '../images/roblox-icon.png';
import clashRoyale from '../images/clash-royale-icon.png';
import pubg from '../images/pubg-mobile-icon.png';
import legendsBangBan from '../images/Mobile-legends-bang-bang.png';
import stumble from '../images/Stumble.png';
import brawl from '../images/Brawl-star.avif';
import ws from '../images/ws-icon.png';
//import telegram from '../images/telegram.png';
import youtube from '../images/youtube-icon.png';
import itunes from '../images/itunes-icon.png';
import paypal from '../images/paypal-icon.png';
import fortnite from '../images/fortnite-icon.jpeg';
//import ps from '../images/ps-icon.jpeg';
//import steam from '../images/steam.jpeg';
//import xbox from '../images/xbox-icon.jpeg';
//import cover from '../images/cover.jpeg';
import contact from '../images/support.png';
import freeCard from '../images/free-dire-card.png';
import freeLevel from '../images/free-fire-level.png';
import magistv from '../images/magistv.png';
import razer from '../images/razer.png';
import parchis from '../images/parchis.png';
import clashOfClans from '../images/clashOfClans.avif';
import clashroyalePorID from '../images/clashroyalePorID.avif';
import hayDay from '../images/hayDay.avif';


/*Icons servicio basicos*/
import agua from '../images/Agua.png';
import luz from '../images/Luz.png';
import alicuotas from '../images/Alicuotas.jpeg';
import transito from '../images/Transito.jpeg';
import sri from '../images/SRI.png';
import pagos from '../images/Pagos.png';
import consejoJudicactura from '../images/Consejo judicatura.png';
import creditos from '../images/Credito.png';
import depositos from '../images/Depositos.png';
import iess from '../images/IESS.png';
import municipios from '../images/Municipios.png';
import ATM from '../images/ATM.png';
import AMT from '../images/AMT.jpg';
import catalogos from '../images/catalogos.png';
import akiMovil from '../images/akiMovil.png';
import Maxiplus from '../images/Maxiplus.png';
import direcTv from '../images/direcTv.png';
import planes from '../images/planes.png';

/*Icons recargas celulares*/
import claro from '../images/Claro.jpeg';
import movistar from '../images/Movistar.png';
import tuenti from '../images/Tuenti.png';
import cnt from '../images/CNT.png';
import cellvoz from '../images/Cellvoz.png';
import virgin from '../images/Virgin.png';
import une from '../images/Une.jpeg';
import movilExito from '../images/Movil-exito.jpeg';
import luzCuba from '../images/Luz-cuba.jpeg';
import flashMobile from '../images/Flash-mobile.png';
import digitel from '../images/Gigitel.jpeg';
import cubacell from '../images/Cubacell.jpeg';

/*Icons gifts cards*/
import nintento from '../images/nintendo-icon.jpeg';
import pinRoblox from '../images/Pines-roblox.jpeg';
import mcafee from '../images/Pines-mcafee.png';
import pinFortnite from '../images/Pines-fortnite.png';
import pinDiscrod from '../images/Pines-discord.png';
import EA from '../images/Pines-EA.png';
import razerGold from '../images/Pines-razer-gold.png';
import pinLegendsBangBang from '../images/Pines-legends-bang-bang.png';
import minecraft from '../images/Pines-minecraft.png';
import pinXbox from '../images/Pines-xbox.png';
import pinSteam from '../images/Pines-steam.png';
import pinPlayStation from '../images/Pines-play-station.png';
import nexon from '../images/nexon.jpeg';

import { FooterLogout } from '../components/footer-logout';
import { Titles } from '../enums/titles.enum';
import { Helmet } from 'react-helmet-async';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaAngleUp } from 'react-icons/fa';

import apiRest from '../images/api.jpeg';
import slider1 from '../images/slider/slider-1.png';
import slider2 from '../images/slider/slider-2.png';
import slider3 from '../images/slider/slider-3.png';
import slider4 from '../images/slider/slider-4.png';
import slider5 from '../images/slider/slider-5.png';
import slider6 from '../images/slider/slider-6.png';
import slider7 from '../images/slider/slider-7.png';
import slider8 from '../images/slider/slider-8.png';
import slider9 from '../images/slider/slider-9.png';
import slider10 from '../images/slider/slider-10.png';

const navigation = [
  { name: 'Juegos y streaming', href: '#play' },
  { name: 'Servicios básicos', href: '#services' },
  { name: 'Recargas celulares', href: '#recargas' },
  { name: 'API', href: '#api' },
];

const plays = [
  {
    name: 'Free Fire ID',
    description: 'Adquiere diamantes de Free Fire por ID.',
    image: freeFire,
  },
  {
    name: 'Call of Duty®',
    description:
      'No pares de jugar, disfruta de uno de los mejores juegos de guerra.',
    image: callOfDuty,
  },
  {
    name: 'Roblox',
    description: 'Disfruta de esta plataforma de videojuegos en línea.',
    image: roblox,
  },
  {
    name: 'Clash Royale',
    description: '¡Atrévete a pisar la arena! Multijugador y en tiempo real.',
    image: clashRoyale,
  },
  {
    name: 'Pubg Mobile por ID',
    description: 'El mejor juego de Battle Royale para móviles.',
    image: pubg,
  },
  {
    name: 'Fortnite',
    description:
      'Fortnite es un Battle Royale gratuito con un montón de modos de juego diferentes.',
    image: fortnite,
  },
  {
    name: 'Stumble guys',
    description:
      'Es un juego multijugador de estilo «battle royale» donde los jugadores deben superar obstáculos y correr hasta ser el único en pie.',
    image: stumble,
  },
  {
    name: 'Pines razer gold',
    description:
      'Cargar saldo a su cuenta de razer.',
    image: razer,
  },
  {
    name: 'Parchis',
    description:
      'Es un juego multijugador que se puede jugar con 2 o 4 jugadores..',
    image: parchis,
  },
  {
    name: 'Clash of clans por ID',
    description:
      'Construye tu aldea, crea un clan y compite en las épicas guerras de clanes junto a millones de jugadores',
    image: clashOfClans,
  },
  {
    name: 'Clash royale por ID',
    description:
      '¡Únete a un clan o crea uno con otros jugadores para compartir cartas, luchar en las guerras de clanes y ganar recompensas INCREÍBLES!',
    image: clashroyalePorID,
  },
  {
    name: 'Hay day',
    description:
      'Construye una granja de ensueño, pesca, cría animales, explora el valle, cosecha cultivos y personaliza tu propio paraíso',
    image: hayDay,
  },
  {
    name: 'Mobile legends bang ban',
    description:
      'Es un divertido MOBA en la línea de juegos como DOTA 2 y League of Legends.',
    image: legendsBangBan,
  },
  {
    name: 'Brawl star por ID',
    description:
      'Es uno de los mejores MOBA para Android en el que te adentrarás en dinámicas batallas 3v3 repletas de acción.',
    image: brawl,
  },
  {
    name: 'Tarjetas Free Fire por ID',
    description:
      'La suscripción activa te permite acceder a una cierta cantidad de diamantes de forma gratuita.',
    image: freeCard,
  },
  {
    name: 'Pase de nivel por cuenta',
    description: 'Tarjeta para pase de nivel por cuenta.',
    image: freeLevel,
  },
  {
    name: 'Saldo Paypal',
    description: 'Adquiere saldo a tu cuenta Paypal.',
    image: paypal,
  },
];

const features = [
  {
    name: 'Netflix',
    description: 'Películas y series ilimitadas y mucho más.',
    image: netflix,
  },
  {
    name: 'YouTube',
    description:
      'Disfruta de los vídeos y la música que te gustan, sube material original y compartelo.',
    image: youtube,
  },
  {
    name: 'MAGISTV',
    description: 'TV por internet, Streaming y ON Demand.',
    image: magistv,
  },
  {
    name: 'Disney + Plus',
    description: 'Las mejores historias, en un mismo lugar.',
    image: disney,
  },
  {
    name: 'Prime Video',
    description:
      'Disfruta de series Amazon Originals exclusivas, además de películas.',
    image: prime,
  },
  {
    name: 'IPTV',
    description: 'TV por internet, Streaming y ON Demand.',
    image: iptv,
  },
  {
    name: 'Spotify',
    description:
      'Escucha música sin límites en tu teléfono y otros dispositivos.',
    image: spotify,
  },
  {
    name: 'Canva PRO',
    description: 'Obtén acceso ilimitado a fotos y elementos, el Kit de Marca, el Quitafondos y más.',
    image: camvaPro,
  },
  {
    name: 'HBO Max',
    description: 'HBO Max es una plataforma de transmisión de contenido en video de marcas icónicas como Max Originals, HBO, Universo DC, Cartoon Network y Warner.',
    image: hbo,
  },
  {
    name: 'Crunchyroll',
    description: 'Embárcate en la aventura del anime con Crunchyroll, el mejor lugar donde podrás disfrutar de un enorme catálogo de series y películas de anime.',
    image: crunchyrroll,
  },
  {
    name: 'Star',
    description: 'Experiencia de entretenimiento exclusiva. Eventos deportivos de ESPN en vivo y con máxima calidad.',
    image: start,
  },
  {
    name: 'PLEX',
    description: 'Plex te facilita el acceso gratuito a películas y programas de TV.',
    image: plex,
  }
];

const gifts = [
  {
    name: 'Pin razer gold',
    description: 'Codigo para cargar saldo a su cuenta.',
    image: razerGold,
  },
  {
    name: 'Pin EA Sports FC Mobile',
    description: 'Codigo para cargar saldo a su cuenta.',
    image: EA,
  },
  {
    name: 'Pin Legends Bang Bang',
    description: 'Codigo para cargar saldo a su cuenta.',
    image: pinLegendsBangBang,
  },
  {
    name: 'Pin fortnite',
    description: 'Codigo para cargar saldo a su cuenta.',
    image: pinFortnite,
  },
  {
    name: 'Pin mcafee antivirus',
    description: 'Codigo para activar su cuenta.',
    image: mcafee,
  },
  {
    name: 'Pin Ebay',
    description: 'La Tarjeta de Regalo será considerada como una forma de pago al momento de realizar la compra.',
    image: ebay,
  },
  {
    name: 'Pin itunes',
    description: 'Codigo para cargar saldo a su cuenta.',
    image: itunes,
  },
  {
    name: 'Pin roblox',
    description: 'Codigo para cargar saldo a su cuenta.',
    image: pinRoblox,
  },
  {
    name: 'Pin minecraft',
    description: 'Codigo para cargar saldo a su cuenta.',
    image: minecraft,
  },
  {
    name: 'Pin discrod',
    description: 'Codigo para cargar saldo a su cuenta.',
    image: pinDiscrod,
  },
  {
    name: 'Pin nintendo E-Shop',
    description: 'Codigo para cargar saldo a su cuenta.',
    image: nintento,
  },
  {
    name: 'Pin play station',
    description: 'Codigo para cargar saldo a su cuenta.',
    image: pinPlayStation,
  },
  {
    name: 'Pin steam',
    description: 'Codigo para cargar saldo a su cuenta.',
    image: pinSteam,
  },
  {
    name: 'Pin xbox',
    description: 'Codigo para cargar saldo a su cuenta.',
    image: pinXbox,
  },
  {
    name: 'Pin nexon',
    description: 'Codigo para cargar saldo a su cuenta.',
    image: nexon,
  },



];

const services = [
  {
    name: 'Agua',
    description: 'Pagos de agua de todas la provincias.',
    image: agua,
  },
  {
    name: 'Luz',
    description: 'Pagos de luz de todas las provincias.',
    image: luz,
  },
  {
    name: 'Pago alicuotas',
    description: 'Pago de alicuotas.',
    image: alicuotas,
  },
  {
    name: 'Pagos transito',
    description:
      'Pago con el número de ordenes.',
    image: transito,
  },
  {
    name: 'Pagos SRI',
    description: 'Pagos ordenes del SRI.',
    image: sri,
  },
  {
    name: 'Depósitos',
    description:
      'Depósitos disponibles para algunos bancos y cooperativas.',
    image: depositos,
  },
  {
    name: 'Pago créditos',
    description: 'Pago de creditos en tienda como DePrati, Etafashion etc.',
    image: creditos,
  },
  {
    name: 'IESS',
    description: 'Pago de ordenes del IESS.',
    image: iess,
  },
  {
    name: 'Municipios',
    description: 'Pago de ordenes del municipio.',
    image: municipios,
  },
  {
    name: 'Consejo judicactura',
    description: 'Pago de ordenes del consejo judicactura.',
    image: consejoJudicactura,
  },
  {
    name: 'ATM',
    description: 'Pago de ordenes de ATM.',
    image: ATM,
  },
  {
    name: 'AMT',
    description: 'Pago de ordenes de AMT.',
    image: AMT,
  },
  {
    name: 'Planes',
    description: 'Pago de planes.',
    image: planes,
  },
  {
    name: 'catalogos',
    description: 'Pago de catalogos.',
    image: catalogos,
  },
  {
    name: 'Pagos',
    description: 'Pagos varios.',
    image: pagos,
  },
];

const recargas = [
  {
    name: 'Recargas claro',
    description: 'Disponible recargas normales o paquetes.',
    image: claro,
  },
  {
    name: 'Recargas movistar',
    description: 'Disponible recargas normales o paquetes.',
    image: movistar,
  },
  {
    name: 'Recargas tuenti',
    description: 'Disponible recargas normales o paquetes.',
    image: tuenti,
  },
  {
    name: 'Recargas CNT',
    description: 'Disponible recargas normales o paquetes.',
    image: cnt,
  },
  {
    name: 'Recargas Aki Movil',
    description: 'Disponible recargas normales.',
    image: akiMovil,
  },
  {
    name: 'Recargas Maxiplus',
    description: 'Disponible recargas normales.',
    image: Maxiplus,
  },
  {
    name: 'Recargas direcTv',
    description: 'Recargar para tu direcTV no te pierdas de .',
    image: direcTv,
  },
  {
    name: 'Cellvoz colombia',
    description: 'Disponible recargas normales.',
    image: cellvoz,
  },
  {
    name: 'Virgin colombia',
    description: 'Disponible recargas normales.',
    image: virgin,
  },
  {
    name: 'Une colombia',
    description: 'Disponible recargas normales.',
    image: une,
  },
  {
    name: 'Movil exito colombia',
    description: 'Disponible recargas normales.',
    image: movilExito,
  },
  {
    name: 'Flash mobile colombia',
    description: 'Disponible recargas normales.',
    image: flashMobile,
  },
  {
    name: 'Digitel venezuela',
    description: 'Disponible recargas normales.',
    image: digitel,
  },
  {
    name: 'Cubacell cuba',
    description: 'Disponible recargas normales.',
    image: cubacell,
  },
  {
    name: 'Luz cuba',
    description: 'Disponible pago de luz para cuba.',
    image: luzCuba,
  },

];

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="absolute top-1/2 right-0 z-10 bg-transparent hover:bg-orange-600 transform -translate-y-1/2 cursor-pointer"
      style={{ right: 10 }}
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-8 h-8 text-orange-600 hover:text-white">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
      </svg>
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="absolute top-1/2 left-0 z-10 bg-transparent hover:bg-orange-600 transform -translate-y-1/2 cursor-pointer"
      style={{ left: 10 }}
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-8 h-8 text-orange-600 hover:text-white">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
      </svg>
    </div>
  );
};

export const LandingPage = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Para que el carrusel pase automáticamente
    autoplaySpeed: 2500, // Tiempo en milisegundos para cambiar de imagen
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    arrows: true,
  };

  const [isNavSticky, setNavSticky] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    setNavSticky(window.scrollY > 0);
    setIsVisible(window.scrollY > 200);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="bg-white relative">
      <Helmet>
        <title>{Titles.LANDING}</title>
      </Helmet>
      <div className="relative overflow-hidden">
        <div className={`w-full top-0 left-0 z-50 ${isNavSticky ? 'fixed' : 'fixed'}`}>
          <Popover as="header" className={`${isNavSticky ? 'bg-orange-700 bg-opacity-100' : 'bg-black bg-opacity-25'}`}>
            {({ open }) => (
              <>
                <div className="pt-6">
                  <nav className="relative flex items-center justify-between px-4 mx-auto mb-4 max-w-7xl sm:px-6" aria-label="Global">
                    <div className="flex items-center flex-1">
                      <div className="flex items-center justify-between w-full md:w-auto">
                        <Link to="/">
                          <span className="sr-only">Byllergames</span>
                          <img className="hidden w-auto h-28 md:block" src={logo} alt="Byllergames" />
                        </Link>
                        <div className="flex items-center -mr-2 md:hidden">
                          <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-gray-900 rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                            <span className="sr-only">Abrir menu principal</span>
                            <MenuIcon className="w-6 h-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className="hidden space-x-8 md:flex md:ml-10">
                        {navigation.map((item) => (
                          <a key={item.name} href={item.href} className="text-base font-medium text-white hover:text-green-300">
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                    <div className="hidden md:flex md:items-center md:space-x-6">
                      <Link className="px-4 py-2 font-bold text-white bg-green-600 border-b-4 border-green-600 rounded hover:bg-green-800 hover:border-green-800 hover:text-white" to="/login">
                        {Labels.LOG_IN}
                      </Link>
                      <Link className="px-4 py-2 font-bold text-white bg-blue-600 border-b-4 border-blue-600 rounded hover:bg-blue-800 hover:border-blue-800 hover:text-white" to="/register-new-user">
                        {Labels.REGISTER_NEW_USER_LANDING}
                      </Link>
                    </div>
                  </nav>
                </div>

                <Transition show={open} as={Fragment} enter="duration-150 ease-out" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="duration-100 ease-in" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                  <Popover.Panel focus static className="absolute inset-x-0 top-0 p-2 transition transform origin-top md:hidden">
                    <div className="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
                      <div className="flex items-center justify-between px-5 pt-4">
                        <div>
                          <img className="w-auto h-20" src={logo1} alt="Byllergames" />
                        </div>
                        <div className="-mr-2">
                          <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                            <span className="sr-only">Close menu</span>
                            <XIcon className="w-6 h-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className="pt-5 pb-6">
                        <div className="px-2 space-y-1">
                          {navigation.map((item) => (
                            <a key={item.name} href={item.href} className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-50">
                              {item.name}
                            </a>
                          ))}
                        </div>
                        <div className="px-5 mt-6">
                          <p className="text-base font-medium text-center text-gray-500">
                            Ya tienes una cuenta?{' '}
                            <Link className="text-green-900 green-900 hover:underline" to="/login">
                              {Labels.LOG_IN}
                            </Link>
                          </p>

                          <p className="text-base green-900 font-medium text-center text-gray-500">
                            Quieres crear una cuenta?{' '}
                            <Link className="text-gray-900 hover:underline" to="/register-new-user">
                              {Labels.REGISTER_NEW_USER_LANDING}
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
        <main className="relative z-0">
          <Slider {...sliderSettings}>
            <div>
              <img src={slider1} alt="Imagen 1" className="w-full h-full" />
            </div>
            <div>
              <img src={slider2} alt="Imagen 2" className="w-full h-full" />
            </div>
            <div>
              <img src={slider3} alt="Imagen 3" className="w-full h-full" />
            </div>
            <div>
              <img src={slider4} alt="Imagen 4" className="w-full h-full" />
            </div>
            <div>
              <img src={slider5} alt="Imagen 5" className="w-full h-full" />
            </div>
            <div>
              <img src={slider6} alt="Imagen 6" className="w-full h-full" />
            </div>
            <div>
              <img src={slider7} alt="Imagen 7" className="w-full h-full" />
            </div>
            <div>
              <img src={slider8} alt="Imagen 8" className="w-full h-full" />
            </div>
            <div>
              <img src={slider9} alt="Imagen 9" className="w-full h-full" />
            </div>
            <div>
              <img src={slider10} alt="Imagen 10" className="w-full h-full" />
            </div>
          </Slider>
          <div className="mt-5"></div>

          <a href="https://wa.me/+593961735894"
            className="fixed bottom-5 left-5 z-50"
            target="_blank"
            rel="noopener noreferrer">
            <img src={ws}
              alt="WhatsApp"
              className="w-12 h-auto" />
          </a>

          <div className="back-to-top">
            {isVisible && (
              <div className="fixed bottom-5 right-5 z-50 cursor-pointer" onClick={scrollToTop}>
                <FaAngleUp className="back-to-top-icon bg-orange-600 w-8 h-8 text-white rounded-full transition-opacity duration-300" />
              </div>)}
          </div>

          <div
            id="play"
            className="relative py-16 bg-white sm:py-24 lg:py-5"
          >
            <div className="relative pt-16 bg-gray-50 sm:pt-24 lg:pt-32">
              <div className="max-w-md px-4 mx-auto text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
                <p className="mt-2 text-3xl font-extrabold tracking-tight text-orange-600 sm:text-4xl">
                  RECARGAS DE JUEGOS
                </p>
                <hr />
                <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
                  No te quedes sin saldo, ni sin créditos. Recargamos tus juegos
                  favoritos. Mantente al día con conectividad de alta calidad.
                  ¡Recarga tu paquete ideal!.
                  <br></br><b>Disponemos de recargas con entrega inmediata !</b>
                </p>

                <div className="mt-12">
                  <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                    {plays.map((feature) => (
                      <div key={feature.name} className="pt-6">
                        <div className="px-6 pb-8 rounded-lg flow-root bg-gray-50">
                          <div className="-mt-6">
                            <div>
                              <span className="inline-flex items-center justify-center p-3 bg-white shadow-lg rounded-md">
                                <img
                                  src={feature.image}
                                  className="w-20 h-20 text-white"
                                  aria-hidden="true"
                                  alt={feature.name}
                                />
                              </span>
                            </div>
                            <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                              {feature.name}
                            </h3>
                            <p className="mt-5 text-base text-gray-500">
                              {feature.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="platforms"
            className="relative py-16 bg-white sm:py-24 lg:py-32"
          >
            <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-orange-600 sm:text-4xl">
                CUENTAS STREAMING
              </p>
              <hr />
              <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
                Disfruta del entretenimiento en su máxima expresión.
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {features.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="px-6 pb-8 rounded-lg flow-root bg-gray-50">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center p-3 bg-white shadow-lg rounded-md">
                              <img
                                src={feature.image}
                                className="w-20 h-20 text-white"
                                aria-hidden="true"
                                alt={feature.name}
                              />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                            {feature.name}
                          </h3>
                          <p className="mt-5 text-base text-gray-500">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div
            id="gifts"
            className="relative py-16 bg-white sm:py-24 lg:py-32"
          >
            <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-orange-600 sm:text-4xl">
                GIFTS CARDS
              </p>
              <hr />
              <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
                Entregamos el código de la tarjeta comprada para que la puedas activar en cuenta.
                <br></br><b>Disponemos de gifts cards con entrega inmediata !</b>
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {gifts.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="px-6 pb-8 rounded-lg flow-root bg-gray-50">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center p-3 bg-white shadow-lg rounded-md">
                              <img
                                src={feature.image}
                                className="w-20 h-20 text-white"
                                aria-hidden="true"
                                alt={feature.name}
                              />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                            {feature.name}
                          </h3>
                          <p className="mt-5 text-base text-gray-500">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div
            id="services"
            className="relative py-16 bg-white sm:py-24 lg:py-32"
          >
            <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-orange-600 sm:text-4xl">
                SERVICIOS BÁSICOS
              </p>
              <hr />
              <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
                Disponemos de todos los servicios básicos, agua luz, telefono, planes, catálogos, depósitos, pagos tarjetas de crédito etc.
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {services.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="px-6 pb-8 rounded-lg flow-root bg-gray-50">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center p-3 bg-white shadow-lg rounded-md">
                              <img
                                src={feature.image}
                                className="w-20 h-20 text-white"
                                aria-hidden="true"
                                alt={feature.name}
                              />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                            {feature.name}
                          </h3>
                          <p className="mt-5 text-base text-gray-500">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div
            id="recargas"
            className="relative py-16 bg-white sm:py-24 lg:py-32"
          >
            <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-orange-600 sm:text-4xl">
                RECARGAS CELULARES
              </p>
              <hr />
              <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
                Disponemos de recargas normales y paquetes de todas las operadoras, adicional tambien disponemos de recargas internacionales cuba, colombia y venezuela.
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {recargas.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="px-6 pb-8 rounded-lg flow-root bg-gray-50">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center p-3 bg-white shadow-lg rounded-md">
                              <img
                                src={feature.image}
                                className="w-20 h-20 text-white"
                                aria-hidden="true"
                                alt={feature.name}
                              />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                            {feature.name}
                          </h3>
                          <p className="mt-5 text-base text-gray-500">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* <div id="api2" className="mt-12 bg-gray-50">
            <div className="px-4 py-40 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div>
                  <h2 className="text-3xl text-center font-extrabold text-gray-900">
                    ¡ByllerGames API!
                  </h2>
                  <br></br>
                  <dt className="text-lg font-medium text-justify text-gray-900 leading-6">
                    Con nuestra plataforma de fácil integración, puedes acceder a una amplia gama de servicios en línea desde una sola API. Olvídate de tener que lidiar con múltiples proveedores y plataformas complicadas.
                  </dt>
                </div>
                <div className="mt-12 lg:mt-0 lg:col-span-2">
                  <dl className="space-y-12">
                    <div>
                      <dt className="text-lg font-medium text-center font-extrabold leading-6">
                        Nuestra API ofrece
                      </dt>
                      <dd className="mt-2 text-base text-justify text-gray">
                        <ul>
                          <li><b>Variedad de servicios:</b> Desde recargas de juegos hasta pagos de servicios básicos, tenemos todo lo que necesitas en un solo lugar.</li>
                          <li><b>Seguridad:</b> Garantizamos la seguridad de todas tus transacciones para que puedas realizar tus pagos con total confianza.</li>
                          <li><b>Rapidez y facilidad de integración:</b> Con nuestra API de fácil integración, puedes empezar a ofrecer nuestros servicios en cuestión de minutos.</li>
                          <li><b>Soporte técnico:</b> Nuestro equipo de soporte técnico está siempre disponible para ayudarte en caso de cualquier duda o inconveniente.
                            Ya sea que seas un desarrollador que busca ofrecer una experiencia completa a tus usuarios o una empresa que quiere simplificar sus procesos de pago, ByllerGames API es la solución perfecta para ti.</li>
                          <br></br>
                          <li><b>¡Únete a nuestra plataforma hoy mismo y lleva tus servicios de recargas de juegos, pines, gifts cards, cuentas streamings, recargas celulares y servicios básicos en línea al siguiente nivel con ByllerGames API!</b></li>
                        </ul>

                        <div className="mt-8">
                          <div className="inline-flex shadow rounded-md">
                            <a
                              href="https://wa.link/sscili"
                              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-50"
                              target="_blank"
                            >
                              0961735894
                              <img src={ws} alt="ws" className="w-6 h-6 ml-2" />
                            </a>
                          </div>
                        </div>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div> */}

          <div className="relative bg-gray-900" id="api">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex flex-col-reverse md:flex-row">
                <div className="md:w-1/2 flex">
                  <div className="mr-10 ml-10 py-12 my-auto sm:py-10 md:py-10 lg:py-10">
                    <h2 className="text-3xl font-extrabold text-center text-gray-200 mb-3">
                      ¡ByllerGames API!
                    </h2>
                    <p className="text-lg text-justify text-gray-300 mb-10">
                      Con nuestra plataforma de fácil integración, puedes acceder a una amplia gama de servicios en línea desde una sola API. Olvídate de tener que lidiar con múltiples proveedores y plataformas complicadas. ByllerGames API te ofrece una solución completa y conveniente para todas tus necesidades de recargas de juegos, pines, gifts cards, cuentas streamings, recargas celulares y servicios básicos.
                    </p>
                    <dt className="text-lg text-center text-gray-300 font-extrabold leading-6">
                      Nuestra API ofrece
                    </dt>
                    <ul className='text-lg text-justify text-gray-300 mb-10'>
                      <li><b>Variedad de servicios:</b> Desde recargas de juegos hasta pagos de servicios básicos, tenemos todo lo que necesitas en un solo lugar.</li>
                      <li><b>Seguridad:</b> Garantizamos la seguridad de todas tus transacciones para que puedas realizar tus pagos con total confianza.</li>
                      <li><b>Rapidez y facilidad de integración:</b> Con nuestra API de fácil integración, puedes empezar a ofrecer nuestros servicios en cuestión de minutos.</li>
                      <li><b>Soporte técnico:</b> Nuestro equipo de soporte técnico está siempre disponible para ayudarte en caso de cualquier duda o inconveniente. Ya sea que seas un desarrollador que busca ofrecer una experiencia completa a tus usuarios o una empresa que quiere simplificar sus procesos de pago, ByllerGames API es la solución perfecta para ti.</li>
                    </ul>
                    <p className="text-lg text-gray-300 mb-8">
                      <b>¡Únete a nuestra plataforma hoy mismo y lleva tus servicios de recargas de juegos, pines, gifts cards, cuentas streamings, recargas celulares y servicios básicos en línea al siguiente nivel con ByllerGames API!</b>
                    </p>
                    <div className="flex justify-center">
                      <div className="shadow rounded-md">
                        <a
                          href="https://wa.me/+593961735894"
                          className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-50"
                          target="_blank"
                        >
                          0961735894
                          <img src={ws} alt="ws" className="w-6 h-6 ml-2" />
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="md:w-1/2 flex justify-end">
                  <img className="max-w-full h-auto" src={apiRest} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div id="faq" className="relative bg-gray-900">
            <div className="relative h-56 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
              <img className="w-full h-full" src={contact} alt="" />
              <div
                aria-hidden="true"
                className="absolute"
                style={{ mixBlendMode: 'multiply' }}
              />
            </div>
            <div className="relative px-4 py-12 mx-auto sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-48">
              <div className="md:ml-auto md:w-1/2 md:pl-10">
                <h2 className="text-3xl text-center font-extrabold text-gray-200">
                  Contacto
                </h2>
                <p className="mt-3 text-lg text-justify text-gray-300">
                  Se parte de nuestros clientes te esperamos para atenderte, si tienes algún requerimiento o duda con respecto a nuestros servicios digitales, escribanos.
                </p>
                <div className="mt-8 flex justify-center" >
                  <div className="inline-flex shadow rounded-md">
                    <a
                      href="https://wa.me/+593961735894"
                      className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-50"
                      target="_blank"
                    >
                      0961735894
                      <img src={ws} alt="ws" className="w-6 h-6 ml-2" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <FooterLogout navigation={navigation} />
      </div>
    </div>
  );
};
