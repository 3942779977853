import React, { useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { gql } from '@apollo/client/core';
import { getFormattedValue } from '../../utils/string-utils';
import { DatePicker, Table, Tag, Tooltip } from 'antd';
import { DevolutionStatus } from '../../enums/spanish.enum';
import { useMutation, useQuery } from '@apollo/client';
import { useMe } from '../../hooks/use-me';
import { DevType, Role } from '../../__api__/globalTypes';
import { Helmet } from 'react-helmet-async';
import { Titles } from '../../enums/titles.enum';
import { Labels } from '../../enums/labels.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Modal } from '../../components/modal';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';
import { JSONDetail } from '../../components/jsonDetails';
import { reviewDevoluciones, reviewDevolucionesVariables, reviewDevoluciones_devoluciones_nodes_Devolucion } from '../../__api__/reviewDevoluciones';
import { updateDevolucionMutation, updateDevolucionMutationVariables } from '../../__api__/updateDevolucionMutation';
import { AnswerDevolution } from './devolucion-update';
import { UPDATE_DEVOLUTION_MUTATION } from '../../common-mutations/common-mutations';

const { RangePicker } = DatePicker;

const DEVOLUTION_QUERY = gql`
  query reviewDevoluciones($input: DevolucionesSearcherInput!) {
    devoluciones(input: $input) {
      meta {
        nodeCount
        nodesPerPage
        pageCount
        pageCurrent
      }
      nodes {
        __typename
        ... on Devolucion {
            id
            amount
            request
            answer
            status
            createdAt
            updatedAt
            reviewerUserId
            reviewerEmail
            purchaseId
            purchase {
                id
                product {
                  name
                  saleCost
                  description
                }
                customer {
                  name
                  lastName
                  identificationNumber
                  cellPhone
                  distributorEmail
                }
                adminDetail
                comment
                createdAt
                updatedAt
                status
                total
                precioVenta
                productionCost
                balanceNuevo
                reviewerUserId
                reviewerEmail
                result
                commission
                alternateName
                alternateDesc
                factNames
                facLastnames
                facAddress
                facID
                facIDType
            }
        }
      }
    }
  }
`;

const DEFAULT_PAGE_SIZE = 10;

export const ReviewDevoluciones = () => {
  const { data: user } = useMe();

  const [pageCurrent, setPageCurrent] = useState(1);
  const [nodesPerPage, setNodesPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [isModalReviewVisible, setIsModalReviewVisible] = useState(false);
  const [isModalDetailVisible, setIsModalDetailVisible] = useState(false);
  const [displayItem, setDisplayItem] = useState<any>(null);
  const [status, setStatus] = useState<DevType[] | null>(null);
  const [
    devolution,
    setDevolution,
  ] = useState<reviewDevoluciones_devoluciones_nodes_Devolucion | null>(null);

  const { data, loading, refetch } = useQuery<
    reviewDevoluciones,
    reviewDevolucionesVariables
  >(DEVOLUTION_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        pageCurrent,
        nodesPerPage,
        where: {
          dateFrom,
          dateTo,
          status,
        },
      },
    },
  });

  const getLabelsForRole = () => {
    if (user?.me.role === Role.ADMIN) {
      return {
        id:"ID",
        product:"Producto",
        customer:"Cliente",
        comment:"Comentario",
        createdAt:"Fecha del Pedido",
        updatedAt:"Fecha de Revision",
        status:"Estado",
        adminDetail:"Detalles",
        result:"Resultado",
        total:"Total",
        comission:"Comisión",
        productionCost:"Costo de Producción",
        precioVenta: "PVP",
        alternateName:"Nombre Real del Producto",
        alternateDesc:"Descripcion Real del Producto",
        factNames:"Nombre Del Cliente",
        facLastnames:"Apellido del Cliente",
        facAddress:"Dirección del Cliente",
        facID:"Numero de Identificación del Cliente",
        facIDType:"Typo de Identificación del Cliente",
        reviewerUserId:"ID de Usuario de Revision",
        reviewerEmail:"Mail de Usuario de Revision",
      };
    }
    else if (user?.me.role === Role.DISTRIBUTOR) {
      return {
        id:"ID",
        product:"Producto",
        customer:"Cliente",
        comment:"Comentario",
        createdAt:"Fecha del Pedido",
        status:"Estado",
        adminDetail:"Detalles",
        result:"Resultado",
        total:"Total",
        comission:"Comisión",
        productionCost:"Costo de Producción",
        alternateName:"Nombre Real del Producto",
        alternateDesc:"Descripcion Real del Producto",
        factNames:"Nombre Del Cliente",
        facLastnames:"Apellido del Cliente",
        facAddress:"Dirección del Cliente",
        facID:"Numero de Identificación del Cliente",
        facIDType:"Typo de Identificación del Cliente",
      };
    }
    else if (user?.me.role === Role.OPERATOR) {
      return {
        id:"ID",
        product:"Producto",
        customer:"Cliente",
        comment:"Comentario",
        createdAt:"Fecha del Pedido",
        status:"Estado",
        adminDetail:"Detalles",
        result:"Resultado",
        total:"Total",
        productionCost:"Costo de Producción",
        precioVenta: "PVP",
        alternateName:"Nombre Real del Producto",
        alternateDesc:"Descripcion Real del Producto",
        factNames:"Nombre Del Cliente",
        facLastnames:"Apellido del Cliente",
        facAddress:"Dirección del Cliente",
        facID:"Numero de Identificación del Cliente",
        facIDType:"Typo de Identificación del Cliente",
      };
    }
  }

  const purchaseDEVOLUTIONS = data?.devoluciones.nodes as
    | reviewDevoluciones_devoluciones_nodes_Devolucion[]
    | null;

  const columns = [
    {
      title: 'Fecha de creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'ID Cliente',
      dataIndex: 'identificationNumber',
      key: 'identificationNumber',
    },
    {
      title: 'Nombre Cliente',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: 'Producto',
      dataIndex: 'product',
      key: 'product',
    },
    {
        title: 'Valor de compra',
        dataIndex: 'total',
        key: 'total',
    },
    {
        title: 'Costo de producción',
        dataIndex: 'productionCost',
        key: 'productionCost',
    },
    {
        title: 'Valor Devuelto',
        dataIndex: 'devolution',
        key: 'devolution',
    },
    {
      title: 'Request',
      dataIndex: 'request',
      key: 'request',
    },
    {
      title: 'Respuesta',
      dataIndex: 'answer',
      key: 'answer',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const datasource = purchaseDEVOLUTIONS?.map((item) => ({
    key: item.id,
    request: item.request,
    answer: item.answer,
    devolution: item.amount ? `$${getFormattedValue(item.amount)}` : "$0.00",
    identificationNumber: item.purchase?.customer.identificationNumber,
    customerName: `${item.purchase?.customer.name} ${item.purchase?.customer.lastName}`,
    product: item.purchase?.alternateName?item.purchase?.alternateName: item.purchase?.product.name,
    productionCost: `$ ${getFormattedValue(item.purchase?.productionCost || 0)}`,
    total: `$ ${getFormattedValue(item.purchase?.total || 0)}`,
    createdAt: item.createdAt,
    status: (
      <Tag
        color={
          item.status === 'ACCEPTED'
            ? 'geekblue'
            : item.status === 'PENDING' 
            ? 'orange'
            : item.status === 'REJECTED'
            ? 'error'
            : 'cyan'
        }
      >
        {DevolutionStatus[item.status]}
      </Tag>
    ),
    action: (<div className="flex items-center justify-start">
      <div
        onClick={() => {
          setDisplayItem(item);
          setIsModalDetailVisible(true);
        }}
        className="flex-shrink-0 mr-2"
      >
        <Tooltip title={'ver detalle'}>
          <InformationCircleIcon
            className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
            aria-hidden="true"
          />
        </Tooltip>
      </div>
      {[DevType.PENDING,DevType.IN_PROGRESS].includes(item.status) && (
      <button
        onClick={async () => {
          try {
          await updateDevolucion(item.id, DevType.IN_PROGRESS);
          setDevolution(item);
          setIsModalReviewVisible(true);
          }
          catch (error) {
            alert("Error al actualizar el estado de la devolución");
            console.log(error);
          }
        }}
        type="button"
        className={`flex justify-center w-full py-1 text-sm font-medium text-white border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${
          (!item.reviewerUserId || item.reviewerUserId === user?.me.id || DevType.PENDING === item.status) 
            ? 'bg-green-600 hover:opacity-90'
            : 'bg-green-300 pointer-events-none'
        }`}
      >
        <span>Revisar</span>
      </button>
    )}
    <div className="divider w-2" />
    {(DevType.IN_PROGRESS === item.status && user?.me.role === Role.ADMIN) && (
      <button
      onClick={async () => {
        await updateDevolucion(item.id, DevType.PENDING);
        refetch();
      }}
      type="button"
      className={`flex justify-center w-full py-1 text-sm font-medium text-white border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-indigo-600 hover:opacity-90'
      }`}
    >
      <span>Liberar</span>
    </button>
    )}
    </div>),
  }));

  const onChange = (values: any) => {
    if (!values) {
      setDateFrom(null);
      setDateTo(null);
      return;
    }
    const dateFrom = values[0].format();
    const dateTo = values[1].format();

    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };

  const onCompleted = async (data: updateDevolucionMutation) => {
    const { updateDevolucion: id } = data;
    if (id) {
      await refetch();
      if (isModalReviewVisible) {
        setIsModalReviewVisible(false);
      }
    }
  };

  const [
    updateDevolucionMutation,
    { loading: loadingMutation },
  ] = useMutation<updateDevolucionMutation, updateDevolucionMutationVariables>(
    UPDATE_DEVOLUTION_MUTATION,
    { onCompleted },
  );

  const updateDevolucion = async (
    id: number,
    status: DevType,) => {
    if (!loadingMutation) {
      try {
        await updateDevolucionMutation({
          variables: {
            input: {
                id: id,
                status: status
            },
          },
        });
      } catch (error) {throw error;}
    }
  };

  return (
    <div>
      <Modal
        title="Detalles del pedido"
        visible={isModalDetailVisible}
        onOk={async () => {
          setIsModalDetailVisible(false);
        }}
        onCancel={async () => {
          setIsModalDetailVisible(false);
        }}
        child={<JSONDetail json={displayItem?.purchase} 
        labels={getLabelsForRole()}/>}
      />
      <Modal
        title="Revisión del pedido"
        visible={isModalReviewVisible}
        onOk={async () => {
          setIsModalReviewVisible(false);
          await refetch();
        }}
        onCancel={async () => {
          setIsModalReviewVisible(false);
          await refetch();
        }}
        child={<AnswerDevolution devolution={devolution} onOk={async () => {
          setIsModalReviewVisible(false);
          refetch();}}></AnswerDevolution>}
      />
      <Helmet>
        <title>{Titles.DEVOLUTIONS}</title>
      </Helmet>
      <div className="px-4 py-6">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900 leading-6">
              {Labels.MENU_DEVOLUCIONES}
            </h3>
          </div>
          <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
            <form className="flex flex-col items-center w-full py-8 bg-white shadow sm:rounded-3xl sm:px-4">
              <div className="mb-4 mr-auto">
                {Labels.FILTERS}
                <FontAwesomeIcon icon={faFilter} className="ml-3 text-xl" />
              </div>
              <div className="max-w-xl grid grid-cols-1 gap-5">
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_DATES}</span>
                  </label>
                  <RangePicker
                    showTime={true}
                    placeholder={['Fecha desde', 'Fecha hasta']}
                    className="w-full rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={onChange}
                    disabledDate={(current) => moment().isBefore(current)}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_STATUS}</span>
                  </label>
                  <select
                    name="status"
                    onChange={(event) => {
                      const value = event.target.value;
                      setStatus(value ? ([value] as DevType[]) : null);
                    }}
                    className="w-full select"
                  >
                    <option value="">Todos</option>
                    {Object.keys(DevType).map((state) => (
                      <option key={state} value={state}>
                        {DevolutionStatus[state as any]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </form>
          </div>
          <SkeletonTable
            active={true}
            loading={loading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              dataSource={datasource}
              columns={columns}
              pagination={{
                defaultPageSize: DEFAULT_PAGE_SIZE,
                pageSize: nodesPerPage,
                current: pageCurrent,
                total: data?.devoluciones.meta.nodeCount,
                responsive: true,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '100'],
                onShowSizeChange: (current, size) => setNodesPerPage(size),
                onChange: (page) => setPageCurrent(page),
              }}
            />
          </SkeletonTable>
        </div>
      </div>
    </div>
  );
};
