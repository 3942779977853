import React, { useState } from 'react';
import { FormErrorMessages } from '../../../../enums/form-error-messages.enum';
import { FormError } from '../../../../components/form-error';
import { useForm } from 'react-hook-form';
import { Labels } from '../../../../enums/labels.enum';
import { Button } from '../../../../components/button';
import { useMutation } from '@apollo/client';
import { useMe } from '../../../../hooks/use-me';
import { Exceptions } from '../../../../enums/exceptions.enum';
import { checkLength,removeSpecialCharacters } from '../../../../utils/validate-utils';
import { FacType } from '../../../../__api__/globalTypes';
import { EMAIL_REGEX, VALID_NUMBER_REGEX, VALID_TEXT_NUMBER_REGEX, INPUT_TEXT_REGEX } from '../../../../constants';
import { pagarMTTRMutation, pagarMTTRMutationVariables } from '../../../../__api__/pagarMTTRMutation';
import { MRExceptions } from '../../../../enums/mr-exceptions.enum';
import { IBuyProductMTTR2Props } from '../buy-product-mttr-props.interface';
import { CREATE_MTTR_PURCHASE_ORDER } from '../../../../common-mutations/common-mutations';

interface IField {
  label: string;
  name: string;
  dataType: string;
}

export const BuyProductMTTRDefault2: React.FC<IBuyProductMTTR2Props> = ({ onOk, details, product }) => {
  const { data, refetch } = useMe();
  let primalLoading = false;

  const [errorCON,seterrorCON] = useState<string | null>(null);
  const [showConsumidor,setshowConsumidor] = useState<boolean>(false);

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState: {  },
  } = useForm({
    mode: 'onChange',
  });

  const consumidorChange = e => {
    if(!e)
    {
      return;
    }
    const val =Number(getValues().tipocliente);
    if(details==null)
    {
      setshowConsumidor(false);

    }
    else
    {
      setshowConsumidor(val!=1);
    }
  }

  const onCompleted = async (data: pagarMTTRMutation) => {
    const { pagarMTTR: purchaseOrderId } = data;
    if (purchaseOrderId) {
      primalLoading = true;
      await refetch();
      onOk();
    }
  };
  const [pagarMTTRMutation, { loading, error }] = useMutation<
    pagarMTTRMutation,
    pagarMTTRMutationVariables
  >(CREATE_MTTR_PURCHASE_ORDER, {
    onCompleted,
  });

  const onSubmit = async () => {
    if(primalLoading)
    {
      return;
    }
    primalLoading = true;
    let facIdType: FacType = FacType.CONSUMIDORFINAL;
    switch (Number(getValues().tipocliente)) {
      case 1:
        facIdType = FacType.CONSUMIDORFINAL;
        break;
      case 2:
        facIdType = FacType.CEDULA;
        break;
      case 3:
        facIdType = FacType.RUC;
        break;
      case 4:
        facIdType = FacType.PASAPORTE;
        break;
      default:
        alert(Number(getValues().tipocliente));
        seterrorCON("Tipo de Consumidor no reconocido");
        return;
    }
    if(details==null || details==undefined)
    {
      seterrorCON("Error de Consulta");
      return;
    }
    if(product==null || product==undefined)
    {
      seterrorCON("Error de Producto");
      return;
    }
    if (!loading) {
      seterrorCON(null);
      try {
        const { name, lastName, direccion, identificationNumber,...input } = getValues();
        if(facIdType!=FacType.CONSUMIDORFINAL)
        {
          if(name==="" || name==undefined)
          {
            seterrorCON(Labels.NAME_FAC_SERVICIO+": "+FormErrorMessages.REQUIRED_FIELD);
            return;
          }
          if(name.length>30)
          {
            seterrorCON(Labels.NAME_FAC_SERVICIO+": "+FormErrorMessages.LENGTH);
            return;
          }
          if(!/^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(name))
          {
            seterrorCON(Labels.NAME_FAC_SERVICIO+": "+FormErrorMessages.CHARACTERS);
            return;

          }
          if(lastName==="" || lastName==undefined)
          {
            seterrorCON(Labels.APELLIDO_FAC_SERVICIO+": "+FormErrorMessages.REQUIRED_FIELD);
            return;
          }
          if(lastName.length>30)
          {
            seterrorCON(Labels.APELLIDO_FAC_SERVICIO+": "+FormErrorMessages.LENGTH);
            return;
          }
          if(!/^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(lastName))
          {
            seterrorCON(Labels.APELLIDO_FAC_SERVICIO+": "+FormErrorMessages.CHARACTERS);
            return;

          }
          if(direccion==="" || direccion==undefined)
          {
            seterrorCON(Labels.DIR_FAC_SERVICIO+": "+FormErrorMessages.REQUIRED_FIELD);
            return;
          }
          if(direccion.length>150)
          {
            seterrorCON(Labels.DIR_FAC_SERVICIO+": "+FormErrorMessages.LENGTH);
            return;
          }
          if(!/^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(direccion))
          {
            seterrorCON(Labels.DIR_FAC_SERVICIO+": "+FormErrorMessages.CHARACTERS);
            return;

          }
          if(identificationNumber==="" || identificationNumber==undefined)
          {
            seterrorCON(Labels.CI_FAC_SERVICIO+": "+FormErrorMessages.REQUIRED_FIELD);
            return;
          }
          if(identificationNumber.length<10 || identificationNumber.length>30 )
          {
            seterrorCON(Labels.CI_FAC_SERVICIO+": "+FormErrorMessages.ID_LENGTH_SERVICE);
            return;
          }
          if(!/^[0-9]*$/.test(identificationNumber))
          {
            seterrorCON(Labels.CI_FAC_SERVICIO+": "+FormErrorMessages.CHARACTERS);
            return;
          }
        }
        await pagarMTTRMutation({
          variables: {
            input: {
              userId: data?.me.id || '',
              productId: product?.id || '',
              amount: product?.priceWithDiscount || 0,
              mttr_id: product?.mttrID ? product?.mttrID : "",
              values: generateValuesFromInput(input),
              order_id: details.order_id || '',
              facData: {
                factNames: facIdType==FacType.CONSUMIDORFINAL ? "" : name,
                facLastnames: facIdType==FacType.CONSUMIDORFINAL ? "" : lastName,
                facAddress: facIdType==FacType.CONSUMIDORFINAL ? "" : direccion,
                facID: facIdType==FacType.CONSUMIDORFINAL ? "" : identificationNumber,
                facIDType: facIdType
              }
            },
          },
        });
      } catch (error : any) {
        const errorMR = "MR"+(error.toString()?.split(":"))[2];
        seterrorCON(MRExceptions[errorMR]? MRExceptions[errorMR] : MRExceptions.Default);
      }
      finally
      {
          primalLoading = false;
      }
    }
  };

  function generateValuesFromInput(input : any)
  {
    const values = {};
    Object.keys(input).forEach(key => {
      if(key!="tipocliente" && key!="name" && key!="lastName" && key!="direccion" && key!="identificationNumber")
      {
        //check in product?.mttrReqInfo.fields, if it is a number parse it to int
        for (const field of product?.mttrReqInfo.fields) {
          if(field.name==key)
          {
            if(field.dataType=="number")
            {
              values[key] = parseInt(input[key]);
            }
            else
            {
              values[key] = input[key];
            }
          }
        }
      }
    });
    return values;
  }

  const getDefaultValue = (key: string) => {
    return details[key] ? details[key] : '';
  };

  function renderDetails(detail) {
    if (detail == null) {
      return <FormError errorMessage="El valor consultado es NULL, por favor reintentar mas tarde" />;
    }
  
    return (
      <div className="px-4 py-4 bg-white shadow sm:rounded-3xl sm:px-10">
        {Object.entries(detail).map(([key, value]) => {
          if (Array.isArray(value)) {
            return value.map((item) => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p>{key}:</p>
                {renderDetails(item)}
              </div>
              
            ));
          } else {
            return (
              <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', borderBottom: '1px solid darkgray' }}>
                <span>{key}:</span>
                <span>{value as string}</span>
              </div>
            );
          }
        })}
      </div>
    );
  }

  const patternByDataType = (dataType: string) =>
  dataType === 'email'
    ? {
        value: EMAIL_REGEX,
        message: FormErrorMessages.VALID_EMAIL,
      }
    : dataType === 'number'
    ? {
        value: VALID_NUMBER_REGEX,
        message: FormErrorMessages.INVALID_NUMBER,
      }
    : dataType === 'text_number'
    ? {
        value: VALID_TEXT_NUMBER_REGEX,
        message: FormErrorMessages.INVALID_DATA,
      }
    : {
        value: INPUT_TEXT_REGEX,
        message: FormErrorMessages.INVALID_DATA,
      };

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="flex-1 min-w-0">
          <div className="flex items-center mb-4 space-x-3">
            <h2 className="text-lg font-bold text-gray-900 leading-7">
              {product?.name}
            </h2>
          </div>
          <h3 className="mb-6 text-base font-semibold text-gray-900 leading-7">
            {product?.description}
          </h3>
          {renderDetails(details)}
          <h4 className="mb-2 text-sm font-normal text-gray-900 leading-5">
            Requerimos de la siguiente información para la compra de tu producto
          </h4>
        </div>
        { (details==null || details==undefined) ? (<FormError errorMessage="El valor consultado es NULL, por favor reintentar mas tarde" />) : (
          <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            {product?.mttrReqInfo.fields.map((field: IField) => {
              return field.dataType === 'text_number' ? (
                <div key={field.name}>
                  <label htmlFor={field.name} className="label">
                    {field.label}
                  </label>
                  <div className="mt-1">
                    <input
                      defaultValue={getDefaultValue(field.name)}
                      ref={register({
                        required: FormErrorMessages.REQUIRED_FIELD,
                        pattern: patternByDataType(field.dataType),
                      })}
                      name={field.name}
                      onKeyDown={(e) => checkLength(e)}
                      className="input"
                      step="1"
                      disabled={getDefaultValue(field.name)!=""}
                    />
                    {errors[field.name]?.message && (
                      <FormError errorMessage={errors[field.name]?.message} />
                    )}
                  </div>
                </div>
              ) : (
                <div key={field.name}>
                  <label htmlFor={field.name} className="label">
                    {field.label}
                  </label>
                  <div className="mt-1">
                    <input
                      defaultValue={getDefaultValue(field.name)}
                      ref={register({
                        required: FormErrorMessages.REQUIRED_FIELD,
                        pattern: patternByDataType(field.dataType),
                      })}
                      name={field.name}
                      type={field.dataType}
                      className="input"
                      step="1"
                      disabled={getDefaultValue(field.name)!=""}
                    />
                    {errors[field.name]?.message && (
                      <FormError errorMessage={errors[field.name]?.message} />
                    )}
                  </div>
                </div>
              );
            })}
            <div>
              <label htmlFor="tipocliente" className="label">
                {Labels.CLI_SELECT_SERVICIO}
              </label>
              <div className="mt-1">
                <select
                  name="tipocliente"
                  ref={register({ required: true })}
                  className="input"
                  onChange={consumidorChange}
                  defaultValue={1}
                >
                  <option value="1">Consumidor Final</option>
                  <option value="2">Cedula</option>
                  <option value="3">RUC</option>
                  <option value="4">Pasaporte</option>
                </select>
              </div>
            </div>
            {  showConsumidor &&(
            <div>
              <label htmlFor="name" className="label">
                {Labels.NAME_FAC_SERVICIO}
              </label>
              <div className="mt-1">
                <input
                  ref={register({ maxLength: 100 })}
                  name="name"
                  maxLength={100}
                  type="text"
                  className="input"
                />
              </div>
            </div>)}
            {  showConsumidor &&(
            <div>
              <label htmlFor="lastName" className="label">
                {Labels.APELLIDO_FAC_SERVICIO}
              </label>
              <div className="mt-1">
                <input
                  ref={register({ maxLength: 100})}
                  name="lastName"
                  maxLength={100}
                  type="text"
                  className="input"
                />
              </div>
            </div>)}
            {  showConsumidor &&(
            <div>
              <label htmlFor="direccion" className="label">
                {Labels.DIR_FAC_SERVICIO}
              </label>
              <div className="mt-1">
                <input
                  ref={register({ maxLength: 150 })}
                  name="direccion"
                  maxLength={150}
                  type="text"
                  className="input"
                />
              </div>
            </div>)}
            {  showConsumidor &&(
            <div>
              <label htmlFor="identificationNumber" className="label">
                {Labels.CI_FAC_SERVICIO}
              </label>
              <div className="mt-1">
                <input
                  ref={register({ maxLength: 20, minLength: 10, pattern: /^[0-9]*$/})}
                  name="identificationNumber"
                  type="tel"
                  pattern="[0-9]*"
                  minLength={10}
                  maxLength={20}
                  className="input"
                  onKeyDown={(e) => checkLength(e)}
                  onKeyUp={removeSpecialCharacters}
                />
              </div>
            </div>)}
              <Button
                canClick={true}
                loading={loading  || primalLoading}
                actionText={Labels.BUY}
              />
              {error && (
                <FormError errorMessage={Exceptions[error.message as any]} />
              )}
            </form>
            {errorCON && (
              <FormError errorMessage={errorCON} />
            )}
          </div>
        )}

      </div>
    </div>
  );
};
