export enum IdentificationType {
  CITIZENSHIP_ID = <any>'Cédula',
  PASSPORT = <any>'Pasaporte',
  RUC = <any>'RUC',
}

export enum ConsignmentStatus {
  PENDING = <any>'PENDIENTE',
  REVISION = <any>'EN REVISIÓN',
  ACCEPTED = <any>'ACEPTADO',
  REJECTED = <any>'RECHAZADO',
  REVERSED = <any>'REVERTIDO',
  DEVOLUTION = <any>'POR DEVOLUCION',
}

export enum DevolutionStatus {
  PENDING = <any>'PENDIENTE',
  IN_PROGRESS = <any>'EN REVISIÓN',
  ACCEPTED = <any>'ACEPTADO',
  REJECTED = <any>'RECHAZADO',
}

export enum PurchaseOrderStatus {
  PENDING = <any>'PENDIENTE',
  REVISION = <any>'EN REVISIÓN',
  ACCEPTED = <any>'ACEPTADO',
  REJECTED = <any>'RECHAZADO',
  DEVOLUTION_REQUESTED = <any>'REPORTADO',
  DEVOLUTION_ACCEPTED = <any>'SOLUCIONADO',
  DEVOLUTION_REJECTED = <any>'RECLAMO NEGADO',
}

export enum ProductStatusSpanish {
  ACTIVE = <any>'ACTIVO',
  INACTIVE = <any>'INACTIVO',
}

export enum DataTypeEnum {
  EMAIL = <any>'Correo',
  PASSWORD = <any>'Contraseña',
  NUMBER = <any>'Número',
  TEXT = <any>'Texto',
  TEXT_NUMBER = <any>'Número en texto',
}

export enum ProductAccountStatusSpa {
  AVAILABLE = <any>'DISPONIBLE',
  USED = <any>'USADA',
}

export enum RoleSpanish {
  ADMIN = <any>'ADMINISTRADOR',
  DISTRIBUTOR = <any>'DISTRIBUIDOR',
  OPERATOR = <any>'OPERADOR',
}

export enum AccountTypeSpanish {
  CHECKING = <any>'Corriente',
  SAVINGS = <any>'Ahorros',
}

export enum BankAccountStatusSpanish {
  ACTIVE = <any>'ACTIVO',
  INACTIVE = <any>'INACTIVO',
}

export enum UserStatusSpanish {
  ACTIVE = <any>'ACTIVO',
  INACTIVE = <any>'INACTIVO',
  PENDING = <any>'PENDIENTE',
}

export enum CategoryStatusSpanish {
  ACTIVE = <any>'ACTIVO',
  INACTIVE = <any>'INACTIVO',
}

export enum DiscountStatusSpanish {
  ACTIVE = <any>'ACTIVO',
  INACTIVE = <any>'INACTIVO',
}

export enum CommissionStatusSpanish {
  ACTIVE = <any>'ACTIVO',
  INACTIVE = <any>'INACTIVO',
}

export enum APIStatusSpanish {
  ON = <any>'HABILITADO',
  OFF = <any>'DESHABILITADO',
}
