import { FooterLogout } from '../components/footer-logout';
import { Link } from 'react-router-dom';
import logo from '../images/byller-logo.png';
import { PointsDesign } from '../components/points-design';
import { Titles } from '../enums/titles.enum';
import { Helmet } from 'react-helmet-async';

const navigation = [{ name: 'Inicio', href: '/' }];

export const Privacity = () => {
  return (
    <div>
      <Helmet>
        <title>{Titles.PRIVACITY}</title>
      </Helmet>
      <div className="px-4 pt-6 mx-auto max-w-7xl sm:px-6">
        <Link to="/">
          <img className="w-auto h-32" src={logo} alt="Byllergames" />
        </Link>
      </div>
      <div className="relative py-8 overflow-hidden bg-white">
        <PointsDesign />
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto text-lg max-w-prose">
            <h1>
              <span className="block mt-8 text-2xl font-extrabold tracking-tight text-center text-gray-900 leading-8">
                AVISO DE PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES
              </span>
            </h1>
          </div>
          <div className="mx-auto mt-6 text-gray-500 prose prose-indigo prose-lg">
            <p className='text-justify'>
              En Byllergames consideramos muy importante que el usuario sea consciente de cómo recopilamos y qué hacemos con sus datos personales, razón por la cual hemos preparado la siguiente Política de Privacidad y Protección de Datos Personales (en adelante, “Política de Privacidad”) para informar sobre el tratamiento de datos personales recopilados a través de nuestra Página Web. La presente política busca dar cumplimiento a las disposiciones legales aplicables en materia de protección de datos personales y privacidad de la información personal, garantizando el tratamiento seguro de los datos personales e información privada de los clientes y los colaboradores de Byllergames.
              <br></br> Particularmente, esta Política de Privacidad busca dar cumplimiento a lo establecido en la Ley Orgánica de Protección de Datos Personales publicada en el Registro Oficial Suplemento 459 de 26 de mayo de 2021, a través de la cual se establecen las disposiciones generales que regulan la materia (en adelante, “LOPDP”).
            </p>
            <h4>Responsable del tratamiento:</h4>
            <p className='text-justify'>
              Byllergames. con domicilio ubicado en Avenida 6 de diciembre n92-65 y sabanilla, Quito, Distrito Metropolitano, de la ciudad de Quito, Ecuador (en adelante, simplemente “Byllergames”).
              <br></br>Byllergames en su calidad de responsable de tratamiento de datos personales está comprometido a respetar la intimidad, privacidad y el adecuado tratamiento de los datos personales de los usuarios, los cuales se nos ha provisto en condición de usuario de nuestra página web (en adelante, simplemente “la web”).
              <br></br>En caso de dudas relativas al tratamiento de los datos personales del usuario, se podrá contactar con Byllergames a través de la siguiente dirección de correo electrónico: byllergames@gmail.com
            </p>
            <h4>Información recopilada:</h4>
            <p className='text-justify'>
              El usuario no está obligado a entregar ninguna información personal al consultar nuestro sitio web.
              Sin embargo, el usuario puede optar por registrarse en nuestra plataforma para hacer uso de los servicios que brindamos por lo que los siguientes datos serán ingresados.
              <ul>
                <li>Nombres y Apellidos</li>
                <li>Cédula (número de identificación)</li>
                <li>Cuidad</li>
                <li>Dirección de correo electrónico</li>
                <li>Número de teléfono de contacto</li>
              </ul>
              Al hacer “clic” en el botón “Aceptar términos y condiciones” (o equivalente) incorporado en los citados formularios de receptación de datos personales, el usuario declara que la información y los datos que en ellos ha facilitado son exactos y veraces.
            </p>
            <h4>Base que legitima el tratamiento:</h4>
            <p className='text-justify'>
              Byllergames realizará el tratamiento de los datos personales proporcionados por el usuario, en base a su consentimiento expreso (Art. 7.1 LOPDP), solicitado para el caso concreto y en la aceptación expresa del contenido de esta Política de Privacidad.
              <br></br>El tratamiento de los datos personales provistos se basará en el consentimiento dado por el titular de los datos personales al momento de proporcionar sus datos, así como en el interés legítimo de Byllergames para llevar a cabo dicho tratamiento, amparado en la normativa vigente.
              <br></br>Byllergames se encuentra facultado para utilizar la información del usuario para dar cumplimiento a las distintas obligaciones legales o requerimientos de autoridad competente a los que esté obligado a cumplir, así como para el cumplimiento de sus intereses legítimos.
              <br></br>Adicionalmente, para llevar a cabo el tratamiento de datos personales de los usuarios en la gestión de la contratación de servicios, facturación y envíos correspondientes, la base legitimadora radicará en la ejecución del contrato de servicios suscrito con el titular de los datos (Art. 7.5 LOPDP).
              <br></br>El titular podrá revocar en cualquier momento el consentimiento conferido para el tratamiento de sus datos personales por Byllergames, revocatoria que tendrá efectos únicamente hacia el futuro. Esta revocatoria no afectará la legalidad del tratamiento realizado antes de la revocatoria del consentimiento, ratificando la licitud de los tratamientos efectuados con anterioridad.
              <br></br>El responsable del tratamiento de datos personales podrá retener los datos personales que sean necesarios para cumplir con las finalidades contractuales vigentes o con las obligaciones legales con las que Byllergames deba cumplir.
            </p>
            <h4>Finalidad para el tratamiento de datos personales y categorías de datos</h4>
            <p className='text-justify'>
              Sus datos personales serán tratados, de manera manual o parcialmente automatizada, para cumplir con el objeto social de Byllergames y proporcionarle nuestros bienes o servicios al usuario, incluyendo las siguientes finalidades específicas:
              <br />Atender y dar contestación a sus preguntas, consultas, requerimientos o solicitudes, contactándole para brindarle la información requerida, a través de los formularios de registro y cotización habilitados o a través de nuestro servicio al cliente. Gestionar la contratación de servicios que realice a través de la web, así como la facturación y entrega correspondiente.
              <br />Cumplir con las obligaciones jurídicas, como el proporcionar cotizaciones, llevar a cabo ventas, procesar pedidos e informar su estado.
              <br />Remitir periódicamente comunicaciones electrónicas sobre nuestros productos, servicios, eventos, descuentos, novedades, próximos lanzamientos y noticias de interés relacionadas con nuestra oferta comercial, salvo que se indique lo contrario o el usuario se oponga o revoque su consentimiento.
              <br />Remitir encuestas para conocer la opinión del usuario sobre los productos o servicios proporcionados por Byllergames, conociendo la experiencia y valoración de estos y la calidad del servicio.
            </p>
          </div>
        </div>
      </div>
      <FooterLogout navigation={navigation} />
    </div>
  );
};
