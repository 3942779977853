import React, { useEffect, useMemo, useState } from 'react';
import { ApolloError, gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@apollo/client';
import {
  customerDiscountsQuery,
  customerDiscountsQuery_customerDiscounts_nodes_CustomerDiscount,
  customerDiscountsQueryVariables,
} from '../../__api__/customerDiscountsQuery';
import { DiscountStatus } from '../../__api__/globalTypes';
import {
  DatePicker,
  Modal as AModal,
  Select,
  SelectProps,
  Spin,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import {
  DiscountStatusSpanish,
  IdentificationType,
} from '../../enums/spanish.enum';
import {
  EyeIcon,
  EyeOffIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { Labels } from '../../enums/labels.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';
import { useCategories } from '../../hooks/use-categories';
import { FormError } from '../../components/form-error';
import { Exceptions } from '../../enums/exceptions.enum';
import { Modal } from '../../components/modal';
import {
  updateCustomerDiscountMutation,
  updateCustomerDiscountMutationVariables,
} from '../../__api__/updateCustomerDiscountMutation';
import { useMe } from '../../hooks/use-me';
import {
  deleteCustomerDiscountMutation,
  deleteCustomerDiscountMutationVariables,
} from '../../__api__/deleteCustomerDiscountMutation';
import { useForm } from 'react-hook-form';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { Button } from '../../components/button';
import {
  customersQuery,
  customersQuery_customers_nodes_MainCustomer,
  customersQueryVariables,
} from '../../__api__/customersQuery';
import {
  createCustomerDiscountMutation,
  createCustomerDiscountMutationVariables,
} from '../../__api__/createCustomerDiscountMutation';
import debounce from 'lodash/debounce';

const { RangePicker } = DatePicker;

const CUSTOMER_DISCOUNTS = gql`
  query customerDiscountsQuery($input: DiscountSearcherInput!) {
    customerDiscounts(input: $input) {
      meta {
        nodeCount
        pageCount
        pageCurrent
        nodesPerPage
      }
      nodes {
        __typename
        ... on CustomerDiscount {
          category {
            id
            name
          }
          customer {
            id
            identificationType
            identificationNumber
            name
            lastName
          }
          id
          createdAt
          discountRate
          status
        }
      }
    }
  }
`;

const ALL_CUSTOMERS_QUERY = gql`
  query allCustomersQuery($input: CustomerSearcherInput!) {
    customers(input: $input) {
      meta {
        nodeCount
        nodesPerPage
        pageCount
        pageCurrent
      }
      nodes {
        __typename
        ... on MainCustomer {
          customer {
            id
            identificationType
            identificationNumber
            name
            lastName
          }
        }
      }
    }
  }
`;

const CREATE_CUSTOMER_DISCOUNT_MUTATION = gql`
  mutation createCustomerDiscountMutation(
    $input: CreateCustomerDiscountInput!
  ) {
    createCustomerDiscount(input: $input)
  }
`;

const UPDATE_CUSTOMER_DISCOUNT_MUTATION = gql`
  mutation updateCustomerDiscountMutation(
    $input: UpdateCustomerDiscountInput!
  ) {
    updateCustomerDiscount(input: $input)
  }
`;

const DELETE_CUSTOMER_DISCOUNT_MUTATION = gql`
  mutation deleteCustomerDiscountMutation(
    $input: DeleteCustomerDiscountInput!
  ) {
    deleteCustomerDiscount(input: $input)
  }
`;

const success = (content: string) => {
  AModal.success({
    content,
  });
};

interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any
>({ fetchOptions, debounceTimeout = 300, ...props }: DebounceSelectProps) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<ValueType[]>([]);
  const fetchRef = React.useRef(0);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select<ValueType>
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

interface CustomerValue {
  label: string;
  value: string;
}

interface CreateCustomerDiscountForm {
  categoryId: string;
  discountRate: number;
}

interface CreateCustomerDiscountProps {
  onOk: () => void;
}

const CreateCustomerDiscount: React.FC<CreateCustomerDiscountProps> = ({
  onOk,
}) => {
  const [fullTextSearch, setFullTextSearch] = useState<string | null>(null);
  const [value, setValue] = React.useState<CustomerValue[]>([]);
  const [customerId, setCustomerId] = useState<string | null>(null);

  const { data: user } = useMe();
  const { data: categories } = useCategories();
  const categoryOptions = useMemo(() => categories?.categories || [], [
    categories,
  ]);
  const { data: customers } = useQuery<customersQuery, customersQueryVariables>(
    ALL_CUSTOMERS_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          pageCurrent: 1,
          nodesPerPage: 100,
          where: {
            isAllCustomers: true,
            fullTextSearch,
          },
        },
      },
    },
  );

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState: { isValid },
  } = useForm<CreateCustomerDiscountForm>({
    mode: 'onChange',
  });

  const onCompleted = (data: createCustomerDiscountMutation) => {
    const { createCustomerDiscount: customerDiscountId } = data;
    if (customerDiscountId) {
      onOk();
    }
  };
  const [createCustomerDiscountMutation, { loading, error }] = useMutation<
    createCustomerDiscountMutation,
    createCustomerDiscountMutationVariables
  >(CREATE_CUSTOMER_DISCOUNT_MUTATION, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        const { ...input } = getValues();
        await createCustomerDiscountMutation({
          variables: {
            input: {
              categoryId: input.categoryId,
              customerId: customerId || '',
              discountRate: Number(input.discountRate),
              userId: user?.me.id || '',
            },
          },
        });
      } catch (error) {}
    }
  };

  async function fetchCustomers(value: string): Promise<CustomerValue[]> {
    setFullTextSearch(value);
    const customerArray = customers
      ? (customers.customers
          .nodes as customersQuery_customers_nodes_MainCustomer[])
      : [];
    return (
      customerArray.map((customer) => ({
        label: `${customer.customer.name} ${customer.customer.lastName}`,
        value: customer.customer.id,
      })) || []
    );
  }

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="categoryId" className="label">
                Categoría
              </label>
              <div className="mt-1">
                <select
                  name="categoryId"
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {categoryOptions.map((category: any) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="customerId" className="label">
                Cliente
              </label>
              <div className="mt-1">
                <DebounceSelect
                  mode="multiple"
                  value={value}
                  placeholder="Busca el cliente (nombres o cédula)"
                  fetchOptions={fetchCustomers}
                  onSelect={(value) => setCustomerId(value.key)}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  style={{
                    width: '100%',
                    border: 'none',
                  }}
                />
              </div>
            </div>
            <div>
              <label htmlFor="discountRate" className="label">
                Porcentaje de descuento
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0.1,
                  })}
                  name="discountRate"
                  min={0.1}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.discountRate?.message && (
                  <FormError errorMessage={errors.discountRate?.message} />
                )}
                {errors.discountRate?.type === 'min' && (
                  <FormError
                    errorMessage={FormErrorMessages.DISCOUNT_RATE_MIN_VALUE}
                  />
                )}
              </div>
            </div>
            <Button
              canClick={isValid && customerId !== null}
              loading={loading}
              actionText={Labels.CREATE}
            />
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

interface UpdateCustomerDiscountForm {
  categoryId?: string;
  discountRate?: number;
}

interface UpdateCustomerDiscountProps {
  customerDiscount: customerDiscountsQuery_customerDiscounts_nodes_CustomerDiscount | null;
  onOk: () => void;
}

const UpdateCustomerDiscount: React.FC<UpdateCustomerDiscountProps> = ({
  customerDiscount,
  onOk,
}) => {
  const { data: user } = useMe();
  const { data: categories } = useCategories();

  const [fullTextSearch, setFullTextSearch] = useState<string | null>(null);
  const [value, setValue] = React.useState<CustomerValue[]>([]);
  const [customerId, setCustomerId] = useState<string | null | undefined>(null);

  useEffect(() => {
    setCustomerId(customerDiscount?.customer.id);
    setValue([
      {
        label: `${customerDiscount?.customer.name} ${customerDiscount?.customer.lastName}`,
        value: customerDiscount?.customer.id || '',
      },
    ]);
  }, []);

  const categoryOptions = useMemo(() => categories?.categories || [], [
    categories,
  ]);

  const { data: customers } = useQuery<customersQuery, customersQueryVariables>(
    ALL_CUSTOMERS_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          pageCurrent: 1,
          nodesPerPage: 100,
          where: {
            isAllCustomers: true,
            fullTextSearch,
          },
        },
      },
    },
  );

  const {
    register,
    getValues,
    errors,
    handleSubmit,
  } = useForm<UpdateCustomerDiscountForm>({
    mode: 'onChange',
    defaultValues: {
      categoryId: customerDiscount?.category.id,
      discountRate: customerDiscount?.discountRate,
    },
  });

  async function fetchCustomers(value: string): Promise<CustomerValue[]> {
    setFullTextSearch(value);
    const customerArray = customers
      ? (customers.customers
          .nodes as customersQuery_customers_nodes_MainCustomer[])
      : [];
    return (
      customerArray.map((customer) => ({
        label: `${customer.customer.name} ${customer.customer.lastName}`,
        value: customer.customer.id,
      })) || []
    );
  }

  const onCompleted = (data: updateCustomerDiscountMutation) => {
    const { updateCustomerDiscount: customerDiscountId } = data;
    if (customerDiscountId) {
      onOk();
    }
  };
  const [updateCustomerDiscountMutation, { loading, error }] = useMutation<
    updateCustomerDiscountMutation,
    updateCustomerDiscountMutationVariables
  >(UPDATE_CUSTOMER_DISCOUNT_MUTATION, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        const { ...input } = getValues();
        await updateCustomerDiscountMutation({
          variables: {
            input: {
              categoryId: input.categoryId,
              customerId,
              discountRate: Number(input.discountRate),
              id: customerDiscount?.id || '',
              userId: user?.me.id || '',
            },
          },
        });
      } catch (error) {}
    }
  };

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="categoryId" className="label">
                Categoría
              </label>
              <div className="mt-1">
                <select
                  name="categoryId"
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {categoryOptions.map((category: any) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="customerId" className="label">
                Cliente
              </label>
              <div className="mt-1">
                <DebounceSelect
                  mode="multiple"
                  value={value}
                  placeholder="Busca el cliente (nombres o cédula)"
                  fetchOptions={fetchCustomers}
                  onSelect={(value) => setCustomerId(value.key)}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  style={{
                    width: '100%',
                    border: 'none',
                  }}
                />
              </div>
            </div>
            <div>
              <label htmlFor="discountRate" className="label">
                Porcentaje de descuento
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0.1,
                  })}
                  name="discountRate"
                  min={0.1}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.discountRate?.message && (
                  <FormError errorMessage={errors.discountRate?.message} />
                )}
                {errors.discountRate?.type === 'min' && (
                  <FormError
                    errorMessage={FormErrorMessages.DISCOUNT_RATE_MIN_VALUE}
                  />
                )}
              </div>
            </div>
            <Button
              canClick={true}
              loading={loading}
              actionText={Labels.UPDATE}
            />
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

const DEFAULT_PAGE_SIZE = 10;

export const CustomerDiscount = () => {
  const { data: categories } = useCategories();
  const { data: user } = useMe();

  const [pageCurrent, setPageCurrent] = useState(1);
  const [nodesPerPage, setNodesPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [status, setStatus] = useState<DiscountStatus | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEnableVisible, setIsModalEnableVisible] = useState(false);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [
    customerDiscount,
    setCustomerDiscount,
  ] = useState<customerDiscountsQuery_customerDiscounts_nodes_CustomerDiscount | null>(
    null,
  );

  const { data, loading, refetch } = useQuery<
    customerDiscountsQuery,
    customerDiscountsQueryVariables
  >(CUSTOMER_DISCOUNTS, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        pageCurrent,
        nodesPerPage,
        where: {
          categoryId,
          dateFrom,
          dateTo,
          status,
        },
      },
    },
  });

  const onChange = (values: any) => {
    if (!values) {
      setDateFrom(null);
      setDateTo(null);
      return;
    }
    const dateFrom = values[0].format();
    const dateTo = values[1].format();

    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };

  const columns = [
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Id. Cliente',
      dataIndex: 'idClient',
      key: 'idClient',
    },
    {
      title: 'Nombre cliente',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Porc. de descuento',
      dataIndex: 'discountRate',
      key: 'discountRate',
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const customerDiscounts = data?.customerDiscounts.nodes as
    | customerDiscountsQuery_customerDiscounts_nodes_CustomerDiscount[]
    | null;

  const datasource = customerDiscounts?.map(
    (
      item: customerDiscountsQuery_customerDiscounts_nodes_CustomerDiscount,
    ) => ({
      key: item.id,
      category: item.category.name,
      idClient: `${IdentificationType[item.customer.identificationType]} - ${
        item.customer.identificationNumber
      }`,
      name: `${item.customer.name} ${item.customer.lastName}`,
      discountRate: `${item.discountRate}%`,
      createdAt: item.createdAt,
      status: (
        <Tag color={item.status === 'ACTIVE' ? 'geekblue' : 'error'}>
          {DiscountStatusSpanish[item.status]}
        </Tag>
      ),
      action: (
        <div className="flex items-center justify-start">
          <div
            onClick={() => {
              setCustomerDiscount(item);
              setIsModalEnableVisible(true);
            }}
            className="flex-shrink-0 mr-3"
          >
            <Tooltip
              title={item.status === 'ACTIVE' ? 'desactivar' : 'activar'}
            >
              {item.status === 'ACTIVE' && (
                <EyeOffIcon
                  className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  aria-hidden="true"
                />
              )}
              {item.status === 'INACTIVE' && (
                <EyeIcon
                  className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  aria-hidden="true"
                />
              )}
            </Tooltip>
          </div>
          <div
            onClick={() => {
              setCustomerDiscount(item);
              setIsModalUpdateVisible(true);
            }}
            className="flex-shrink-0"
          >
            <Tooltip title="editar">
              <PencilIcon
                className="w-6 h-6 mr-3 text-gray-400 cursor-pointer hover:text-blue-400"
                aria-hidden="true"
              />
            </Tooltip>
          </div>
          <div
            onClick={() => {
              setCustomerDiscount(item);
              setIsModalDeleteVisible(true);
            }}
            className="flex-shrink-0"
          >
            <Tooltip title="eliminar">
              <TrashIcon
                className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                aria-hidden="true"
              />
            </Tooltip>
          </div>
        </div>
      ),
    }),
  );

  const onCompletedEnable = async (data: updateCustomerDiscountMutation) => {
    const { updateCustomerDiscount: customerDiscountId } = data;
    if (customerDiscountId) {
      setErrorMessage(null);
      setIsModalEnableVisible(false);
      success('Se actualizó el descuento por cliente satisfactoriamente');
      await refetch();
    }
  };
  const [
    updateCustomerDiscountMutation,
    { loading: loadingEnable },
  ] = useMutation<
    updateCustomerDiscountMutation,
    updateCustomerDiscountMutationVariables
  >(UPDATE_CUSTOMER_DISCOUNT_MUTATION, {
    onCompleted: onCompletedEnable,
  });

  const enableOrDisableCustomerDiscount = async (
    customerDiscountId: string | undefined,
    isEnabled: boolean,
  ) => {
    if (!loadingEnable) {
      try {
        await updateCustomerDiscountMutation({
          variables: {
            input: {
              id: customerDiscountId || '',
              userId: user?.me.id || '',
              status: isEnabled
                ? DiscountStatus.ACTIVE
                : DiscountStatus.INACTIVE,
            },
          },
        });
      } catch (error) {}
    }
  };

  const onCompleted = (data: deleteCustomerDiscountMutation) => {
    const { deleteCustomerDiscount: customerDiscountId } = data;
    if (customerDiscountId) {
      setIsModalDeleteVisible(false);
      success('Descuento por cliente eliminado satisfactoriamente');
    }
    setErrorMessage(null);
  };

  const onError = (error: ApolloError) => {
    if (error) {
      setErrorMessage(error.message);
    }
  };

  const [
    deleteCustomerDiscountMutation,
    { loading: loadingDelete },
  ] = useMutation<
    deleteCustomerDiscountMutation,
    deleteCustomerDiscountMutationVariables
  >(DELETE_CUSTOMER_DISCOUNT_MUTATION, {
    onCompleted,
    onError,
  });

  const deleteCustomerDiscount = async (customerDiscountId?: string) => {
    if (!loadingDelete) {
      try {
        await deleteCustomerDiscountMutation({
          variables: {
            input: {
              id: customerDiscountId || '',
              userId: user?.me.id || '',
            },
          },
        });
      } catch (error) {}
    }
  };

  return (
    <div>
      <Modal
        title={'Crear descuento por cliente'}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        afterClose={async () => {
          await refetch();
        }}
        child={
          <CreateCustomerDiscount
            onOk={() => {
              setIsModalVisible(false);
              success('Descuento por cliente creado satisfactoriamente');
            }}
          />
        }
      />
      <Modal
        title={'Actualizar descuento por cliente'}
        visible={isModalUpdateVisible}
        onOk={() => setIsModalUpdateVisible(false)}
        onCancel={() => setIsModalUpdateVisible(false)}
        afterClose={async () => {
          await refetch();
        }}
        child={
          <UpdateCustomerDiscount
            customerDiscount={customerDiscount}
            onOk={() => {
              setIsModalUpdateVisible(false);
              success('Descuento por cliente actualizado satisfactoriamente');
            }}
          />
        }
      />
      <Modal
        title={`${
          customerDiscount?.status === DiscountStatus.ACTIVE
            ? 'Desactivar'
            : 'Activar'
        } descuento por cliente`}
        visible={isModalEnableVisible}
        onOk={async () => {
          await enableOrDisableCustomerDiscount(
            customerDiscount?.id,
            customerDiscount?.status === DiscountStatus.INACTIVE,
          );
        }}
        onCancel={() => {
          setIsModalEnableVisible(false);
          setErrorMessage(null);
        }}
        footer={true}
        afterClose={async () => {
          setErrorMessage(null);
          await refetch();
        }}
        child={
          <div className="flex flex-col">
            <span className="mb-4">
              {`Estás seguro de ${
                customerDiscount?.status === DiscountStatus.ACTIVE
                  ? 'desactivar'
                  : 'activar'
              } el descuento por cliente?`}
            </span>
            {errorMessage && (
              <FormError errorMessage={Exceptions[errorMessage as any]} />
            )}
          </div>
        }
      />
      <Modal
        title={'Eliminar descuento por cliente'}
        visible={isModalDeleteVisible}
        onOk={async () => {
          await deleteCustomerDiscount(customerDiscount?.id);
        }}
        onCancel={() => {
          setIsModalDeleteVisible(false);
          setErrorMessage(null);
        }}
        footer={true}
        afterClose={async () => {
          setErrorMessage(null);
          await refetch();
        }}
        child={
          <div className="flex flex-col">
            <span className="mb-4">
              Estás seguro de eliminar el descuento por cliente?
            </span>
            {errorMessage && (
              <FormError errorMessage={Exceptions[errorMessage as any]} />
            )}
          </div>
        }
      />
      <div className="py-2">
        <div className="py-2 mx-auto max-w-7xl">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900 leading-6">
              Descuentos por cliente
            </h3>
            <button
              type="button"
              onClick={() => {
                setIsModalVisible(true);
              }}
              className="inline-flex items-center px-3 py-2 mr-3 text-sm font-medium text-white bg-indigo-600 border border-transparent shadow-sm leading-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <div className="flex items-center justify-between">
                <span className="pr-2">{Labels.CREATE}</span>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </button>
          </div>
          <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
            <form className="flex flex-col items-center w-full py-8 bg-white shadow sm:rounded-3xl sm:px-4">
              <div className="mb-4 mr-auto">
                {Labels.FILTERS}
                <FontAwesomeIcon icon={faFilter} className="ml-3 text-xl" />
              </div>
              <div className="max-w-xl grid grid-cols-1 gap-5">
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_DATES}</span>
                  </label>
                  <RangePicker
                    showTime={true}
                    placeholder={['Fecha desde', 'Fecha hasta']}
                    className="w-full rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={onChange}
                    disabledDate={(current) => moment().isBefore(current)}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="status"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_STATUS}</span>
                  </label>
                  <select
                    name="status"
                    onChange={(event) => {
                      const value = event.target.value;
                      setStatus(value ? (value as DiscountStatus) : null);
                    }}
                    className="w-full select"
                  >
                    <option value="">Todos</option>
                    {Object.keys(DiscountStatus).map((state) => (
                      <option key={state} value={state}>
                        {DiscountStatusSpanish[state as any]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="categoryId"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">Categorías:</span>
                  </label>
                  <select
                    name="categoryId"
                    onChange={(event) => {
                      const value = event.target.value;
                      setCategoryId(value === 'Todas' ? null : value);
                    }}
                    className="w-full select"
                  >
                    <option value="">Todas</option>
                    {categories?.categories.map((category: any) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </form>
          </div>
          <SkeletonTable
            active={true}
            loading={loading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              dataSource={datasource}
              columns={columns}
              pagination={{
                defaultPageSize: DEFAULT_PAGE_SIZE,
                pageSize: nodesPerPage,
                current: pageCurrent,
                total: data?.customerDiscounts.meta.nodeCount,
                responsive: true,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '100'],
                onShowSizeChange: (current, size) => setNodesPerPage(size),
                onChange: (page) => setPageCurrent(page),
              }}
            />
          </SkeletonTable>
        </div>
      </div>
    </div>
  );
};
