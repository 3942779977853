import React, { useMemo, useState } from 'react';
import { ApolloError, gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@apollo/client';
import {
  adminProducts,
  adminProducts_products_nodes_Product,
  adminProductsVariables,
} from '../../__api__/adminProducts';
import { DatePicker, Modal as AModal, Table, Tag, Tooltip } from 'antd';
import { Labels } from '../../enums/labels.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Modal } from '../../components/modal';
import { CreateProduct, DataType } from './create-product';
import {
  EyeIcon,
  EyeOffIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import {
  updateProductMutation,
  updateProductMutationVariables,
} from '../../__api__/updateProductMutation';
import { useMe } from '../../hooks/use-me';
import { MTTRType, ProductStatus } from '../../__api__/globalTypes';
import { FormError } from '../../components/form-error';
import { Exceptions } from '../../enums/exceptions.enum';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { Button } from '../../components/button';
import { useForm } from 'react-hook-form';
import { useCategories } from '../../hooks/use-categories';
import { DataTypeEnum, ProductStatusSpanish, APIStatusSpanish } from '../../enums/spanish.enum';
import { cloneDeep } from '@apollo/client/utilities';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';

const { RangePicker } = DatePicker;

interface Field {
  label: string;
  name: string;
  dataType: string;
}

const ADMIN_PRODUCTS_QUERY = gql`
  query adminProducts($input: ProductSearcherInput!) {
    products(input: $input) {
      meta {
        nodeCount
        nodesPerPage
        pageCount
        pageCurrent
      }
      nodes {
        __typename
        ... on Product {
          category {
            id
            name
            imageURL
            apiEnabled
          }
          id
          name
          createdAt
          description
          productionCost
          saleCost
          pvp
          gain
          isImmediateResponse
          isAcceptDiscounts
          requiredInfo
          status
          apiEnabled
          apiCost
          suggested
          mttrReqInfo
          mttrID
          mttrType
        }
      }
    }
  }
`;

const UPDATE_PRODUCT_MUTATION = gql`
  mutation updateProductMutation($input: UpdateProductInput!) {
    updateProduct(input: $input)
  }
`;

interface UpdateProductProps {
  product: adminProducts_products_nodes_Product | null;
  loading: boolean;
  errorForm: string | null;
  onOk: (values: any) => void;
}

const UpdateProduct: React.FC<UpdateProductProps> = ({
  product,
  onOk,
  loading,
  errorForm,
}) => {
  const { data: categories } = useCategories();
  const [testMTTRID, setTestMTTRID] = useState(product?.mttrID != null ? product?.mttrID : "");
  const [testMTTRType, setTestMTTRType] = useState(product?.mttrType != null ? MTTRType[product?.mttrType] : MTTRType.DEFAULT);
  const [formMTTRElements, setformMTTRElements] = useState<any[]>(product?.mttrReqInfo != null ? product?.mttrReqInfo.fields : []);
  const categoryOptions = useMemo(() => categories?.categories || [], [
    categories,
  ]);
  const [dynamicFields, setDynamicFields] = useState<Field[]>(
    product?.requiredInfo.fields,
  );

  const { register, getValues, errors, handleSubmit } = useForm({
    mode: 'onChange',
  });

  return (
    <div className="flex flex-col justify-center px-2 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form
            onSubmit={handleSubmit(() => onOk(getValues()))}
            className="space-y-6"
          >
            <div>
              <label htmlFor="categoryId" className="label">
                Categoría
              </label>
              <div className="mt-1">
                <select
                  name="categoryId"
                  defaultValue={product?.category.id}
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {categoryOptions.map((category: any) => (
                    <option
                      selected={product?.category.id === category.id}
                      key={category.id}
                      value={category.id}
                    >
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="name" className="label">
                Nombre
              </label>
              <div className="mt-1">
                <input
                  defaultValue={product?.name}
                  ref={register({
                    required: FormErrorMessages.REQUIRED_CATEGORY_NAME,
                    minLength: 3,
                    maxLength: 100,
                    validate: {
                      isValidCharacters: (name) =>
                        /^[áéíóúÁÉÍÓÚñÑa+-zA-Z0-9 ]*$/.test(name) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="name"
                  minLength={3}
                  maxLength={100}
                  type="text"
                  className="input"
                />
                {errors.name?.message && (
                  <FormError errorMessage={errors.name?.message} />
                )}
                {(errors.name?.type === 'minLength' ||
                  errors.name?.type === 'maxLength') && (
                    <FormError errorMessage={FormErrorMessages.LENGTH} />
                  )}
              </div>
            </div>
            <div>
              <label htmlFor="description" className="label">
                Descripción
              </label>
              <div className="mt-1">
                <input
                  defaultValue={product?.description}
                  ref={register({
                    required: FormErrorMessages.REQUIRED_DESCRIPTION,
                    minLength: 3,
                    maxLength: 500,
                    validate: {
                      isValidCharacters: (description) =>
                        /^[áéíóúÁÉÍÓÚñÑa+-zA-Z0-9 ]*$/.test(description) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="description"
                  minLength={3}
                  maxLength={500}
                  type="text"
                  className="input"
                />
                {errors.description?.message && (
                  <FormError errorMessage={errors.description?.message} />
                )}
                {(errors.description?.type === 'minLength' ||
                  errors.description?.type === 'maxLength') && (
                    <FormError errorMessage={FormErrorMessages.LENGTH} />
                  )}
              </div>
            </div>
            <div>
              <label htmlFor="pvp" className="label">
                Precio de venta al público
              </label>
              <div className="mt-1">
                <input
                  defaultValue={product?.pvp}
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                  })}
                  name="pvp"
                  min={0}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.pvp?.message && (
                  <FormError errorMessage={errors.pvp?.message} />
                )}
                {errors.pvp?.type === 'min' && (
                  <FormError errorMessage={FormErrorMessages.MIN_VALUE} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="productionCost" className="label">
                Costo de producción
              </label>
              <div className="mt-1">
                <input
                  defaultValue={product?.productionCost}
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                  })}
                  name="productionCost"
                  min={0}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.productionCost?.message && (
                  <FormError errorMessage={errors.productionCost?.message} />
                )}
                {errors.productionCost?.type === 'min' && (
                  <FormError errorMessage={FormErrorMessages.MIN_VALUE} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="saleCost" className="label">
                Costo de venta vendedor
              </label>
              <div className="mt-1">
                <input
                  defaultValue={product?.saleCost}
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                  })}
                  name="saleCost"
                  min={0}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.saleCost?.message && (
                  <FormError errorMessage={errors.saleCost?.message} />
                )}
                {errors.saleCost?.type === 'min' && (
                  <FormError errorMessage={FormErrorMessages.MIN_VALUE} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="saleCostAPI" className="label">
                Costo de venta para API
              </label>
              <div className="mt-1">
                <input
                  defaultValue={product?.apiCost}
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                  })}
                  name="saleCostAPI"
                  min={0}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.saleCostAPI?.message && (
                  <FormError errorMessage={errors.saleCostAPI?.message} />
                )}
                {errors.saleCostAPI?.type === 'min' && (
                  <FormError errorMessage={FormErrorMessages.MIN_VALUE} />
                )}
              </div>
            </div>
            <div className="flex -align-center">
              <label htmlFor="apiEnabled" className="mt-1 label">
                Se encuentra habilitado en el API?
              </label>
              <div className="mt-1">
                <input
                  defaultChecked={product?.apiEnabled}
                  ref={register()}
                  name="apiEnabled"
                  type="checkbox"
                  className="w-4 h-4 ml-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
              </div>
            </div>
            <div className="flex -align-center">
              <label htmlFor="isAcceptDiscounts" className="mt-1 label">
                Acepta descuentos?
              </label>
              <div className="mt-1">
                <input
                  defaultChecked={product?.isAcceptDiscounts}
                  ref={register()}
                  name="isAcceptDiscounts"
                  type="checkbox"
                  className="w-4 h-4 ml-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
              </div>
            </div>
            <div className="flex -align-center">
              <label htmlFor="isImmediateResponse" className="mt-1 label">
                Tiene respuesta inmediata?
              </label>
              <div className="mt-1">
                <input
                  defaultChecked={product?.isImmediateResponse}
                  ref={register()}
                  name="isImmediateResponse"
                  type="checkbox"
                  className="w-4 h-4 ml-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
              </div>
            </div>
            <div className="flex -align-center">
              <label htmlFor="IsSuggested" className="mt-1 label">
                Es Sugerido a los Clientes?
              </label>
              <div className="mt-1">
                <input
                  defaultChecked={product?.suggested}
                  ref={register()}
                  name="IsSuggested"
                  type="checkbox"
                  className="w-4 h-4 ml-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
              </div>
            </div>
            <div>
              <label htmlFor="mttrID" className="label">
                Codigo MTTR
              </label>
              <div className="mt-1">
                <input
                  defaultValue={product?.mttrID != null ? product?.mttrID : ""}
                  ref={register({
                    minLength: 0,
                    maxLength: 100,
                    validate: {
                      isValidCharacters: (name) =>
                        /^[áéíóúÁÉÍÓÚñÑa+-zA-Z0-9 ]*$/.test(name) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="mttrID"
                  minLength={0}
                  maxLength={100}
                  type="text"
                  className="input"
                  onChange={e => setTestMTTRID(e.target.value)}
                />
                {errors.mttrID?.message && (
                  <FormError errorMessage={errors.mttrID?.message} />
                )}
                {(errors.mttrID?.type === 'minLength' ||
                  errors.mttrID?.type === 'maxLength') && (
                    <FormError errorMessage={FormErrorMessages.LENGTH} />
                  )}
              </div>
            </div>
            {testMTTRID != "" && (
              <div>
                <label htmlFor="mttrType" className="label">
                  Tipo de MTTR
                </label>
                <div className="mt-1">
                  <select
                    name="mttrType"
                    ref={register({ required: true })}
                    className="input"
                    onChange={e => setTestMTTRType(e.target.value as MTTRType)}
                    defaultValue={product?.mttrType ? MTTRType[product?.mttrType] : MTTRType.DEFAULT}
                  >
                    <option value="">{Labels.SELECTED_ONE}</option>
                    {Object.keys(MTTRType).map((key) => (
                      <option value={MTTRType[key]}>{key}</option>
                    ))}

                  </select>
                </div>
              </div>
            )}
            {(testMTTRID == "" || (testMTTRID != "" && testMTTRType == MTTRType.DEFAULT)) && (
              <div>
                <h3 className="mb-1 font-semibold">
                  Campos dinámicos del producto
                </h3>
                <div
                  onClick={() =>
                    setDynamicFields((elements) => [
                      ...elements,
                      {
                        name: `temp${elements.length + 1}`,
                        label: '',
                        dataType: '',
                      },
                    ])
                  }
                  className="flex text-gray-700 cursor-pointer text-l group"
                >
                  <span className="mr-2 text-gray-700 group-hover:text-blue-700">
                    Agregar nuevo campo
                  </span>
                  <PlusCircleIcon
                    className="w-6 h-6 mr-3 group-hover:text-blue-700"
                    aria-hidden="true"
                  />
                </div>
                {dynamicFields.map((field: Field, index: number) => {
                  const labelId = `defaultlabel-${field.name}-${index}`;
                  const nameId = `defaultname-${field.name}-${index}`;
                  const dataTypeId = `defaultdataType-${field.name}-${index}`;
                  return (
                    <div
                      className="px-3 py-3 border-2 border-r-2 rounded-2xl"
                      key={index}
                    >
                      <div
                        onClick={() => {
                          const newArray = cloneDeep(dynamicFields);
                          if (newArray.length > 1) {
                            setDynamicFields(
                              newArray.filter(
                                (element) => element.name !== field.name,
                              ),
                            );
                          }
                        }}
                        className="flex justify-end"
                      >
                        <TrashIcon
                          className="w-6 h-6 mr-3 cursor-pointer hover:text-blue-700"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mb-4" key={labelId}>
                        <label htmlFor={labelId} className="label">
                          Etiqueta
                        </label>
                        <div className="mt-1">
                          <input
                            ref={register({
                              required: FormErrorMessages.REQUIRED_FIELD,
                            })}
                            defaultValue={field.label}
                            name={labelId}
                            type="text"
                            className="input"
                          />
                          {errors[labelId]?.message && (
                            <FormError errorMessage={errors[labelId]?.message} />
                          )}
                        </div>
                      </div>
                      <div className="mb-4" key={nameId}>
                        <label htmlFor={nameId} className="label">
                          ID del formulario
                        </label>
                        <div className="mt-1">
                          <input
                            ref={register({
                              required: FormErrorMessages.REQUIRED_FIELD,
                            })}
                            defaultValue={
                              field.name.includes('temp') ? '' : field.name
                            }
                            name={nameId}
                            type="text"
                            className="input"
                          />
                          {errors[nameId]?.message && (
                            <FormError errorMessage={errors[nameId]?.message} />
                          )}
                        </div>
                      </div>
                      <div className="mb-2" key={dataTypeId}>
                        <label htmlFor={dataTypeId} className="label">
                          Tipo de dato
                        </label>
                        <div className="mt-1">
                          <select
                            name={dataTypeId}
                            className="select"
                            ref={register({ required: true })}
                            defaultValue={field.dataType.toUpperCase()}
                          >
                            <option value="">{Labels.SELECTED_ONE}</option>
                            {Object.keys(DataType).map((dataType) => (
                              <option key={dataType} value={dataType}>
                                {DataTypeEnum[dataType as any]}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {(testMTTRID != "" && testMTTRType==MTTRType.DEFAULT) && (
              <div>
                <h3 className="mb-1 font-semibold">
                  Campos dinámicos para topup de MTTR (Repetir la llave de la respuesta de mttr para que se use en el topup)
                </h3>
                <div
                  onClick={() =>
                    setformMTTRElements((elements) => [
                      ...elements,
                      {
                        name: `temp${elements.length + 1}`,
                        label: '',
                        dataType: '',
                      },
                    ])
                  }
                  className="flex text-gray-700 cursor-pointer text-l group"
                >
                  <span className="mr-2 text-gray-700 group-hover:text-blue-700">
                    Agregar nuevo campo
                  </span>
                  <PlusCircleIcon
                    className="w-6 h-6 mr-3 group-hover:text-blue-700"
                    aria-hidden="true"
                  />
                </div>
                {formMTTRElements.map((field: Field, index: number) => {
                  const labelId = `mttrlabel-${field.name}-${index}`;
                  const nameId = `mttrname-${field.name}-${index}`;
                  const dataTypeId = `mttrdataType-${field.name}-${index}`;
                  return (
                    <div
                      className="px-3 py-3 border-2 border-r-2 rounded-2xl"
                      key={index}
                    >
                      <div
                        onClick={() => {
                          const newArray = cloneDeep(formMTTRElements);
                          if (newArray.length > 1) {
                            setformMTTRElements(
                              newArray.filter(
                                (element) => element.name !== field.name,
                              ),
                            );
                          }
                        }}
                        className="flex justify-end"
                      >
                        <TrashIcon
                          className="w-6 h-6 mr-3 cursor-pointer hover:text-blue-700"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mb-4" key={labelId}>
                        <label htmlFor={labelId} className="label">
                          Etiqueta
                        </label>
                        <div className="mt-1">
                          <input
                            ref={register({
                              required: FormErrorMessages.REQUIRED_FIELD,
                            })}
                            defaultValue={field.label}
                            name={labelId}
                            type="text"
                            className="input"
                          />
                          {errors[labelId]?.message && (
                            <FormError errorMessage={errors[labelId]?.message} />
                          )}
                        </div>
                      </div>
                      <div className="mb-4" key={nameId}>
                        <label htmlFor={nameId} className="label">
                          ID del formulario
                        </label>
                        <div className="mt-1">
                          <input
                            ref={register({
                              required: FormErrorMessages.REQUIRED_FIELD,
                            })}
                            defaultValue={
                              field.name.includes('temp') ? '' : field.name
                            }
                            name={nameId}
                            type="text"
                            className="input"
                          />
                          {errors[nameId]?.message && (
                            <FormError errorMessage={errors[nameId]?.message} />
                          )}
                        </div>
                      </div>
                      <div className="mb-2" key={dataTypeId}>
                        <label htmlFor={dataTypeId} className="label">
                          Tipo de dato
                        </label>
                        <div className="mt-1">
                          <select
                            name={dataTypeId}
                            className="select"
                            ref={register({ required: true })}
                            defaultValue={field.dataType.toUpperCase()}
                          >
                            <option value="">{Labels.SELECTED_ONE}</option>
                            {Object.keys(DataType).map((dataType) => (
                              <option key={dataType} value={dataType}>
                                {DataTypeEnum[dataType as any]}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>)}
            <Button
              canClick={true}
              loading={loading}
              actionText={Labels.UPDATE}
            />
            <div className="mb-2" />
            {errorForm && <FormError errorMessage={errorForm} />}
          </form>
        </div>
      </div>
    </div>
  );
};

const DEFAULT_PAGE_SIZE = 10;

export const AdminProducts = () => {
  const { data: user } = useMe();
  const { data: categories } = useCategories();

  const [pageCurrent, setPageCurrent] = useState(1);
  const [nodesPerPage, setNodesPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [isModalEnableVisible, setIsModalEnableVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [status, setStatus] = useState<ProductStatus | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [
    product,
    setProduct,
  ] = useState<adminProducts_products_nodes_Product | null>(null);

  const onCompleted = async (data: updateProductMutation) => {
    const { updateProduct: productId } = data;
    if (productId) {
      setErrorMessage(null);
      if (isModalEnableVisible) {
        setIsModalEnableVisible(false);
        success('Se actualizó el producto satisfactoriamente');
      }
      if (isModalUpdateVisible) {
        setIsModalUpdateVisible(false);
        success('Producto actualizado satisfactoriamente');
      }
      await refetch();
    }
  };

  const onError = (error: ApolloError) => {
    if (error) {
      setErrorMessage(Exceptions[error.message as any]);
    }
  };

  const [updateProductMutation, { loading: loadingMutation }] = useMutation<
    updateProductMutation,
    updateProductMutationVariables
  >(UPDATE_PRODUCT_MUTATION, { onCompleted, onError });

  const enableOrDisableProduct = async (
    productId: string | undefined,
    isEnabled: boolean,
  ) => {
    if (!loadingMutation) {
      try {
        await updateProductMutation({
          variables: {
            input: {
              id: productId || '',
              userId: user?.me.id || '',
              status: isEnabled ? ProductStatus.ACTIVE : ProductStatus.INACTIVE,
            },
          },
        });
      } catch (error) { }
    }
  };

  const updateProduct = async (input: any) => {
    if (!loadingMutation) {
      try {
        let label: string | null, name: string | null, dataType: string | null;
        const fields: Field[] = [];
        Object.keys(input).forEach((key: string) => {
          if (key.startsWith('defaultlabel')) {
            label = input[key];
          }
          if (key.startsWith('defaultname')) {
            name = input[key].toLowerCase();
          }
          if (key.startsWith('defaultdataType')) {
            dataType = input[key];
          }
          if (label && name && dataType) {
            fields.push({ label, name, dataType: dataType.toLowerCase() });
            name = null;
            label = null;
            dataType = null;
          }
        });
        const requiredInfo = { fields };
        let mttrReqInfo: any | null = null;
        if (input.mttrID != "") {
          const mttrFields: Field[] = [];
          Object.keys(input).forEach((key: string) => {
            if (key.startsWith('mttrlabel')) {
              label = input[key];
            }
            if (key.startsWith('mttrname')) {
              name = input[key].toLowerCase();
            }
            if (key.startsWith('mttrdataType')) {
              dataType = input[key];
            }
            if (label && name && dataType) {
              mttrFields.push({ label, name, dataType: dataType.toLowerCase() });
              name = null;
              label = null;
              dataType = null;
            }
          });
          mttrReqInfo = { fields: mttrFields };
        }
        await updateProductMutation({
          variables: {
            input: {
              id: product?.id || '',
              categoryId: input.categoryId,
              name: input.name,
              description: input.description,
              productionCost: Number(input.productionCost),
              pvp: Number(input.pvp),
              saleCost: Number(input.saleCost),
              apiCost: Number(input.saleCostAPI),
              apiEnabled: input.apiEnabled,
              isAcceptDiscounts: input.isAcceptDiscounts || false,
              isImmediateResponse: input.isImmediateResponse || false,
              requiredInfo,
              userId: user?.me.id || '',
              suggested: input.IsSuggested || false,
              mttrID: input.mttrID == "" ? null : input.mttrID,
              mttrReqInfo: input.mttrID == "" ? null : mttrReqInfo,
              mttrType: input.mttrID == "" ? null : MTTRType[input.mttrType],
            },
          },
        });
      } catch (error) { }
    }
  };

  const onChange = (values: any) => {
    if (!values) {
      setDateFrom(null);
      setDateTo(null);
      return;
    }
    const dateFrom = values[0].format();
    const dateTo = values[1].format();

    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };

  const { data, loading, refetch } = useQuery<
    adminProducts,
    adminProductsVariables
  >(ADMIN_PRODUCTS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        pageCurrent,
        nodesPerPage,
        where: {
          categoryId,
          dateFrom,
          dateTo,
          name,
          status,
        },
      },
    },
  });

  const products = data?.products.nodes as
    | adminProducts_products_nodes_Product[]
    | null;

  const columns = [
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Costo de producción',
      dataIndex: 'productionCost',
      key: 'productionCost',
    },
    {
      title: 'PVP',
      dataIndex: 'pvp',
      key: 'pvp',
    },
    {
      title: 'Costo de venta vendedor',
      dataIndex: 'saleCost',
      key: 'saleCost',
    },
    {
      title: 'Costo de venta API',
      dataIndex: 'apiCost',
      key: 'apiCost',
    },
    {
      title: 'Ganancia',
      dataIndex: 'gain',
      key: 'gain',
    },
    {
      title: 'API Habilitado',
      dataIndex: 'enabledAPI',
      key: 'enabledAPI',
    },
    {
      title: 'Tiene respuesta inmediata',
      dataIndex: 'isImmediateResponse',
      key: 'isImmediateResponse',
    },
    {
      title: 'Acepta descuento',
      dataIndex: 'isAcceptDiscounts',
      key: 'isAcceptDiscounts',
    },
    {
      title: 'Campos requeridos',
      dataIndex: 'requiredInfo',
      key: 'requiredInfo',
    },
  ];

  const datasource = products?.map((product) => ({
    key: product.id,
    enabledAPI: (
      <Tag color={product.apiEnabled ? 'geekblue' : 'error'}>
        {product.apiEnabled ? APIStatusSpanish.ON : APIStatusSpanish.OFF}
      </Tag>
    ),
    apiCost: `$${product.apiCost}`,
    pvp: `$${product.pvp}`,
    category: product.category.name,
    name: product.name,
    createdAt: product.createdAt,
    productionCost: `$${product.productionCost}`,
    saleCost: `$${product.saleCost}`,
    gain: `$${product.gain}`,
    isImmediateResponse: product.isImmediateResponse ? 'Si' : 'No',
    isAcceptDiscounts: product.isAcceptDiscounts ? 'Si' : 'No',
    requiredInfo: product ? (
      <ul>
        {product.requiredInfo.fields.map((field: Field) => (
          <li key={field.label}>
            <span className="font-semibold">
              {field.label}
              {': '}
            </span>
            <span>{field.dataType}</span>
          </li>
        ))}
      </ul>
    ) : (
      ''
    ),
    status: (
      <Tag color={product.status === 'ACTIVE' ? 'geekblue' : 'error'}>
        {ProductStatusSpanish[product.status]}
      </Tag>
    ),
    action: (
      <div className="flex items-center justify-start">
        <div
          onClick={() => {
            setProduct(product);
            setIsModalUpdateVisible(true);
          }}
          className="flex-shrink-0"
        >
          <Tooltip title="editar">
            <PencilIcon
              className="w-6 h-6 mr-3 text-gray-400 cursor-pointer hover:text-blue-400"
              aria-hidden="true"
            />
          </Tooltip>
        </div>
        <div
          onClick={() => {
            setProduct(product);
            setIsModalEnableVisible(true);
          }}
          className="flex-shrink-0"
        >
          <Tooltip
            title={product.status === 'ACTIVE' ? 'desactivar' : 'activar'}
          >
            {product.status === 'ACTIVE' && (
              <EyeOffIcon
                className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                aria-hidden="true"
              />
            )}
            {product.status === 'INACTIVE' && (
              <EyeIcon
                className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                aria-hidden="true"
              />
            )}
          </Tooltip>
        </div>
      </div>
    ),
  }));

  const success = (content: string) => {
    AModal.success({
      content,
    });
  };

  return (
    <div>
      <Modal
        title={Labels.CREATE_PRODUCT}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        afterClose={async () => {
          setPageCurrent(1);
          setDateTo(null);
          setDateTo(null);
          await refetch();
        }}
        child={
          <CreateProduct
            onOk={() => {
              setIsModalVisible(false);
              success('Producto creado satisfactoriamente');
            }}
          />
        }
      />
      <Modal
        title={`${product?.status === ProductStatus.ACTIVE ? 'Desactivar' : 'Activar'
          } producto`}
        visible={isModalEnableVisible}
        onOk={async () => {
          await enableOrDisableProduct(
            product?.id,
            product?.status === ProductStatus.INACTIVE,
          );
        }}
        onCancel={() => {
          setIsModalEnableVisible(false);
          setErrorMessage(null);
        }}
        footer={true}
        afterClose={async () => {
          setErrorMessage(null);
          await refetch();
        }}
        child={
          <div className="flex flex-col">
            <span className="mb-4">
              {`Estás seguro de ${product?.status === ProductStatus.ACTIVE
                  ? 'desactivar'
                  : 'activar'
                } el producto?`}
            </span>
            {errorMessage && (
              <FormError errorMessage={Exceptions[errorMessage as any]} />
            )}
          </div>
        }
      />
      <Modal
        title={Labels.UPDATE_PRODUCT}
        visible={isModalUpdateVisible}
        onOk={() => setIsModalUpdateVisible(false)}
        onCancel={() => setIsModalUpdateVisible(false)}
        afterClose={async () => {
          await refetch();
        }}
        child={
          <UpdateProduct
            product={product}
            loading={loadingMutation}
            errorForm={errorMessage}
            onOk={updateProduct}
          />
        }
      />
      <div className="py-6">
        <div className="mx-auto px-1.5 max-w-7xl">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900 leading-6">
              {Labels.MENU_PRODUCTS}
            </h3>
            <button
              type="button"
              onClick={() => {
                setIsModalVisible(true);
              }}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent shadow-sm leading-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <div className="flex items-center justify-between">
                <span className="pr-2">{Labels.CREATE}</span>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </button>
          </div>
          <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
            <form className="flex flex-col items-center w-full py-8 bg-white shadow sm:rounded-3xl sm:px-4">
              <div className="mb-4 mr-auto">
                {Labels.FILTERS}
                <FontAwesomeIcon icon={faFilter} className="ml-3 text-xl" />
              </div>
              <div className="max-w-xl grid grid-cols-1 gap-5">
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_DATES}</span>
                  </label>
                  <RangePicker
                    showTime={true}
                    placeholder={['Fecha desde', 'Fecha hasta']}
                    className="w-full rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={onChange}
                    disabledDate={(current) => moment().isBefore(current)}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="status"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_STATUS}</span>
                  </label>
                  <select
                    name="status"
                    onChange={(event) => {
                      const value = event.target.value;
                      setStatus(value ? (value as ProductStatus) : null);
                    }}
                    className="w-full select"
                  >
                    <option value="">Todos</option>
                    {Object.keys(ProductStatus).map((state) => (
                      <option key={state} value={state}>
                        {ProductStatusSpanish[state as any]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="categoryId"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">Categorías:</span>
                  </label>
                  <select
                    name="categoryId"
                    onChange={(event) => {
                      const value = event.target.value;
                      setCategoryId(value === 'Todas' ? null : value);
                    }}
                    className="w-full select"
                  >
                    <option value="">Todas</option>
                    {categories?.categories.map((category: any) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="productName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">Producto</span>
                  </label>
                  <input
                    onChange={(event) => {
                      const value = event.target.value;
                      setName(value);
                    }}
                    name="productName"
                    type="text"
                    className="input"
                  />
                </div>
              </div>
            </form>
          </div>
          <SkeletonTable
            active={true}
            loading={loading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              dataSource={datasource}
              columns={columns}
              pagination={{
                defaultPageSize: DEFAULT_PAGE_SIZE,
                pageSize: nodesPerPage,
                current: pageCurrent,
                total: data?.products.meta.nodeCount,
                responsive: true,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '100'],
                onShowSizeChange: (current, size) => setNodesPerPage(size),
                onChange: (page) => setPageCurrent(page),
              }}
            />
          </SkeletonTable>
        </div>
      </div>
    </div>
  );
};
