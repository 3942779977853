import { FooterLogout } from '../components/footer-logout';
import { Link } from 'react-router-dom';
import logo from '../images/byller-logo.png';
import { PointsDesign } from '../components/points-design';
import { Titles } from '../enums/titles.enum';
import { Helmet } from 'react-helmet-async';

const navigation = [{ name: 'Inicio', href: '/' }];
export const TermsOfService = () => {
  return (
    <div>
      <Helmet>
        <title>{Titles.TERMS}</title>
      </Helmet>
      <div className="px-4 pt-6 mx-auto max-w-7xl sm:px-6">
        <Link to="/">
          <img className="w-auto h-32" src={logo} alt="Byllergames" />
        </Link>
      </div>
      <div className="relative py-8 overflow-hidden bg-white">
        <PointsDesign />
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto text-lg max-w-prose">
            <h1>
              <span className="block mt-8 text-2xl font-extrabold tracking-tight text-center text-gray-900 leading-8">
                TÉRMINOS Y CONDICIONES
              </span>
            </h1>
          </div>
          <div className="mx-auto mt-6 text-gray-500 prose prose-indigo prose-lg">
            <h4>1. CAPACIDAD</h4>
            <p className='text-justify'>
              Nuestros servicios sólo están disponibles para personas que tengan
              capacidad legal para contratar. No podrán utilizar los servicios
              menores de edad, las personas que no tengan esa capacidad o las
              personas que hayan sido declarados incapaces judicialmente o dados
              de baja del sistema de Todo en línea, temporal o definitivamente,
              por haber incumplido los Términos y Condiciones Generales o por
              haber incurrido a criterio de Todo en línea en conductas o actos
              dolosos o fraudulentos mediante el uso del sitio o de los
              servicios. En general, serán consideradas como conductas o actos
              dolosos o fraudulentos aquellos descritos como tales en parte
              integrante de este contrato.
            </p>
            <h4>2. REGISTRO</h4>
            <p className='text-justify'>
              Es obligatorio completar el formulario de registro para poder
              utilizar los servicios que brinda Todo en línea. El futuro Usuario
              deberá completarlo con su información personal de manera exacta,
              precisa y verdadera ("Datos Personales"). Todos los campos deberán
              ser completados con la información requerida. El Usuario deberá
              actualizar los Datos Personales conforme resulte necesario. Un
              Ejecutivo de Servicio al Cliente contactará al Usuario
              telefónicamente o por cualquier otro medio para verificar los
              datos ingresados. Todo en línea se reserva el derecho de
              inhabilitar a aquellos Usuarios que en un período razonable no
              logre contactar o bien al momento de la verificación no quieran
              confirmar sus datos. En estos casos de inhabilitación, se dará de
              baja todos los productos publicados, así como las ofertas
              realizadas, sin que ello genere algún derecho a resarcimiento. El
              Usuario accederá a su Cuenta Personal mediante el ingreso de su
              correo y Password o Clave de Seguridad personal, ambos elegidos
              por el Usuario al momento del registro. El Usuario se obliga a
              mantener la confidencialidad de su Clave de Seguridad. El Usuario
              será responsable por todas las operaciones efectuadas en su
              Cuenta, pues el acceso a la misma está restringido al ingreso y
              uso de su Clave de Seguridad, de conocimiento exclusivo del
              Usuario. El Usuario se compromete a notificar a Todo en línea en
              forma inmediata y por medio idóneo y fehaciente, cualquier uso no
              autorizado de su Cuenta, así como el ingreso por terceros no
              autorizados a la misma.
            </p>
            <h4>3. MODIFICACIONES DEL ACUERDO</h4>
            <p className='text-justify'>
              De acuerdo a normas legales, regulaciones del mercado sobre oferta
              y demanda y cualquier otra situación, los términos y condiciones
              de este contrato podrán ser modificadas, las cuales serán
              debidamente notificadas al Usuario publicando una versión
              actualizada de dichos términos y condiciones en el sitio. Dentro
              de los 5 (cinco) días siguientes a la publicación de las
              modificaciones introducidas, el Usuario deberá comunicar por
              e-mail a byllergames@gmail.com si no acepta las mismas; en ese
              caso quedará disuelto el vínculo contractual. Vencido este plazo,
              se considerará que el Usuario acepta tácitamente los nuevos
              términos y el contrato continuará vinculando a ambas partes.
            </p>
            <h4>4. PRIVACIDAD DE LA INFORMACIÓN</h4>
            <p className='text-justify'>
              Todo en línea no venderá, alquilará ni negociará con otras
              empresas su información personal. Estos datos serán usados
              únicamente para que quien resulte comprador de un bien ofrecido
              pueda contactarse con el vendedor y realizar la transacción de una
              manera eficiente. Todo en línea no venderá su base de datos a
              otras empresas u organizaciones. Sin embargo, Todo en línea podrá
              compartir dicha información con proveedores de servicios de valor
              agregado que se integren dentro del sitio o mediante links a otros
              sitios de Internet, para atender necesidades de los Usuarios
              relacionadas con los servicios que suministra Todo en línea. Toda
              la información personal que usted transmite se hace a través de
              una página de Internet segura que protege y encripta su
              información. Igualmente, su información personal se almacena en
              servidores o medios magnéticos que mantienen altos estándares de
              seguridad. Todo en línea hará todo lo necesario para mantener la
              confidencialidad y seguridad de que trata esta sección, pero no
              responderá por perjuicios que se puedan derivar de la violación de
              dichas medidas por parte de terceros que utilicen las redes
              públicas o el Internet para acceder a dicha información.
            </p>
            <h4>5. VIOLACIONES DEL SISTEMA O BASES DE DATOS</h4>
            <p className='text-justify'>
              No está permitido el uso de ningún dispositivo, software, u otro
              medio tendiente a interferir tanto en las actividades y operatoria
              de Todo en línea como en las ofertas o en las bases de datos de
              Todo en línea. Cualquier intromisión, tentativa o actividad
              violatoria o contraria a las leyes sobre derecho de autor y/o a
              las prohibiciones estipuladas en este contrato harán pasible a su
              responsable de las acciones legales pertinentes, y a las sanciones
              previstas por este acuerdo, así como lo hará responsable de
              indemnizar los daños ocasionados.
            </p>
            <h4>6. SANCIONES</h4>
            <p className='text-justify'>
              Todo en línea podrá advertir, suspender o cancelar, temporal o
              definitivamente la Cuenta de un Usuario en cualquier momento, e
              iniciar las acciones legales que correspondan, si se quebrantara
              cualquiera de las estipulaciones de este contrato, si se incurre a
              criterio de Todo en línea en conductas o actos dolosos o
              fraudulentos, o bien si no pudiera verificarse la identidad del
              Usuario o cualquier información proporcionada por el mismo fuere
              errónea. En el caso de la suspensión o inhabilitación de un
              Usuario, todos los productos que tuviera publicados, y las ofertas
              realizadas también serán dadas de baja. Todo en línea se reserva
              el derecho de cancelar la Cuenta del Usuario que hubiere
              incumplido sus obligaciones derivadas de una transacción, o si se
              detectara en su conducta intencionalidad de perjudicar o defraudar
              a otros Usuarios.
            </p>
            <h4>7. SUSPENSIÓN DE TRANSACCIONES</h4>
            <p className='text-justify'>
              Todo en línea podrá aplazar, interrumpir, o terminar una
              transacción en cualquier momento en virtud de una violación a las
              cláusulas previstas en este acuerdo, por no estar verificados los
              datos del comprador o por cualquier otra razón. Todo en línea se
              reserva el derecho a cancelar una transacción por razones no
              mencionadas anteriormente o si considera que el Usuario Comprador
              ha incurrido en algún error involuntario o por cualquier otra
              razón que Todo en línea considere justificada.
            </p>
            <h4>8. RESPONSABILIDAD</h4>
            <p className='text-justify'>
              Todo en línea se hace responsable y cubre la garantía en las
              transacciones o compras realizadas por parte del Usuario
              Comprador. Todo en línea NO será responsable por las transacciones
              que se lleven a cabo entre los Usuarios, o entre los Usuarios y
              terceros, por cuanto tales transacciones se realizan entre
              Usuarios Vendedores y Compradores de productos y servicios, y Todo
              en línea proporciona el medio al través del cual dichas
              transacciones pueden realizarse. En caso que uno o más Usuarios o
              algún tercero inicie cualquier tipo de reclamo o acciones legales
              contra otro u otros Usuarios, todos y cada uno de los Usuarios
              involucrados en dichos reclamos o acciones eximen de toda
              responsabilidad a Todo en línea y a sus directores, gerentes,
              empleados, agentes, operarios, representantes y apoderados, por
              cuanto tales transacciones se realizan entre Usuarios Vendedores y
              Compradores de productos y servicios y Tienda en línea proporciona
              el medio a través del cual dichas transacciones pueden realizarse.
            </p>
            <h4>9. ALCANCE DE LOS SERVICIOS DE BYLLERGAMES</h4>
            <p className='text-justify'>
              Este acuerdo no crea ningún contrato de sociedad, de mandato, de
              franquicia, o relación laboral entre Todo en línea y el Usuario.
              Todo en línea otorga la garantía de los bienes objeto de las
              transacciones entre los Usuarios, por cuanto tales transacciones
              se realizan entre Usuarios Compradores y Todo en línea proporciona
              el medio a través del cual dichas transacciones pueden realizarse.
              El Usuario deberá realizar los pagos correspondientes por envíos,
              servicios y otros gastos en que incurra por la oferta, producto, y
              por las transacciones realizadas a través de Todo en línea. El
              Usuario reconoce que la transferencia de bienes inmuebles o
              registrables está sujeta a regulaciones específicas con las cuales
              deberá cumplir.
            </p>
            <h4>10. FALLAS EN EL SISTEMA</h4>
            <p className='text-justify'>
              Todo en línea no se responsabiliza por cualquier daño, perjuicio o
              pérdida en el equipo del Usuario causado por fallas en el sistema,
              en el servidor o en Internet, a no ser que se compruebe que, por
              acción, omisión, dolo, negligencia por parte de Todo en línea.
              Todo en línea tampoco será responsable por cualquier virus que
              pudiera infectar el equipo del Usuario como consecuencia del
              acceso, uso o examen del Sitio o a raíz de cualquier transferencia
              de datos, archivos, imágenes, textos, o audios contenidos en el
              mismo a no ser que se compruebe acción, omisión, dolo, negligencia
              por parte de Todo en línea. Los Usuarios NO podrán imputarle
              responsabilidad alguna ni exigir pago por lucro cesante, en virtud
              de perjuicios resultantes de dificultades técnicas o fallas en los
              sistemas o en Internet a no ser que se compruebe acción, omisión,
              dolo, negligencia por parte de Todo en línea. Todo en línea no
              garantiza el acceso y uso continuado o ininterrumpido de su Sitio,
              por causas externas a no ser que se compruebe que, por acción,
              omisión, dolo, negligencia por parte de Todo en línea se produjo
              tal interrupción. El sistema puede eventualmente no estar
              disponible debido a dificultades técnicas o fallas de Internet, o
              por cualquier otra circunstancia ajena a Todo en línea; en tales
              casos se procurará restablecerlo con la mayor rapidez posible sin
              que por ello pueda imputársele algún tipo de responsabilidad.
            </p>
            <h4>11. TARIFAS</h4>
            <p className='text-justify'>
              El registro en Todo en línea es gratuito. Cuando una transacción
              se concrete, el Usuario Comprador deberá pagar a Todo en línea el
              precio establecido en la publicación del artículo, la cual es
              conocida y aceptada por cuanto forma parte integrante del proceso
              de publicación de artículos o servicios. Todas las tarifas deberán
              ser abonadas en DOLARES NORTEAMERICANOS. Todo en línea se reserva
              el derecho de modificar, cambiar, agregar, o eliminar las tarifas
              vigentes, en cualquier momento. Los cambios y modificaciones de
              precios en los productos, promociones y demás se aplicarán bajo
              previo aviso.
            </p>
            <h4>12. PROPIEDAD INTELECTUAL</h4>
            <p className='text-justify'>
              Los contenidos de las pantallas relativas a los servicios de Todo
              en línea como así también los programas, bases de datos, redes,
              archivos que permiten al Usuario acceder y usar su Cuenta, son de
              propiedad de Todo -en-línea.com y están protegidas por las leyes y
              tratados internacionales de derecho de autor, marcas, patentes,
              modelos y diseños industriales. El uso indebido y la reproducción
              total o parcial de dichos contenidos quedan prohibidos, están
              penados por la ley con sanciones civiles y penales, y serán objeto
              de todas las acciones judiciales pertinentes.
            </p>
            <h4>13. LEY APLICABLE</h4>
            <p className='text-justify'>
              Este acuerdo estará regido en todos sus puntos por las leyes
              vigentes en la República del Ecuador.
            </p>
            <h4>14. JURISDICCIÓN APLICABLE</h4>
            <p className='text-justify'>
              Para todo lo relativo a la interpretación y cumplimiento de este
              Contrato, las partes se someten a las leyes aplicables y a los
              Tribunales competentes de la Ciudad de Quito, y renuncian a
              cualquier otro fuero que por razón de sus domicilios presentes o
              futuros pudiere corresponderles.
            </p>
            <h4>15. DOMICILIO</h4>
            <p className='text-justify'>
              Se fija como domicilio de Todo en línea el siguiente: Casa de 2
              pisos, ubicada en la Avenida 6 de diciembre n92-65 y sabanilla,
              Quito, Distrito Metropolitano, Ecuador. El celular es: +593 96 173
              5894.
            </p>
          </div>
        </div>
      </div>
      <FooterLogout navigation={navigation} />
    </div>
  );
};
