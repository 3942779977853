import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {DocumentDownloadIcon, InformationCircleIcon} from '@heroicons/react/outline';
import { Titles } from '../../enums/titles.enum';
import { Helmet } from 'react-helmet-async';
import { Labels } from '../../enums/labels.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { DatePicker, Select, Table, Tag,Tooltip } from 'antd';
import { gql } from '@apollo/client/core';
import { useMe } from '../../hooks/use-me';
import {  useQuery } from '@apollo/client';
import { FacType, OrderStatus, Role } from '../../__api__/globalTypes';
import {
  purchases,
  purchases_orders_nodes_PurchaseOrder,
  purchasesVariables,
} from '../../__api__/purchases';
import { PurchaseOrderStatus } from '../../enums/spanish.enum';
import { getFormattedValue } from '../../utils/string-utils';
import {
  subsidiaries,
  subsidiaries_users_nodes_User,
  subsidiariesVariables,
} from '../../__api__/subsidiaries';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';
import { parseToShow,filterObject, keysToFilterDetail, keysToFilterResult, parseFormData, getValueFromArray } from '../../utils/display-utils';
import { JSONDetail } from '../../components/jsonDetails';
import { Modal } from '../../components/modal';
import { RequestDevolution } from './request-devolution';

interface Field {
  name: string;
  value: string;
}

const { RangePicker } = DatePicker;
const { Option } = Select;

const PURCHASE_ORDER_QUERY = gql`
  query purchases($input: PurchaseOrderSearcherInput!) {
    orders(input: $input) {
      meta {
        nodeCount
        nodesPerPage
        pageCount
        pageCurrent
      }
      nodes {
        __typename
        ... on PurchaseOrder {
          id
          product {
            name
            categoryName
            description
          }
          comment
          createdAt
          updatedAt
          status
          detail
          result
          total
          precioVenta
          balanceNuevo
          factNames
          facLastnames
          facAddress
          facID
          facIDType
          alternateName
          alternateDesc
          customer {
            email
            name
            lastName
            identificationNumber
          }
          devolution {
            request
            answer
          }
        }
      }
    }
  }
`;

const SUBSIDIARIES_QUERY = gql`
  query subsidiaries($input: UserSearcherInput!) {
    users(input: $input) {
      meta {
        nodeCount
        nodesPerPage
        pageCount
        pageCurrent
      }
      nodes {
        __typename
        ... on User {
          id
          email
          customer {
            id
            name
            lastName
          }
        }
      }
    }
  }
`;

const DEFAULT_PAGE_SIZE = 10;

export const Purchases = () => {
  const { data: user } = useMe();
  const [pageCurrent, setPageCurrent] = useState(1);
  const [nodesPerPage, setNodesPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [status, setStatus] = useState<OrderStatus[] | null>(null);
  const [isModalDetailVisible, setIsModalDetailVisible] = useState(false);
  const [isModalDevolutionVisible, setIsModalDevolutionVisible] = useState(false);
  const [displayItem, setDisplayItem] = useState<any>(null);
  const [customerFilter, setCustomerFilter] = useState<string>('customerId');
  const [customerId, setCustomerId] = useState<string | undefined>(
    user?.me.customer?.id,
  );

  const { data: secondaryUsers } = useQuery<
    subsidiaries,
    subsidiariesVariables
  >(SUBSIDIARIES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        pageCurrent: 1,
        nodesPerPage: 10000,
        where: {
          customerParentId: [Role.MAIN_CUSTOMER, Role.SUBSIDIARY].includes(
            user?.me.role || Role.MAIN_CUSTOMER,
          )
            ? user?.me.customer?.id
            : '',
        },
      },
    },
  });


  const onChange = (values: any) => {
    if (!values) {
      setDateFrom(null);
      setDateTo(null);
      return;
    }
    const dateFrom = values[0].format();

    const dateTo = values[1].format();
    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };

  const { data, loading, refetch } = useQuery<purchases, purchasesVariables>(
    PURCHASE_ORDER_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          pageCurrent,
          nodesPerPage,
          where: {
            [customerFilter]: customerId,
            dateFrom,
            dateTo,
            status,
          },
        },
      },
    },
  );

  const purchaseOrders = data?.orders.nodes as
    | purchases_orders_nodes_PurchaseOrder[]
    | null;

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Producto',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Costo',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'PVP',
      dataIndex: 'pvp',
      key: 'pvp',
    },
    {
      title: 'Comission',
      dataIndex: 'commision',
      key: 'commision',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: 'Nota de Venta',
      dataIndex: 'nota',
      key: 'nota',
    },
  ];

  const datasource = purchaseOrders?.map((item) => ({
    key: item.id, 
    description: item.alternateDesc?item.alternateDesc:"",
    facdata: (
      <ul>
        <li>
          <span className="font-semibold">
            {'Tipo de Documento'}
            {': '}
          </span>
          <span>{item.facIDType}</span>
        </li>
        {item.facIDType !== FacType.CONSUMIDORFINAL && (
        <li>
          <span className="font-semibold">
            {'Numero de Documento'}
            {': '}
          </span>
          <span>{item.facID}</span>
        </li>)}
        {item.facIDType !== FacType.CONSUMIDORFINAL && (
        <li>
          <span className="font-semibold">
            {'Nombres'}
            {': '}
          </span>
          <span>{item.factNames}</span>
        </li>)}
        {item.facIDType !== FacType.CONSUMIDORFINAL && (
        <li>
          <span className="font-semibold">
            {'Apellidos'}
            {': '}
          </span>
          <span>{item.facLastnames}</span>
        </li>)}
        {item.facIDType !== FacType.CONSUMIDORFINAL && (
        <li>
          <span className="font-semibold">
            {'Dirección'}
            {': '}
          </span>
          <span>{item.facAddress}</span>
        </li>)}
      </ul>
    ),
    client: `${item.customer.name} ${item.customer.lastName}`,
    product: item.alternateName?item.alternateName: item.product.name,
    total: `$${getFormattedValue(item.total || 0)}`,
    createdAt: item.createdAt,
    comment: item.comment,
    pvp: `$${getFormattedValue(item.precioVenta || 0)}`,
    commision: `$${getFormattedValue((item.precioVenta-item.total) || 0)}`,
    balance: `$${getFormattedValue(item.balanceNuevo || 0)}`,
    action: (<div className="flex items-center justify-start">
        <div
          onClick={() => {
            setDisplayItem(item);
            setIsModalDetailVisible(true);
          }}
          className="flex-shrink-0 mr-2"
        >
          <Tooltip title={'ver detalle'}>
            <InformationCircleIcon
              className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
              aria-hidden="true"
            />
          </Tooltip>
        </div>
      </div> ),
      /*
      <div
          onClick={() => {
            setDisplayItem(item);
            alert("Devolucion de "+item.product.name);
          }}
          className="flex-shrink-0 mr-2"
        >
          <Tooltip title={'Realizar pedido de Devolucion'}>
            <ArrowCircleRightIcon
              className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
              aria-hidden="true"
            />
          </Tooltip>
        </div>
      */
    nota:(
      <div className="flex items-center justify-start">
        <div className="flex-shrink-0" >
          <Tooltip title="Descargar Nota de Venta">
            <Link to={{pathname: "/downloadNotaVenta",state: { purchaseNV: item }}}>
              <DocumentDownloadIcon className="w-6 h-6 mr-3 text-gray-400 cursor-pointer hover:text-blue-400" aria-hidden="true"/>
            </Link>
          </Tooltip>
        </div>
        {
          item.result?.fields ? item.result.fields.map((field: Field) => 
          {
            if(field.name === 'recibo')
            {
              return (
                (<div className="flex-shrink-0" >
                    <Tooltip title="Descargar Recibo Proveedor">
                      <Link to={{pathname: "/downloadNotaVenta",state: { purchasePagatodito: field.value }}}>
                        <DocumentDownloadIcon className="w-6 h-6 mr-3 text-gray-400 cursor-pointer hover:text-blue-400" aria-hidden="true"/>
                      </Link>
                    </Tooltip>
                  </div>
                )
              );
            }
            return ("");
          }) : ""
        }
        </div>
    ),
    status: (
      <Tag
        color={
          item.status === 'ACCEPTED' || item.status === 'DEVOLUTION_ACCEPTED'
            ? 'geekblue'
            : item.status === 'PENDING' || item.status === 'DEVOLUTION_REQUESTED'
            ? 'orange'
            : item.status === 'REJECTED' || item.status === 'DEVOLUTION_REJECTED'
            ? 'error'
            : 'cyan'
        }
      >
        {PurchaseOrderStatus[item.status]}
      </Tag>
    ),
    detail: item.detail?.fields ? (
      <ul>
        {filterObject(keysToFilterDetail, item.detail.fields).map((field: Field) => 
          <li key={field.name}>
            <span className="font-semibold">
              {field.name}
              {': '}
            </span>
            <span>{parseToShow( field.value)}</span>
          </li>
        )}
        {
          parseFormData(getValueFromArray(item.detail.fields,"formData"), getValueFromArray(item.detail.fields,"formKey")).map((field2: Field) =>
          <li key={field2.name}>
            <span className="font-semibold">
              {field2.name}
              {': '}
            </span>
            <span>{parseToShow( field2.value)}</span>
          </li>)
        }
      </ul>
    ) : (
      ''
    ),
    result: item.result ? (
      <ul>
        {filterObject(keysToFilterResult, item.result.fields).map((field: Field) => (
            (
              <li key={field.name}>
                <span className="font-semibold">
                  {field.name}
                  {': '}
                </span>
                <span>{parseToShow( field.value)}</span>
              </li>)
          )
        )}
      </ul>
    ) : (
      ''
    ),
  }));

  return (
    <div>
      <Modal
        title="Reportar el pedido"
        visible={isModalDevolutionVisible}
        onOk={async () => {
          setIsModalDevolutionVisible(false);
        }}
        onCancel={async () => {
          setIsModalDevolutionVisible(false);
        }}
        child={<RequestDevolution onOk={function (): void {
          refetch();
          setIsModalDevolutionVisible(false);
        } } purchaseOrder={displayItem} />}
      />
      <Modal
        title="Detalles del pedido"
        visible={isModalDetailVisible}
        onOk={async () => {
          setIsModalDetailVisible(false);
        }}
        onCancel={async () => {
          setIsModalDetailVisible(false);
        }}
        child={<JSONDetail json={displayItem}
        requestDev={displayItem?.devolution?.request}
        answerDev={displayItem?.devolution?.answer}
        labels={{
          product:"Producto",
          comment:"Comentario",
          createdAt:"Fecha del Pedido",
          updatedAt:"Fecha de Revision",
          status:"Estado",
          detail:"Detalles",
          result:"Resultado",
          alternateName:"Nombre Real del Producto",
          alternateDesc:"Descripcion Real del Producto",
          factNames:"Nombre Del Cliente",
          facLastnames:"Apellido del Cliente",
          facAddress:"Dirección del Cliente",
          facID:"Numero de Identificación del Cliente",
          facIDType:"Typo de Identificación del Cliente"
          }}
          buttonLabel={displayItem?.status!=OrderStatus.ACCEPTED ? undefined : 'Reportar Pedido'}
          buttonAction={async () => {
            setIsModalDetailVisible(false);
            setIsModalDevolutionVisible(true)}} />}
      />
      <Helmet>
        <title>{Titles.PURCHASES}</title>
      </Helmet>
      <div className="py-6">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900 leading-6">
              {Labels.MENU_PURCHASES}
            </h3>
          </div>
          <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
            <form className="flex flex-col items-center w-full py-8 bg-white shadow sm:rounded-3xl sm:px-4">
              <div className="mb-4 mr-auto">
                {Labels.FILTERS}
                <FontAwesomeIcon icon={faFilter} className="ml-3 text-xl" />
              </div>
              <div className="max-w-xl grid grid-cols-1 gap-5">
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_DATES}</span>
                  </label>
                  <RangePicker
                    showTime={true}
                    placeholder={['Fecha desde', 'Fecha hasta']}
                    className="w-full rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={onChange}
                    disabledDate={(current) => moment().isBefore(current)}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_STATUS}</span>
                  </label>
                  <select
                    name="status"
                    onChange={(event) => {
                      const value = event.target.value;
                      setStatus(value ? ([value] as OrderStatus[]) : null);
                    }}
                    className="w-full select"
                  >
                    <option value="">Todos</option>
                    {Object.keys(OrderStatus).map((state) => (
                      <option key={state} value={state}>
                        {PurchaseOrderStatus[state as any]}
                      </option>
                    ))}
                  </select>
                </div>
                {user?.me.role === Role.MAIN_CUSTOMER && (
                  <div className="flex items-center justify-center">
                    <label
                      htmlFor="first_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      <span className="mr-3">Usuarios secundarios:</span>
                    </label>
                    <Select
                      className="w-a"
                      defaultValue={'Ninguno(solo mis compras)'}
                      showSearch
                      style={{ width: 326 }}
                      onChange={(customerId) => {
                        if (customerId === 'Ninguno(solo mis compras)') {
                          setCustomerId(user?.me.customer?.id);
                          setCustomerFilter('customerId');
                          return;
                        }
                        if (customerId === 'Todos') {
                          setCustomerId(user?.me.customer?.id);
                          setCustomerFilter('customerParentId');
                          return;
                        }
                        setCustomerId((customerId as string)?.split('|')[0]);
                        setCustomerFilter('customerId');
                      }}
                      placeholder="Selecciona un usuario secundario"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (
                          option?.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      <Option key="none" value="Ninguno(solo mis compras)">
                        <span>Ninguno(solo mis compras)</span>
                      </Option>
                      <Option key="all" value="Todos">
                        <span>Todos</span>
                      </Option>
                      {(secondaryUsers?.users
                        ?.nodes as subsidiaries_users_nodes_User[])?.map(
                        (user: subsidiaries_users_nodes_User) => (
                          <Option
                            key={user.customer?.id}
                            value={`${
                              user.customer?.id
                            }|${user.customer?.name.toLowerCase()} ${user.customer?.lastName.toLowerCase()}`}
                          >
                            <span>{`${user.customer?.name} ${user.customer?.lastName}`}</span>
                          </Option>
                        ),
                      )}
                    </Select>
                  </div>
                )}
              </div>
            </form>
          </div>
          <SkeletonTable
            active={true}
            loading={loading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              dataSource={datasource}
              columns={columns}
              pagination={{
                defaultPageSize: DEFAULT_PAGE_SIZE,
                pageSize: nodesPerPage,
                current: pageCurrent,
                total: data?.orders.meta.nodeCount,
                responsive: true,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '100'],
                onShowSizeChange: (current, size) => setNodesPerPage(size),
                onChange: (page) => setPageCurrent(page),
              }}
            />
          </SkeletonTable>
        </div>
      </div>
    </div>
  );
};
