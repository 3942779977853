import React, { useState } from 'react';
import { gql } from '@apollo/client/core';
import { DatePicker, Table, Typography } from 'antd';
import { IdentificationType } from '../../enums/spanish.enum';
import { useQuery } from '@apollo/client';
import { Labels } from '../../enums/labels.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';
import {
  customersTotalCreditQuery,
  customersTotalCreditQuery_customersTotalCredit_nodes_CustomerTotalCredit,
  customersTotalCreditQueryVariables,
} from '../../__api__/customersTotalCreditQuery';
import { Titles } from '../../enums/titles.enum';
import { Helmet } from 'react-helmet-async';

const { RangePicker } = DatePicker;
const { Text } = Typography;

const CUSTOMERS_TOTAL_CREDIT_QUERY = gql`
  query customersTotalCreditQuery($input: CustomerSearcherInput!) {
    customersTotalCredit(input: $input) {
      meta {
        nodeCount
        nodesPerPage
        pageCount
        pageCurrent
      }
      nodes {
        __typename
        ... on CustomerTotalCredit {
          customer {
            id
            identificationType
            identificationNumber
            name
            lastName
            createdAt
            balance
            userType
          }
          totalAccreditations
          totalPurchases
        }
      }
    }
  }
`;

const DEFAULT_PAGE_SIZE = 10;

export const ReportCustomerCredit = () => {
  const [pageCurrent, setPageCurrent] = useState(1);
  const [nodesPerPage, setNodesPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);

  const onChange = (values: any) => {
    if (!values) {
      setDateFrom(null);
      setDateTo(null);
      return;
    }
    const dateFrom = values[0].format();
    const dateTo = values[1].format();

    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };

  const columns = [
    {
      title: 'Tipo de ID',
      dataIndex: 'identificationType',
      key: 'identificationType',
    },
    {
      title: 'Identificación',
      dataIndex: 'identificationNumber',
      key: 'identificationNumber',
    },
    {
      title: 'Nombres',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tipo de cliente',
      dataIndex: 'userType',
      key: 'userType',
    },
    {
      title: 'Fecha creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Saldo disponible',
      dataIndex: 'balanceP',
      key: 'balanceP',
    },
    {
      title: 'Total de acreditaciones',
      dataIndex: 'totalAccreditationsP',
      key: 'totalAccreditationsP',
    },
    {
      title: 'Total de compras',
      dataIndex: 'totalPurchasesP',
      key: 'totalPurchasesP',
    },
  ];

  const { data, loading } = useQuery<
    customersTotalCreditQuery,
    customersTotalCreditQueryVariables
  >(CUSTOMERS_TOTAL_CREDIT_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        pageCurrent,
        nodesPerPage,
        where: {
          dateFrom,
          dateTo,
        },
      },
    },
  });

  const round = (value: number): number => Math.round(value * 100) / 100;

  const customers = data?.customersTotalCredit.nodes as
    | customersTotalCreditQuery_customersTotalCredit_nodes_CustomerTotalCredit[]
    | null;

  const datasource = customers?.map((item) => ({
    key: item.customer.id,
    identificationType: IdentificationType[item.customer.identificationType],
    identificationNumber: item.customer.identificationNumber,
    name: `${item.customer.name} ${item.customer.lastName}`,
    userType: item.customer.userType,
    createdAt: item.customer.createdAt,
    balance: item.customer.userType === 'Principal' ? item.customer.balance : 0,
    totalAccreditations: item.totalAccreditations,
    totalPurchases: item.totalPurchases,
    balanceP: `$ ${
      item.customer.userType === 'Principal' ? item.customer.balance : 0
    }`,
    totalAccreditationsP: `$ ${item.totalAccreditations}`,
    totalPurchasesP: `$ ${item.totalPurchases}`,
  }));

  return (
    <div className="py-6">
      <Helmet>
        <title>{Titles.REPORTS}</title>
      </Helmet>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-medium text-gray-900 leading-6">
            Estado de cuenta de clientes
          </h3>
        </div>
        <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
          <form className="flex flex-col items-center w-full py-8 bg-white shadow sm:rounded-3xl sm:px-4">
            <div className="mb-4 mr-auto">
              {Labels.FILTERS}
              <FontAwesomeIcon icon={faFilter} className="ml-3 text-xl" />
            </div>
            <div className="max-w-xl grid grid-cols-1 gap-5">
              <div className="flex items-center justify-center">
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  <span className="mr-3">{Labels.FILTER_DATES}</span>
                </label>
                <RangePicker
                  showTime={true}
                  placeholder={['Fecha desde', 'Fecha hasta']}
                  className="w-full rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={onChange}
                  disabledDate={(current) => moment().isBefore(current)}
                />
              </div>
            </div>
          </form>
        </div>
        <SkeletonTable
          active={true}
          loading={loading}
          columns={columns as SkeletonTableColumnsType[]}
        >
          <Table
            dataSource={datasource}
            columns={columns}
            pagination={{
              defaultPageSize: DEFAULT_PAGE_SIZE,
              pageSize: nodesPerPage,
              current: pageCurrent,
              total: data?.customersTotalCredit.meta.nodeCount,
              responsive: true,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '100'],
              onShowSizeChange: (current, size) => setNodesPerPage(size),
              onChange: (page) => setPageCurrent(page),
            }}
            summary={(pageData) => {
              let sumBalance = 0;
              let sumAccreditations = 0;
              let sumPurchases = 0;

              pageData.forEach(
                ({ balance, totalAccreditations, totalPurchases }) => {
                  sumBalance += balance || 0;
                  sumAccreditations += totalAccreditations;
                  sumPurchases += totalPurchases;
                },
              );

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={1}>
                      <span className="font-bold text-l">Total</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>{''}</Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>{''}</Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>{''}</Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>{''}</Table.Summary.Cell>
                    <Table.Summary.Cell index={6}>
                      <Text>
                        <span className="font-bold text-l">{`$ ${round(
                          sumBalance,
                        )}`}</span>
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>
                      <Text>
                        <span className="font-bold text-l">
                          {`$ ${round(sumAccreditations)}`}
                        </span>
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8}>
                      <Text>
                        <span className="font-bold text-l">{`$ ${round(
                          sumPurchases,
                        )}`}</span>
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </SkeletonTable>
      </div>
    </div>
  );
};
