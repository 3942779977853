import {
  CurrencyDollarIcon,
  ShoppingCartIcon,
  UserAddIcon,
} from '@heroicons/react/outline';
import React from 'react';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { stats } from '../../__api__/stats';
import { Skeleton } from 'antd';
import { Titles } from '../../enums/titles.enum';
import { Helmet } from 'react-helmet-async';
import { useMe } from '../../hooks/use-me';
import { Role } from '../../__api__/globalTypes';
import { FaMarker } from 'react-icons/fa';

const STATS_QUERY = gql`
  query stats {
    stats {
      pendingConsignments
      pendingOrders
      invalidUsers
      pendingUsers
      pendingDevolutions
      reviewingConsignments
      reviewingOrders
      reviewingDevolutions
      registeredUsers
      monthCommissions
      totalCommissions
    }
  }
`;

export const Dashboard = () => {
  const { data: user } = useMe();
  const { data, loading } = useQuery<stats>(STATS_QUERY, {
    //fetchPolicy: 'network-only',
    pollInterval: 36000,
  });

  const adminCards = [
    {
      name: 'Acreditación saldo pendientes',
      href: '/review-consignments',
      icon: CurrencyDollarIcon,
      iconClass: 'text-red-400',
      amount: data?.stats.pendingConsignments,
    },
    {
      name: 'Pedidos pendientes',
      href: '/review-orders',
      icon: ShoppingCartIcon,
      iconClass: 'text-red-400',
      amount: data?.stats.pendingOrders,
    },
    {
      name: 'Reclamos pendientes',
      href: '/review-devoluciones',
      icon: FaMarker,
      iconClass: 'text-red-400',
      amount: data?.stats.pendingDevolutions,
    },
    {
      name: 'Acreditación saldo en revisión',
      href: '/review-consignments',
      icon: CurrencyDollarIcon,
      iconClass: 'text-yellow-400',
      amount: data?.stats.reviewingConsignments,
    },
    {
      name: 'Pedidos en revisión',
      href: '/review-orders',
      icon: ShoppingCartIcon,
      iconClass: 'text-yellow-400',
      amount: data?.stats.reviewingOrders,
    },
    {
      name: 'Reclamos en revisión',
      href: '/review-devoluciones',
      icon: FaMarker,
      iconClass: 'text-yellow-400',
      amount: data?.stats.reviewingDevolutions,
    },
    {
      name: 'Clientes registrados',
      href: '#',
      icon: UserAddIcon,
      iconClass: 'text-green-400',
      amount: data?.stats.registeredUsers,
    },
    {
      name: 'Clientes pendientes',
      href: '#',
      icon: UserAddIcon,
      iconClass: 'text-red-400',
      amount: data?.stats.pendingUsers,
    },
  ];

  const distributorCards = [
    {
      name: 'Clientes registrados',
      href: '#',
      icon: UserAddIcon,
      iconClass: 'text-gray-400',
      amount: data?.stats.registeredUsers,
    },
    {
      name: 'Comisiones de este mes',
      href: '#',
      icon: CurrencyDollarIcon,
      iconClass: 'text-gray-400',
      amount: data?.stats.monthCommissions,
    },
    {
      name: 'Comisiones totales',
      href: '#',
      icon: CurrencyDollarIcon,
      iconClass: 'text-gray-400',
      amount: data?.stats.totalCommissions,
    },
  ];

  const cards =
    user?.me.role === Role.DISTRIBUTOR ? distributorCards : adminCards;

  return (
    <div className="bg-gray-100">
      <Helmet>
        <title>{Titles.MAIN}</title>
      </Helmet>
      <div>
        <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h2 className="py-6 text-lg font-medium text-gray-900 leading-6">
            Información general
          </h2>
          <div className="py-6 mt-2 mb-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {cards.map((card) => (
              <div key={card.name} className="mb-8 bg-white rounded-lg shadow">
                <div className="px-5 py-12">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <card.icon
                        className={"w-8 h-8 " + card.iconClass}
                        aria-hidden="true"
                      />
                    </div>
                    <div className="flex-1 w-0 ml-5">
                      <dl>
                        <dt className="text-sm font-medium text-gray-500 truncate">
                          {card.name}
                        </dt>
                        <dd>
                          <div className="text-lg font-medium text-gray-900">
                            {loading && (
                              <Skeleton.Input
                                style={{ width: 100 }}
                                active={true}
                                size={'small'}
                              />
                            )}
                            {!loading && card.amount}
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
