import React, { useState } from 'react';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import { useForm } from 'react-hook-form';
import { Labels } from '../../enums/labels.enum';
import { Button } from '../../components/button';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import {
  consultarServicioMutation_consultarServicio_package
} from '../../__api__/consultarServicioMutation';
import {
  pagarServicioMutation,
  pagarServicioMutationVariables,
} from '../../__api__/pagarServicioMutation';
import { useMe } from '../../hooks/use-me';
import { Exceptions } from '../../enums/exceptions.enum';
import { checkLength,removeSpecialCharacters } from '../../utils/validate-utils';
import { productsByCategoryId_productsByCategoryId } from '../../__api__/productsByCategoryId';
import { FacType } from '../../__api__/globalTypes';

interface IBuyProductProps {
  onOk: () => void;
  consulta: consultarServicioMutation_consultarServicio_package | null;
  product: productsByCategoryId_productsByCategoryId | null;
}

interface IPagarForm {
  lastName: string;
  name: string;
  identificationNumber: string;
  direccion: string;
  monto: number;
  tipocliente: number;
}

const PAGAR_SERVICE = gql`
  mutation pagarServicioMutation($input: ServicioPagarInput!) {
    pagarServicio(input: $input)
  }
`;

export const BuyService2: React.FC<IBuyProductProps> = ({ onOk, consulta, product }) => {
  const { data, refetch } = useMe();
  let primalLoading = false;

  const [errorCON,seterrorCON] = useState<string | null>(null);
  const [isInit,setisInit] = useState<boolean>(true);
  const [total,setTotal] = useState<number>(consulta==null ? 0 : consulta.valorComision+consulta.valorSinComision);
  const [showConsumidor,setshowConsumidor] = useState<boolean>(false);

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: {  },
  } = useForm<IPagarForm>({
    mode: 'onChange',
  });


  if(isInit)
  {
    setValue("monto",consulta==null ? 0 : (consulta.valorSinComision))
    setisInit(false);
  }

  const totalChange = e => {
    if(!e)
    {
      return;
    }
    const monto =getValues().monto.toString();
    //if monto has more than 2 decimals, round it
    if(monto.split(".")[1]!=undefined && monto.split(".")[1].length>2)
    {
      setValue("monto",Math.round(Number(monto)*100)/100);
    }
    const val =Number(getValues().monto);
    if(consulta==null)
    {
      setTotal(0);
    }
    else
    {
      if(consulta.admiteBono)
      {
        let valorTotal = consulta.valorComision+consulta.valorSinComision;
        if(consulta.valorSinComision== 0)
        {
          valorTotal = 999.99;
        }
        else
        {
          valorTotal = consulta.valorComision+consulta.valorSinComision;
        }
        if(consulta.valorComision+val>valorTotal)
        {
          setValue("monto", valorTotal-consulta.valorComision);
          setTotal( valorTotal);
        }
        else
        {
          setTotal(consulta.valorComision+val);
        }
      }
      else
      {
        setTotal(consulta.valorComision+consulta.valorSinComision);
        setValue("monto",consulta.valorSinComision);
      }
    }
  }

  const consumidorChange = e => {
    if(!e)
    {
      return;
    }
    const val =Number(getValues().tipocliente);
    if(consulta==null)
    {
      setshowConsumidor(false);

    }
    else
    {
      setshowConsumidor(val!=1);
    }
  }

  const onCompleted = async (data: pagarServicioMutation) => {
    const {pagarServicio : purchaseOrderId } = data;
    if (purchaseOrderId) {
      primalLoading = true;
      await refetch();
      onOk();
    }
  };
  const [pagarServicioMutation, { loading, error }] = useMutation<
    pagarServicioMutation,
    pagarServicioMutationVariables
  >(PAGAR_SERVICE, {
    onCompleted,
  });

  const onSubmit = async () => {
    if(primalLoading)
    {
      return;
    }
    primalLoading = true;
    let facIdType: FacType = FacType.CONSUMIDORFINAL;
    switch (Number(getValues().tipocliente)) {
      case 1:
        facIdType = FacType.CONSUMIDORFINAL;
        break;
      case 2:
        facIdType = FacType.CEDULA;
        break;
      case 3:
        facIdType = FacType.RUC;
        break;
      case 4:
        facIdType = FacType.PASAPORTE;
        break;
        default:
          seterrorCON("Tipo de Consumidor no reconocido");
          return;
    }
    if(consulta==null || consulta==undefined)
    {
      seterrorCON("Error de Consulta");
      return;
    }
    if(product==null || product==undefined)
    {
      seterrorCON("Error de Producto");
      return;
    }
    if (!loading) {
      seterrorCON(null);
      try {
        const { ...input } = getValues();
        if(facIdType!=FacType.CONSUMIDORFINAL)
        {
          if(input.name==="" || input.name==undefined)
          {
            seterrorCON(Labels.NAME_FAC_SERVICIO+": "+FormErrorMessages.REQUIRED_FIELD);
            return;
          }
          if(input.name.length>30)
          {
            seterrorCON(Labels.NAME_FAC_SERVICIO+": "+FormErrorMessages.LENGTH);
            return;
          }
          if(!/^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(input.name))
          {
            seterrorCON(Labels.NAME_FAC_SERVICIO+": "+FormErrorMessages.CHARACTERS);
            return;

          }
          if(input.lastName==="" || input.lastName==undefined)
          {
            seterrorCON(Labels.APELLIDO_FAC_SERVICIO+": "+FormErrorMessages.REQUIRED_FIELD);
            return;
          }
          if(input.lastName.length>30)
          {
            seterrorCON(Labels.APELLIDO_FAC_SERVICIO+": "+FormErrorMessages.LENGTH);
            return;
          }
          if(!/^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(input.lastName))
          {
            seterrorCON(Labels.APELLIDO_FAC_SERVICIO+": "+FormErrorMessages.CHARACTERS);
            return;

          }
          if(input.direccion==="" || input.direccion==undefined)
          {
            seterrorCON(Labels.DIR_FAC_SERVICIO+": "+FormErrorMessages.REQUIRED_FIELD);
            return;
          }
          if(input.direccion.length>150)
          {
            seterrorCON(Labels.DIR_FAC_SERVICIO+": "+FormErrorMessages.LENGTH);
            return;
          }
          if(!/^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(input.direccion))
          {
            seterrorCON(Labels.DIR_FAC_SERVICIO+": "+FormErrorMessages.CHARACTERS);
            return;

          }
          if(input.identificationNumber==="" || input.identificationNumber==undefined)
          {
            seterrorCON(Labels.CI_FAC_SERVICIO+": "+FormErrorMessages.REQUIRED_FIELD);
            return;
          }
          if(input.identificationNumber.length<10 || input.identificationNumber.length>30 )
          {
            seterrorCON(Labels.CI_FAC_SERVICIO+": "+FormErrorMessages.ID_LENGTH_SERVICE);
            return;
          }
          if(!/^[0-9]*$/.test(input.identificationNumber))
          {
            seterrorCON(Labels.CI_FAC_SERVICIO+": "+FormErrorMessages.CHARACTERS);
            return;
          }
        }
        if(consulta.admiteBono)
        {
          if(isNaN(input.monto))
          {
            seterrorCON(Labels.ABONO_FAC_SERVICIO+": "+FormErrorMessages.CHARACTERS);
            return;
          }
          if(input.monto>999.99)
          {
            seterrorCON(Labels.ABONO_FAC_SERVICIO+": "+FormErrorMessages.MAX_VALUE);
            return;

          }
        }
        if (consulta.consultaKey === undefined || consulta.consultaKey === null)
        {
          seterrorCON("Error de Consulta");
          return;
        }
        await pagarServicioMutation({
          variables: {
            input: {
              userId: data?.me.id || '',
              productId: product?.id || '',
              key: consulta.consultaKey,
              amount: consulta.admiteBono ? Number(input.monto) : Number(consulta.valorSinComision),
              facData: {
                factNames: facIdType==FacType.CONSUMIDORFINAL ? "" : input.name,
                facLastnames: facIdType==FacType.CONSUMIDORFINAL ? "" : input.lastName,
                facAddress: facIdType==FacType.CONSUMIDORFINAL ? "" : input.direccion,
                facID: facIdType==FacType.CONSUMIDORFINAL ? "" : input.identificationNumber,
                facIDType: facIdType
              }
            },
          },
        });
      } catch (error : any) {
        seterrorCON(error.toString());
      }
      finally
      {
          primalLoading = false;
      }
    }
  };

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="flex-1 min-w-0">
          <div className="flex items-center mb-4 space-x-3">
            <h2 className="text-lg font-bold text-gray-900 leading-7">
              {product?.name}
            </h2>
          </div>
          <h3 className="mb-6 text-base font-semibold text-gray-900 leading-7">
            {product?.description}
          </h3>
          <h4 className="mb-2 text-sm font-normal text-gray-900 leading-5">
            Requerimos de la siguiente información para la compra de tu producto
          </h4>
        </div>
        { (consulta==null || consulta==undefined) ? (<FormError errorMessage="El valor consultado es NULL, por favor reintentar mas tarde" />) : (
          <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            {  consulta.nombres &&(
            <div>
              <label className="label">
                {Labels.NAME_USR_SERVICIO}
              </label>
              <div className="mt-1">
                <input
                  value={consulta.nombres ? consulta.nombres : ""}
                  className="input"
                  disabled ={true}
                />
              </div>
            </div>)}
            {  consulta.direccion &&(
            <div>
              <label className="label">
                {Labels.DIR_USR_SERVICIO}
              </label>
              <div className="mt-1">
                <input
                  value={consulta.direccion ? consulta.direccion : ""}
                  className="input"
                  disabled ={true}
                />
              </div>
            </div>)}
            {  consulta.documentoID &&(
            <div>
              <label className="label">
                {Labels.CI_USR_SERVICIO}
              </label>
              <div className="mt-1">
                <input
                  value={consulta.documentoID ? consulta.documentoID : ""}
                  className="input"
                  disabled ={true}
                />
              </div>
            </div>)}
            <div>
              <label htmlFor="tipocliente" className="label">
                {Labels.CLI_SELECT_SERVICIO}
              </label>
              <div className="mt-1">
                <select
                  name="tipocliente"
                  ref={register({ required: true })}
                  className="input"
                  onChange={consumidorChange}
                  defaultValue={1}
                >
                  <option value="1">Consumidor Final</option>
                  <option value="2">Cedula</option>
                  <option value="3">RUC</option>
                  <option value="4">Pasaporte</option>
                </select>
              </div>
            </div>
            {  showConsumidor &&(
            <div>
              <label htmlFor="name" className="label">
                {Labels.NAME_FAC_SERVICIO}
              </label>
              <div className="mt-1">
                <input
                  ref={register({ maxLength: 100 })}
                  name="name"
                  maxLength={100}
                  type="text"
                  className="input"
                />
              </div>
            </div>)}
            {  showConsumidor &&(
            <div>
              <label htmlFor="lastName" className="label">
                {Labels.APELLIDO_FAC_SERVICIO}
              </label>
              <div className="mt-1">
                <input
                  ref={register({ maxLength: 100})}
                  name="lastName"
                  maxLength={100}
                  type="text"
                  className="input"
                />
              </div>
            </div>)}
            {  showConsumidor &&(
            <div>
              <label htmlFor="direccion" className="label">
                {Labels.DIR_FAC_SERVICIO}
              </label>
              <div className="mt-1">
                <input
                  ref={register({ maxLength: 150 })}
                  name="direccion"
                  maxLength={150}
                  type="text"
                  className="input"
                />
              </div>
            </div>)}
            {  showConsumidor &&(
            <div>
              <label htmlFor="identificationNumber" className="label">
                {Labels.CI_FAC_SERVICIO}
              </label>
              <div className="mt-1">
                <input
                  ref={register({ maxLength: 20, minLength: 10, pattern: /^[0-9]*$/})}
                  name="identificationNumber"
                  type="tel"
                  pattern="[0-9]*"
                  minLength={10}
                  maxLength={20}
                  className="input"
                  onKeyDown={(e) => checkLength(e)}
                  onKeyUp={removeSpecialCharacters}
                />
              </div>
            </div>)}
              <div>
                <label className="label">
                  {Labels.COMISION_FAC_SERVICIO}
                </label>
                <div className="mt-1">
                  <input
                    value={consulta.valorComision}
                    className="input"
                    disabled ={true}
                  />
                </div>
              </div>
              {
                consulta.valorSinComision > 0 && (
                <div>
                  <label className="label">
                    {consulta.admiteBono ? Labels.AMOUNT_FAC_SERVICIO : Labels.AMOUNT_NOABONO_FAC_SERVICIO}
                  </label>
                  <div className="mt-1">
                    <input
                      value={consulta.valorSinComision}
                      className="input"
                      disabled ={true}
                    />
                  </div>
                </div>)
              }

              {
                consulta.admiteBono && (
                  <div>
                    <label htmlFor="monto" className="label">
                      {Labels.ABONO_FAC_SERVICIO}
                    </label>
                    <div className="mt-1">
                      <input
                        ref={register({ required: true, min: 0, max: consulta.valorSinComision== 0 ? 999.99: consulta.valorSinComision})}
                        name="monto"
                        min={0}
                        max={consulta.valorSinComision== 0 ? 999.99: consulta.valorSinComision}
                        type="number"
                        step=".01"
                        className="input"
                        onChange={totalChange}
                        defaultValue={consulta==null ? 0 : (consulta.valorSinComision)}
                      />
                    </div>
                  </div>
                )
              }
              <div>
                <label className="label">
                  {Labels.TOTAL_FAC_SERVICIO}
                </label>
                <div className="mt-1">
                  <input
                    value={total.toFixed(2)}
                    className="input"
                    disabled ={true}
                  />
                </div>
              </div>
              <Button
                canClick={true}
                loading={loading  || primalLoading}
                actionText={Labels.BUY}
              />
              {error && (
                <FormError errorMessage={Exceptions[error.message as any]} />
              )}
            </form>
            {errorCON && (
              <FormError errorMessage={errorCON} />
            )}
          </div>
        )}

      </div>
    </div>
  );
};
