import React from 'react';

interface IButtonProps {
  canClick: boolean;
  loading: boolean;
  actionText: string;
  bgColor?: string;
  onClick?: () => void;
}

export const Button: React.FC<IButtonProps> = ({
  canClick,
  loading,
  actionText,
  bgColor,
  onClick,
}) => (
  <button
    onClick={onClick}
    className={`flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm hover:bg-` + (bgColor == null ? 'indigo' : bgColor) + `-700 
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-`+ (bgColor == null ? 'indigo' : bgColor) + `-500 ${canClick
        ? 'bg-' + (bgColor == null ? 'indigo' : bgColor) + '-600 hover:opacity-90'
        : 'bg-' + (bgColor == null ? 'indigo' : bgColor) + '-300 pointer-events-none'
      }`}
  >
    {loading ? 'Cargando...' : actionText}
  </button>
);
