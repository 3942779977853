import React from 'react';
import { parseToShow, Field, filterObject, keysToFilterModalDetail, keysToFilterModalResult, keysToFilterModalProduct } from '../utils/display-utils';
import { PurchaseOrderStatus } from '../enums/spanish.enum';

//function create an html component of key value to show the json data
const createKeyValue = (key: string, value: any,label:string) => {
    if(key === '__typename') return null;
    if(value === null) return null;
    if(value === undefined) return null;
    if(value === '') return null;
    return (
        <div className="flex justify-between py-1 text-sm font-medium">
            <dt className="text-gray-500">{label+": "}&nbsp;</dt>
            {createValue(value,key)}
        </div>
    );
}

//function that returns the value, if it is an object returns an array of createkeyvalue
const createValue = (value: any,key: string) => {
    if(value === null) return (<dd className="text-gray-900">{parseToShow(value)}</dd>);
    if(typeof value !== 'object') return (<dd className="text-gray-900">{key=="status" ? PurchaseOrderStatus[value]:parseToShow(value)}</dd>);
    //if value has a key called fields return 'details'
    if(value.fields) 
    {
      if(key=='detail' || key=='details'|| key=='adminDetails' || key=='adminDetail')
      {
        return (
          <dl className="w-full"><br/>
            {filterObject(keysToFilterModalDetail,value.fields).map((field: Field) => {
                return createKeyValue(field.name, field.value,field.name);})}
          </dl>
        );
      }
      else
      {
        return (
          <dl className="w-full"><br/>
            {filterObject(keysToFilterModalResult,value.fields).map((field: Field) => {
                return createKeyValue(field.name, field.value,field.name);})}
          </dl>
        );
      }
    }

    return (<dl className="w-full"><br/>{Object.keys(value).map((key) => {
      if(keysToFilterModalProduct.includes(key)) return null;
        return createKeyValue(key, value[key],key)})}</dl>);
}

interface JSONDetailProps {
    json: any;
    imageURL?: string;
    labels?: any;
    buttonLabel?: string;
    buttonAction?: () => void;
    requestDev?: string;
    answerDev?: string;
}

export const JSONDetail: React.FC<JSONDetailProps> = ({ json,imageURL,labels,buttonLabel,buttonAction,requestDev,answerDev }) => {
    if(!json) return (<div>Sin Datos</div>);
    if(typeof json !== 'object') return (<div>{json}</div>);


    return (
      <div className="flex flex-col justify-center px-2 py-4 sm:px-6 lg:px-8">
        <div className="space-y-6">
          {imageURL && (
            <div>
              <div className="flex items-center justify-center block w-full h-full rounded-lg">
                <img
                  src={imageURL}
                  className="object-cover"
                />
              </div>
            </div>
          )}
          <div>
            <h3 className="font-medium text-gray-900">Información</h3>
            <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                {Object.keys(json).map((key) => {
                  if(!labels)
                  {
                    return createKeyValue(key, json[key],key);
                  }
                  else if(labels[key]!=undefined)
                  {
                    return createKeyValue(key, json[key],labels[key]);
                  }
                })}
                {
                  requestDev && (
                    <div className="flex justify-between py-1 text-sm font-medium">
                        <dt className="text-gray-500">{"Peticion Reclamo: "}&nbsp;</dt>
                        {requestDev}
                    </div>
                  )
                }
                
                {
                  answerDev && (
                    <div className="flex justify-between py-1 text-sm font-medium">
                        <dt className="text-gray-500">{"Respuesta Reclamo: "}&nbsp;</dt>
                        {answerDev}
                    </div>
                  )
                }
            </dl>
            {(buttonLabel && buttonAction) && (
              <div className="flex justify-center">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 mt-4 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none"
                  onClick={buttonAction}
                >
                  {buttonLabel}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };