import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/styles.css';
import { client } from './apollo';
import { ConfigProvider } from 'antd';
import es_ES from 'antd/es/locale/es_ES';

ReactDOM.render(
  <ApolloProvider client={client}>
    <HelmetProvider>
      <ConfigProvider locale={es_ES}>
        <App />        
      </ConfigProvider>
    </HelmetProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);
reportWebVitals();
